import Table, {
  DeleteButton,
  TableHeader,
  UpdateButton,
  ViewButton,
} from "../../../components/Table";
import { User } from "../../../types";
import { UserQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";
import BreadCrumb from "../components/BreadCrumb";

const AgentsCno = () => {
  const props = usePaginationQuery<User, UserQuery>(UserApi.useUsersQuery, {
    userType: ["Agents CNO"],
  });
  const [onDelete] = UserApi.useDeleteMutation();

  const headers: TableHeader<User>[] = [
    { name: "prenom", title: "Prénom" },
    { name: "nom", title: "Nom" },
    { name: "email", title: "Email" },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <UpdateButton
            route={`/dashboard/users/update/${item.id}`}
            state={item}
            permission="agents-cno.update"
          />
          <ViewButton
            route={`/dashboard/users/detail/${item.id}`}
            state={item}
            permission="agents-cno.view"
          />
          <DeleteButton
            id={item.id}
            permission="agents-cno.delete"
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Admin supprimé avec succès"}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="main-page">
        <BreadCrumb
          title="Agents CNO"
          subTitle="Liste des agents CNO"
          items={[
            {
              title: "Home",
              route: "/dashboard",
            },
            { title: "Tous les agents CNO" },
          ]}
        />
        <section className="section pt-10">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des agents CNO</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AgentsCno;
