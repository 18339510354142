// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Category } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const CategoryApi = createApi({
  tagTypes: ["categories", "categoriesSelect", "category"],
  reducerPath: "CategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    categories: builder.query<PaginationResults<Category>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/categories/`, query),
        method: "GET",
      }),
      providesTags: ["categories"],
    }),
    categoriesSelect: builder.query<Category[], void>({
      query: () => ({
        url: `/categories/select/`,
        method: "GET",
      }),
      providesTags: ["categoriesSelect"],
    }),
    findBySlug: builder.query<Category, string>({
      query: (slug) => ({
        url: `/categories/slug/${slug}/`,
        method: "GET",
      }),
      providesTags: (a, b, slug) => [{ type: "category", id: slug }],
    }),
    maj: builder.mutation<Category, { id?: number; data: FormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/categories/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/categories/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["categories", "categoriesSelect"],
    }),
    delete: builder.mutation<Category, number>({
      query: (id) => ({
        url: `/categories/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "categoriesSelect"],
    }),
  }),
});
