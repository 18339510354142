// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Match, MatchFormData } from "../../types";
import { MatchStatus } from "../const-fields";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
export type MatchQuery = CommonQuery & MatchSelectQuery;

export type MatchHistoryQuery = CommonQuery & {
  athletes?: number[];
  equipes?: number[];
};

export type MatchSelectQuery = {
  phase?: number;
  groupe?: number;
  athlete?: number;
  equipe?: number;
  federation?: number;
  competition?: number;
  inspecteur?: number;
  validated?: boolean;
  juge?: number;
  status?: MatchStatus;
};
// Define a service using a base URL and expected endpoints
export const MatchApi = createApi({
  tagTypes: ["matchs", "matchItem", "select"],
  reducerPath: "MatchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    matchs: builder.query<PaginationResults<Match>, MatchQuery>({
      query: (query) => ({
        url: QueryUrl(`/matches/`, query),
        method: "GET",
      }),
      providesTags: ["matchs"],
    }),
    history: builder.query<PaginationResults<Match>, MatchHistoryQuery>({
      query: (query) => ({
        url: QueryUrl(`/matches/history`, query),
        method: "GET",
      }),
      providesTags: ["matchs"],
    }),
    select: builder.query<Match[], MatchSelectQuery>({
      query: (query) => ({
        url: QueryUrl(`/matches/select/`, query),
        method: "GET",
      }),
      providesTags: ["select"],
    }),
    matchById: builder.query<Match, string | number>({
      query: (id) => ({
        url: `/matches/${id}/`,
        method: "GET",
      }),
      providesTags: ["matchItem"],
    }),

    maj: builder.mutation<
      Match,
      { id?: number; data: FormData | MatchFormData }
    >({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/matches/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/matches/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["matchs", "matchItem", "select"],
    }),
    delete: builder.mutation<Match, number>({
      query: (id) => ({
        url: `/matches/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["matchs", "matchItem", "select"],
    }),
  }),
});

export function useMatchFromLocation() {
  const location = useLocation();
  const { matchId } = useParams<{ matchId: string }>();
  const [fetch, { data }] = MatchApi.useLazyMatchByIdQuery();
  const [item, setItem] = useState<Match>(location.state);

  useEffect(() => {
    if (matchId) fetch(matchId);
  }, [matchId]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
