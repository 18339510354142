type ButtonProps = {
  icon?: any;
  title: string;
  id?: string;
  className?: string;
  type: "button" | "submit" | "reset";
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  loadingTitle?: string;
};

const Button = ({
  title,
  type,
  icon,
  loading,
  disabled,
  className,
  id,
  onClick,
  loadingTitle = "Chargement...",
}: ButtonProps) => {
  return (
    <button
      id={id}
      className={className}
      disabled={disabled || loading}
      type={type}
      {...(onClick ? { onClick } : {})}
    >
      {!loading && (
        <span>
          {icon} {title}
        </span>
      )}
      {loading && <span>{loadingTitle}</span>}
    </button>
  );
};

export default Button;
