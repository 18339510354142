import { useUser } from "../../../redux/store";
import { User } from "../../../types";
import { UserQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";
import BreadCrumb from "../components/BreadCrumb";
import AgentTable from "./agent-table";

const Agents = () => {
  const user = useUser();
  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      userType: ["Agents Équipe", "Agents Fédération", "Juge", "Inspecteur"],
      federation: user?.federation?.id,
    },
    {
      federation: true,
      equipe: { federation: user?.federation?.id },
      userType: true,
    }
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Agents"
        subTitle="Liste des agents"
        items={[
          { title: "Home", route: "/dashboard" },
          { title: "Tous les agents" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-warning no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des agents ({props.total})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <AgentTable {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Agents;
