import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../types";
import { AppConfigApi } from "../utils/api/app-config.api";
import { AuthApi } from "../utils/api/auth.api";
import { CategoryApi } from "../utils/api/category.api";
import { CompetitionApi } from "../utils/api/competition.api";
import { ContactApi } from "../utils/api/contact.api";
import { EquipeApi } from "../utils/api/equipe.api";
import { FedesApi } from "../utils/api/fedes.api";
import { GroupeApi } from "../utils/api/groupe.api";
import { MatchApi } from "../utils/api/match.api";
import { NewsApi } from "../utils/api/news.api";
import { NewsletterApi } from "../utils/api/newsletter.api";
import { NiveauApi } from "../utils/api/niveau.api";
import { ParticipeApi } from "../utils/api/participe.api";
import { PhaseApi } from "../utils/api/phase.api";
import { SaisonApi } from "../utils/api/saison.api";
import { ScoreApi } from "../utils/api/score.api";
import { SportApi } from "../utils/api/sports.api";
import { SubscribeApi } from "../utils/api/subscribe.api";
import { UserApi } from "../utils/api/user.api";
import { AppConfigSlice } from "./slices/app-config.slice";
import { userSlice } from "./slices/user.slice";
const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [AppConfigSlice.name]: AppConfigSlice.reducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [FedesApi.reducerPath]: FedesApi.reducer,
    [SportApi.reducerPath]: SportApi.reducer,
    [SaisonApi.reducerPath]: SaisonApi.reducer,
    [AppConfigApi.reducerPath]: AppConfigApi.reducer,
    [EquipeApi.reducerPath]: EquipeApi.reducer,
    [NiveauApi.reducerPath]: NiveauApi.reducer,
    [NewsApi.reducerPath]: NewsApi.reducer,
    [CompetitionApi.reducerPath]: CompetitionApi.reducer,
    [ParticipeApi.reducerPath]: ParticipeApi.reducer,
    [PhaseApi.reducerPath]: PhaseApi.reducer,
    [GroupeApi.reducerPath]: GroupeApi.reducer,
    [MatchApi.reducerPath]: MatchApi.reducer,
    [ScoreApi.reducerPath]: ScoreApi.reducer,
    [NewsletterApi.reducerPath]: NewsletterApi.reducer,
    [SubscribeApi.reducerPath]: SubscribeApi.reducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(AuthApi.middleware)
      .concat(UserApi.middleware)
      .concat(ContactApi.middleware)
      .concat(FedesApi.middleware)
      .concat(SportApi.middleware)
      .concat(CategoryApi.middleware)
      .concat(AppConfigApi.middleware)
      .concat(NiveauApi.middleware)
      .concat(NewsApi.middleware)
      .concat(SaisonApi.middleware)
      .concat(EquipeApi.middleware)
      .concat(CompetitionApi.middleware)
      .concat(PhaseApi.middleware)
      .concat(ParticipeApi.middleware)
      .concat(GroupeApi.middleware)
      .concat(MatchApi.middleware)
      .concat(ScoreApi.middleware)
      .concat(NewsletterApi.middleware)
      .concat(SubscribeApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export function useAppSelector<T>(cb: (s: RootState) => T) {
  return useSelector(cb);
}
export default store;

export function useUser() {
  return useAppSelector((s: RootState) => s.user.user as User);
}
