import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import Input, { Select } from "../../../../components/Input";
import { LoaderPage } from "../../../../components/Loader";
import {
  Competition,
  Groupe,
  Match,
  MatchFormData,
  Phase,
} from "../../../../types";
import { getFN, getId } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import { useGroupeFromLocation } from "../../../../utils/api/groupe.api";
import {
  MatchApi,
  useMatchFromLocation,
} from "../../../../utils/api/match.api";
import { useParticipeSelect } from "../../../../utils/api/participe.api";
import { usePhaseFromLocation } from "../../../../utils/api/phase.api";
import { useUsersSelect } from "../../../../utils/api/user.api";
import { COLORS } from "../../../../utils/constants";
import { useFormData } from "../../../../utils/form";
import { getCompetTypeLabel } from "../../../../utils/hooks";
import BreadCrumb from "../../components/BreadCrumb";

type CreateMatchProps = {
  item?: Match;
  phase: Phase;
  groupe?: Groupe;
  competition: Competition;
};
const CreateMatchComponent = ({
  item,
  competition,
  phase,
  groupe,
}: CreateMatchProps) => {
  const navigate = useNavigate();
  const label = getCompetTypeLabel(competition.type);

  const [exec, { isLoading }] = MatchApi.useMajMutation();
  const { setErrors, register, onSubmitForm, data } =
    useFormData<MatchFormData>(
      {
        date: item?.date || "",
        participeDom: item?.participeDom?.id || "",
        participeExt: item?.participeExt?.id || "",
        juge: item?.juge?.id || "",
        heure: item?.heure || "",
        phase: phase.id,
      },
      (yup) => ({
        date: yup.string().required().label("Date"),
        heure: yup.string().required().label("Heure"),
        juge: yup.string().required().label("Juge"),
        participeDom: yup.string().required().label(`${label} à domicil`),
        participeExt: yup.string().required().label(`${label} à l'extérieure`),
      })
    );

  const { participes } = useParticipeSelect({
    groupe: groupe?.id,
    competition: competition.id,
  });

  const { users: juges } = useUsersSelect({
    federation: getId(competition.federation),
    userType: ["Juge"],
  });

  const onSubmit = React.useCallback(
    async (data: MatchFormData) => {
      if (groupe) {
        data.groupe = groupe.id;
      }
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          date:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Match ${item ? "modifié" : "ajouté"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item, groupe]
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Matchs"
        subTitle={
          item
            ? "Formulaire de modification de match"
            : "Formulaire de création de match"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: phase.name,
            route: `/dashboard/competitions/${competition.slug}/phases/${phase.id}`,
          },
          ...(groupe
            ? [
                {
                  title: groupe.name,
                  route: `/dashboard/competitions/${competition.slug}/phases/${phase.id}/groupe/${groupe.id}`,
                },
              ]
            : []),
          {
            title: item ? "Modification de match" : "Création de match",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} de
                      match
                    </h5>
                    {!!item && (
                      <>
                        <div>
                          Dernière modification:{" "}
                          {moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                        </div>
                        <div>Modifiée par: {getFN(item.updatedBy)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12">
                        <Select
                          placeholder={"Choisir un juge"}
                          {...register("juge")}
                          label="Juge"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={juges}
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          placeholder={"Date"}
                          label="Date"
                          {...register("date")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="date"
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          placeholder={"Heure"}
                          label="Heure"
                          {...register("heure")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="time"
                        />
                      </div>
                      <div className="col-md-12">
                        <Select
                          placeholder={`${label} à domicil`}
                          {...register("participeDom")}
                          label={`${label} à domicil`}
                          containerClass="form-group"
                          inputClass="form-control"
                          options={participes.filter(
                            (f) => f.value != data.participeExt
                          )}
                        />
                      </div>
                      <div className="col-md-12">
                        <Select
                          placeholder={`${label} à l'extérieure`}
                          {...register("participeExt")}
                          label={`${label} à l'extérieure`}
                          containerClass="form-group"
                          inputClass="form-control"
                          options={participes.filter(
                            (f) => f.value != data.participeDom
                          )}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function CreateMatch({ item }: { item?: Match }) {
  const { item: comp } = useCompetitionFromLocation();
  const { item: phase } = usePhaseFromLocation();
  const { item: groupe } = useGroupeFromLocation(false);
  if (!comp || !phase) return <LoaderPage />;
  return (
    <CreateMatchComponent
      groupe={groupe}
      phase={phase}
      item={item}
      competition={comp}
    />
  );
}

export default CreateMatch;

export function UpdateMatch() {
  const { item } = useMatchFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateMatch item={item} />;
}
