import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import moment from "moment";
import Table, {
  DeleteButton,
  TableHeader,
  TableProps,
  imageFormatter,
} from "../../../components/Table";

import { User } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";

type AbonnesTableProps = Omit<TableProps<User>, "keyField" | "headers"> & {};
const commonHeaders: TableHeader<User>[] = [
  { name: "image", title: "Image", formatter: imageFormatter },
  { name: "prenom", title: "Nom", formatter: getFN },
  { name: "email", title: "Email" },
  { name: "telephone", title: "Téléphone" },
];
const AbonnesTable = ({ ...props }: AbonnesTableProps) => {
  const [onDelete] = UserApi.useDeleteMutation();
  const headers: TableHeader<User>[] = [
    ...commonHeaders,
    {
      name: "createdAt",
      title: "Date d'inscription",
      formatter: (i) => moment(i.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <DeleteButton
            permission="abonnes.delete"
            id={item.id}
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Abonné supprimé avec succès"}
          />
        </>
      ),
    },
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default AbonnesTable;
