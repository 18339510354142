import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { LoaderPage } from "../../components/Loader";
import AthleteItem from "../../components/SideBar/PostWidgetItem/AthleteItem";
import SideBar from "../../components/SideBar/SideBar";
import { UserApi } from "../../utils/api/user.api";
import CompetitionItem from "../federation/components/CompetitionItem";
import FederationItem from "../federation/components/FederationItem";
import { EquipeItem } from "../federation/detailFederation";

const Search = () => {
  const location = useLocation();

  const [params] = useSearchParams();
  const s = params.get("search");
  const [search, setSearch] = useState(s ?? "");
  const { data, isLoading, isFetching } = UserApi.useSearchQuery({ search });
  useEffect(() => {
    const search = params.get("search");
    setSearch(search ?? "");
  }, [location.search, params]);
  if (isLoading || isFetching) return <LoaderPage />;
  const { athletes, competitions, equipes, federations } = data || {};
  const empty =
    !athletes?.length &&
    !competitions?.length &&
    !equipes?.length &&
    !federations?.length;
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="forum-box">
                  {empty && (
                    <div style={{ margin: "10px 0ppx" }}>
                      <div className="title-section">
                        <h1>
                          <span>Résultasts (0)</span>
                        </h1>
                      </div>
                    </div>
                  )}
                  {!!federations?.length && (
                    <div style={{ margin: "10px 0ppx" }}>
                      <div className="title-section">
                        <h1>
                          <span>Fédération ({federations.length})</span>
                        </h1>
                      </div>
                      <ul className="list-posts">
                        {federations.map((c) => (
                          <FederationItem item={c} key={c.id} />
                        ))}
                      </ul>
                    </div>
                  )}
                  {!!equipes?.length && (
                    <div style={{ margin: "10px 0ppx" }}>
                      <div className="title-section">
                        <h1>
                          <span>Equipes ({equipes.length})</span>
                        </h1>
                      </div>
                      <ul className="list-posts">
                        {equipes.map((c) => (
                          <EquipeItem item={c} key={c.id} />
                        ))}
                      </ul>
                    </div>
                  )}
                  {!!athletes?.length && (
                    <div style={{ margin: "10px 0ppx" }}>
                      <div className="title-section">
                        <h1>
                          <span>Athlètes ({athletes.length})</span>
                        </h1>
                      </div>
                      <ul className="list-posts">
                        {athletes.map((c) => (
                          <AthleteItem item={c} key={c.id} />
                        ))}
                      </ul>
                    </div>
                  )}
                  {!!competitions?.length && (
                    <div style={{ margin: "10px 0ppx" }}>
                      <div className="title-section">
                        <h1>
                          <span>Compétitions ({competitions.length})</span>
                        </h1>
                      </div>
                      <ul className="list-posts">
                        {competitions.map((c) => (
                          <CompetitionItem item={c} key={c.id} />
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Search;
