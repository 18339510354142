import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useScriptEffect = (cb: () => void) => {
  const [exec, setExec] = useState(false);
  useEffect(() => {
    setExec((old) => {
      if (!old) {
        cb();
        return true;
      }
      return old;
    });
  }, []);
};

export function useAdminScripts() {
  useScriptEffect(() => {
    $(document).ready(function ($: any) {
      // Toggle user info on sidebar
      $(".user-info-handle").on("click", function (event: any) {
        event.preventDefault();
        $(".user-info").toggleClass("closed");
      });

      // Toggle small sidebar
      $(".small-nav-handle").on("click", function (event: any) {
        event.preventDefault();
        $(".left-sidebar").toggleClass("small-nav");
        $(".navbar-header").toggleClass("small-nav-header");
      });

      // Toggle Mobile Nav
      $(".mobile-nav-toggle").on("click", function (event: any) {
        event.preventDefault();
        $(".left-sidebar").toggle();
      });

      // Toggle tooltips
      $('[data-toggle="tooltip"]').tooltip();

      // Toggle popovers
      $('[data-toggle="popover"]').popover();

      // For custom modal backdrop
      $(".modal[data-backdrop-color]").on(
        "show.bs.modal hide.bs.modal",
        function () {
          // @ts-ignore
          $("body").toggleClass("modal-color-" + $(this).data("backdropColor"));
        }
      );

      // Open right sidebar
      $(".open-right-sidebar").on("click", function (event: any) {
        event.preventDefault();
        $(".right-sidebar, .right-sidebar .sidebar-content").css(
          "right",
          "0px"
        );
      });
      $(".right-sidebar .close-icon").on("click", function (event: any) {
        event.preventDefault();
        $(".right-sidebar, .right-sidebar .sidebar-content").css(
          "right",
          "-400px"
        );
      });

      // Initialize panel controls
      $("[data-panel-control]").lobiPanel();

      // Visibility of source code button
      $(".src-btn").hide();
      $(".toggle-help-handle").on("click", function (event: any) {
        event.preventDefault();
        $(".src-btn").toggle();
      });

      // Visibility of source code button
      $(".src-code").hide();
      $(".toggle-code-handle").on("click", function (event: any) {
        event.preventDefault();
        $(".src-code").toggle();
      });

      // Toggle full screen
      $(".full-screen-handle").on("click", function (event: any) {
        event.preventDefault();

        if (
          // @ts-ignore
          (document.fullScreenElement && document.fullScreenElement !== null) ||
          // @ts-ignore
          (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
          // @ts-ignore
          if (document.documentElement.requestFullScreen) {
            // @ts-ignore
            document.documentElement.requestFullScreen();
            // @ts-ignore
          } else if (document.documentElement.mozRequestFullScreen) {
            // @ts-ignore
            document.documentElement.mozRequestFullScreen();
            // @ts-ignore
          } else if (document.documentElement.webkitRequestFullScreen) {
            // @ts-ignore
            document.documentElement.webkitRequestFullScreen(
              // @ts-ignore
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          // @ts-ignore
          if (document.cancelFullScreen) {
            // @ts-ignore
            document.cancelFullScreen();
            // @ts-ignore
          } else if (document.mozCancelFullScreen) {
            // @ts-ignore
            document.mozCancelFullScreen();
            // @ts-ignore
          } else if (document.webkitCancelFullScreen) {
            // @ts-ignore
            document.webkitCancelFullScreen();
          }
        }
      });

      // Toggle sidebar dropdown
      $(".has-children").not(".open").find(".child-nav").slideUp("100");
      $(".has-children>a").on("click", function (event: any) {
        event.preventDefault();
        // @ts-ignore
        $(this).parent().toggleClass("open");
        // @ts-ignore
        $(this).parent().find(".child-nav").slideToggle("500");
      });

      // For Dropdown menu animation
      var dropdownSelectors = $(".dropdown, .dropup");

      // Custom function to read dropdown data
      // =========================
      function dropdownEffectData(target: any) {
        // @todo - page level global?
        var effectInDefault = null,
          effectOutDefault = null;
        var dropdown = $(target),
          dropdownMenu = $(".dropdown-menu", target);
        var parentUl = dropdown.parents("ul.nav");

        // If parent is ul.nav allow global effect settings
        if (parentUl.size() > 0) {
          effectInDefault = parentUl.data("dropdown-in") || null;
          effectOutDefault = parentUl.data("dropdown-out") || null;
        }

        return {
          target: target,
          dropdown: dropdown,
          dropdownMenu: dropdownMenu,
          effectIn: dropdownMenu.data("dropdown-in") || effectInDefault,
          effectOut: dropdownMenu.data("dropdown-out") || effectOutDefault,
        };
      }

      // Custom function to start effect (in or out)
      // =========================
      function dropdownEffectStart(data: any, effectToStart: any) {
        if (effectToStart) {
          data.dropdown.addClass("dropdown-animating");
          data.dropdownMenu.addClass("animated");
          data.dropdownMenu.addClass(effectToStart);
        }
      }

      // Custom function to read when animation is over
      // =========================
      function dropdownEffectEnd(data: any, callbackFunc: any) {
        var animationEnd =
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
        data.dropdown.one(animationEnd, function () {
          data.dropdown.removeClass("dropdown-animating");
          data.dropdownMenu.removeClass("animated");
          data.dropdownMenu.removeClass(data.effectIn);
          data.dropdownMenu.removeClass(data.effectOut);

          // Custom callback option, used to remove open class in out effect
          if (typeof callbackFunc == "function") {
            callbackFunc();
          }
        });
      }

      // Bootstrap API hooks
      // =========================
      dropdownSelectors.on({
        "show.bs.dropdown": function () {
          // On show, start in effect
          var dropdown = dropdownEffectData(this);
          dropdownEffectStart(dropdown, dropdown.effectIn);
        },
        "shown.bs.dropdown": function () {
          // On shown, remove in effect once complete
          var dropdown = dropdownEffectData(this);
          if (dropdown.effectIn && dropdown.effectOut) {
            dropdownEffectEnd(dropdown, function () {});
          }
        },
        "hide.bs.dropdown": function (e: any) {
          // On hide, start out effect
          var dropdown = dropdownEffectData(this);
          if (dropdown.effectOut) {
            e.preventDefault();
            dropdownEffectStart(dropdown, dropdown.effectOut);
            dropdownEffectEnd(dropdown, function () {
              dropdown.dropdown.removeClass("open");
            });
          }
        },
      });

      // @ts-ignore
      $(".counter").counterUp({
        delay: 10,
        time: 1000,
      });
    });
  });
}

export function useScripts() {
  const location = useLocation();
  useScriptEffect(() => {
    /*jshint jquery:true */

    $(document).ready(function ($: any) {
      /* global google: false */
      /*jshint -W018 */

      /*-------------------------------------------------*/
      /* =  portfolio isotope
	/*-------------------------------------------------*/

      var winDow = $(window);
      // Needed variables
      var $container = $(".iso-call");
      var $filter = $(".filter");

      try {
        $container.imagesLoaded(function () {
          // init
          winDow.trigger("resize");
          $container.isotope({
            filter: "*",
            layoutMode: "masonry",
            itemSelector: ".iso-call > div",
            masonry: {
              columnWidth: ".default-size",
            },
            animationOptions: {
              duration: 750,
              easing: "linear",
            },
          });
        });
      } catch (err) {}

      winDow.on("resize", function () {
        var selector = $filter.find("a.active").attr("data-filter");

        try {
          $container.isotope({
            filter: selector,
            animationOptions: {
              duration: 750,
              easing: "linear",
              queue: false,
            },
          });
        } catch (err) {}
        return false;
      });

      // Isotope Filter
      $filter.find("a").on("click", function () {
        // @ts-ignore
        var selector = $(this).attr("data-filter");

        try {
          $container.isotope({
            filter: selector,
            animationOptions: {
              duration: 750,
              easing: "linear",
              queue: false,
            },
          });
        } catch (err) {}
        return false;
      });

      var filterItemA = $(".filter li a");

      filterItemA.on("click", function () {
        // @ts-ignore
        var $this = $(this);
        if (!$this.hasClass("active")) {
          filterItemA.removeClass("active");
          $this.addClass("active");
        }
      });

      $("#container").addClass("active");
      $(".iso-call").css("opacity", 0);
      $(".iso-call").imagesLoaded(function () {
        $(".iso-call").css("opacity", 1);
      });

      /*-------------------------------------------------*/

      $("a.toogle-box").on("click", function (event: any) {
        event.preventDefault();
        // @ts-ignore
        const $this = $(this);
        if (!$this.hasClass("active")) {
          $this.addClass("active");
          $("div.versions-box-choose").addClass("closed");
        } else {
          $this.removeClass("active");
          $("div.versions-box-choose").removeClass("closed");
        }
      });

      /*-------------------------------------------------*/
      /* = ticker news
	/*-------------------------------------------------*/

      try {
        $("#js-news").ticker({
          speed: 0.2, // The speed of the reveal
          controls: true, // Whether or not to show the jQuery News Ticker controls
          titleText: "", // To remove the title set this to an empty String
          displayType: "reveal", // Animation type - current options are 'reveal' or 'fade'
          direction: "ltr", // Ticker direction - current options are 'ltr' or 'rtl'
          pauseOnItems: 2000, // The pause on a news item before being replaced
          fadeInSpeed: 600, // Speed of fade in animation
          fadeOutSpeed: 300, // Speed of fade out animation
        });
      } catch (err) {}

      /*-------------------------------------------------*/
      /* =  OWL carousell - featured post, video post, gallery posts
	/*-------------------------------------------------*/
      try {
        var owlWrap = $(".owl-wrapper");

        if (owlWrap.length > 0) {
          // @ts-ignore
          if (jQuery().owlCarousel) {
            owlWrap.each(function () {
              // @ts-ignore
              var carousel = $(this).find(".owl-carousel"),
                // @ts-ignore
                dataNum = $(this).find(".owl-carousel").attr("data-num"),
                dataNum2,
                dataNum3;

              if (dataNum == 1) {
                dataNum2 = 1;
                dataNum3 = 1;
              } else if (dataNum == 2) {
                dataNum2 = 2;
                dataNum3 = dataNum - 1;
              } else {
                dataNum2 = dataNum - 1;
                dataNum3 = dataNum - 2;
              }

              carousel.owlCarousel({
                autoPlay: 10000,
                navigation: true,
                items: dataNum,
                itemsDesktop: [1199, dataNum2],
                itemsDesktopSmall: [979, dataNum3],
              });
            });
          }
        }
      } catch (err) {}

      try {
        var skillBar = $(".review-box");
        skillBar.appear(function () {
          var animateElement = $(".meter > p");
          animateElement.each(function () {
            // @ts-ignore
            const $this = $(this);
            $this
              .data("origWidth", $this.width())
              .width(0)
              .animate(
                {
                  width: $this.data("origWidth"),
                },
                1200
              );
          });
        });
      } catch (err) {}

      /* ---------------------------------------------------------------------- */
      /*	register login forms
	/* ---------------------------------------------------------------------- */

      $(".register-line a").on("click", function (event: any) {
        event.preventDefault();
        $("form.login-form").slideUp(400);
        $("form.register-form").slideDown(400);
      });

      $("a.lost-password").on("click", function (event: any) {
        event.preventDefault();
        $("form.login-form").slideUp(400);
        $("form.lost-password-form").slideDown(400);
      });

      $(".login-line a").on("click", function () {
        console.log("clicked");
        $("form.lost-password-form").slideUp(400);
        $("form.register-form").slideUp(400);
        $("form.login-form").slideDown(400);
      });

      /* ---------------------------------------------------------------------- */
      /*	Header animate after scroll
	/* ---------------------------------------------------------------------- */

      (function () {
        var docElem = document.documentElement,
          didScroll = false,
          changeHeaderOn = 300;
        document.querySelector("header");
        function init() {
          window.addEventListener(
            "scroll",
            function () {
              if (!didScroll) {
                didScroll = true;
                setTimeout(scrollPage, 100);
              }
            },
            false
          );
        }

        function scrollPage() {
          var sy = scrollY();
          if (sy >= changeHeaderOn) {
            $("header").addClass("active");
          } else {
            $("header").removeClass("active");
          }
          didScroll = false;
        }

        function scrollY() {
          return window.pageYOffset || docElem.scrollTop;
        }

        init();
      })();
    });
  });
}

export const useScript = (uris: string[]) => {
  const [urls] = useState(uris);
  const [scripts, setScripts] = useState<HTMLScriptElement[]>([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    createScriptsAsync(urls).then((scripts) => {
      setScripts((old) => {
        if (old.length === 0) {
          setLoaded(true);
          return scripts;
        } else {
          for (let script of old) {
            if (document.body.contains(script))
              document.body.removeChild(script);
          }
          setLoaded(true);
          return scripts;
        }
      });
    });
  }, [urls]);

  return loaded;
};

async function createScriptsAsync(urls: string[]) {
  const scripts: HTMLScriptElement[] = [];
  for (let script of urls) {
    const res = await createScriptAsync(script);
    if (res) {
      scripts.push(res);
    }
  }
  return scripts;
}

function createScriptAsync(url: string) {
  return new Promise<HTMLScriptElement | null>((resolve) => {
    const elm = document.getElementById(url);
    if (!elm) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.id = url;
      script.onload = (e) => {
        resolve(script);
      };
      script.onerror = (e) => {
        resolve(null);
      };
      document.body.appendChild(script);
    } else {
      resolve(elm as HTMLScriptElement);
    }
  });
}
