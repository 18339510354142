import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onLogout } from "../../../redux/slices/user.slice";
import { useAppDispatch, useUser } from "../../../redux/store";
import { getFN, getImage } from "../../../utils/api/common.api";
import {
  useCompetitionToValidate,
  useUserToValidate,
} from "../../../utils/hooks";
import { PermissionValue, usePermissions } from "../../../utils/permissions";

export type SideBarLink = {
  icon: any;
  title: string;
  route: any;
  badge?: any;
  permissions?: PermissionValue[];
};
type SideBarLinkItemType = { title: string; links: SideBarLink[] };

export type SideBarLinks = SideBarLinkItemType[];

const SIDEBAR_LINKS: SideBarLinks = [
  {
    title: "Menu principal",
    links: [
      {
        title: "Tableau de bord",
        icon: <i className="fa fa-dashboard" />,
        route: "/dashboard",
      },
    ],
  },
  {
    title: "Fédérations",
    links: [
      {
        title: "Créer une fédération",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/federations/create",
        permissions: ["fede.create"],
      },
      {
        title: "Liste des fédérations",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/federations",
        permissions: ["fede.list"],
      },
      {
        title: "Voir sa fédération",
        icon: <i className="fa fa-check-square-o"></i>,
        route: "/dashboard/federations/ma-federation",
        permissions: ["fede.viewfederation"],
      },
    ],
  },
  {
    title: "Sports",
    links: [
      {
        title: "Créer un sport",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/sports/create",
        permissions: ["sports.create"],
      },
      {
        title: "Liste des sports",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/sports",
        permissions: ["sports.list"],
      },
    ],
  },
  {
    title: "Saisons",
    links: [
      {
        title: "Créer une saison",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/saisons/create",
        permissions: ["saisons.create"],
      },
      {
        title: "Liste des saisons",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/saisons",
        permissions: ["saisons.list"],
      },
    ],
  },
  {
    title: "Catégories",
    links: [
      {
        title: "Créer une catégorie",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/niveaux/create",
        permissions: ["niveaux.create"],
      },
      {
        title: "Liste des catégories",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/niveaux",
        permissions: ["niveaux.list"],
      },
    ],
  },
  {
    title: "Agents",
    links: [
      {
        title: "Créer un agent",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/agents/create",
        permissions: ["agents.create"],
      },

      {
        title: "Liste des agents",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/agents",
        permissions: ["agents.list"],
      },
    ],
  },

  {
    title: "Equipes",
    links: [
      {
        title: "Créer une équipe",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/equipes/create",
        permissions: ["equipes.create"],
      },
      {
        title: "Liste des équipes",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/equipes",
        permissions: ["equipes.list"],
      },
      {
        title: "Voir son équipe",
        icon: <i className="fa fa-check-square-o"></i>,
        route: "/dashboard/equipes/mon-equipe",
        permissions: ["equipes.viewteam"],
      },
    ] as SideBarLink[],
  },
  {
    title: "Athlètes",
    links: [
      {
        title: "Créer un athlète",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/athletes/create",
        permissions: ["athletes.create"],
      },
      {
        title: "Athlète à valider",
        icon: <i className="fa fa-check-square-o"></i>,
        route: "/dashboard/athletes/valider",
        permissions: ["athletes.validate"],
      },
      {
        title: "Liste des athlètes",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/athletes",
        permissions: ["athletes.list"],
      },
    ],
  },
  {
    title: "Abonnés",
    links: [
      {
        title: "Liste des abonnés",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/abonnes",
        permissions: ["abonnes.list"],
      },
    ],
  },
  {
    title: "Compétition/Activité",
    links: [
      {
        title: "Créer une compétition",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/competitions/create",
        permissions: ["competitions.create"],
      },
      {
        title: "Compétition à valider",
        icon: <i className="fa fa-check-square-o"></i>,
        route: "/dashboard/competitions/valider",

        permissions: ["competitions.validate"],
      },
      {
        title: "Liste des compétitions",
        icon: <i className="fa fa-file-text"></i>,
        route: "/dashboard/competitions",
        permissions: ["competitions.list"],
      },
    ],
  },
  {
    title: "Président fédérations",
    links: [
      {
        title: "Créer un président",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/presidents-federations/create",
        permissions: ["president-fede.create"],
      },
      {
        title: "List des présidents",
        icon: <i className="fa fa-users"></i>,
        route: "/dashboard/presidents-federations",
        permissions: ["president-fede.list"],
      },
    ],
  },
  {
    title: "Agents CNO",
    links: [
      {
        title: "Créer un agents CNO",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/agents-cno/create",
        permissions: ["agents-cno.create"],
      },
      {
        title: "List des agents CNO",
        icon: <i className="fa fa-users"></i>,
        route: "/dashboard/agents-cno",
        permissions: ["agents-cno.list"],
      },
    ],
  },
  {
    title: "Breaking news",
    links: [
      {
        title: "Créer un news",
        icon: <i className="fa fa-plus"></i>,
        route: "/dashboard/news/create",
        permissions: ["news"],
      },
      {
        title: "List des news",
        icon: <i className="fa fa-newspaper-o"></i>,
        route: "/dashboard/news",
        permissions: ["news"],
      },
    ],
  },
  {
    title: "Profil",
    links: [
      {
        title: "Profil",
        icon: <i className="fa fa-user"></i>,
        route: "/dashboard/profil",
      },
      {
        title: "Mot de passe",
        icon: <i className="fa fa-cog"></i>,
        route: "/dashboard/profil/update-password",
      },
    ],
  },
  {
    title: "Gestion des contenus",
    links: [
      {
        title: "Message de contacts",
        icon: <i className="fa fa-book"></i>,
        route: "/dashboard/contacts",
        permissions: ["president-fede.create"],
      },
      {
        title: "Coordonnées",
        icon: <i className="fa fa-info"></i>,
        route: "/dashboard/config",
        permissions: ["coordonnees", "reseaux-sociaux"],
      },
      // {
      //   title: "Publicité",
      //   icon: <i className="fa fa-ticket"></i>,
      //   route: "#",
      //   permissions: ["pubs"],
      // },

      {
        title: "Role & Permissions",
        icon: <i className="fa fa-cog"></i>,
        route: "/dashboard/roles-et-permissions",
      },
    ],
  },
];

export function useAdminSidebar() {
  const user = useUser();
  const isIndu = user?.federation?.type === "Individuel";
  const [data, setData] = useState<SideBarLinks>(SIDEBAR_LINKS);

  useEffect(() => {
    let links = [...SIDEBAR_LINKS];
    if (isIndu) {
      links = SIDEBAR_LINKS.filter((i) => i.title !== "Equipes");
    }
    links.splice(1, 0, {
      title: "Menu principal",
      links: [
        {
          title: "Score à valider",
          icon: <i className="fa fa-check-square-o" />,
          route: "/dashboard/matches/valider",
          permissions: ["score.validate"],
        },
        {
          title: "Matches",
          icon: <i className="fa fa-area-chart" />,
          route: "/dashboard/matches",
        },
      ],
    });
    setData(links);
  }, [isIndu]);

  return data;
}

function RouteItem({ link }: { link: SideBarLink }) {
  return (
    <li>
      <Link to={link.route}>
        {link.icon} <span>{link.title}</span> {link.badge}
      </Link>
    </li>
  );
}

function ValidateAthleteRoute({ link }: { link: SideBarLink }) {
  const props = useUserToValidate();
  return (
    <RouteItem
      link={{
        ...link,
        badge: (
          <span className="label label-success ml-5">{props.total ?? 0}</span>
        ),
      }}
    />
  );
}

function ValidateComptetitionRoute({ link }: { link: SideBarLink }) {
  const props = useCompetitionToValidate();
  return (
    <RouteItem
      link={{
        ...link,
        badge: (
          <span className="label label-primary ml-5">{props?.total ?? 0}</span>
        ),
      }}
    />
  );
}

function SideBarLinkItem({ item }: { item: SideBarLinkItemType }) {
  const { hasAccess, user } = usePermissions();
  const ignoreAll = item.links.some((a) => !a.permissions);

  const pers = item.links.reduce(
    (acc: PermissionValue[], cur) => [...acc, ...(cur.permissions ?? [])],
    []
  );
  const isAccess = hasAccess(pers);
  if (!isAccess && !ignoreAll && pers.length > 0) return null;

  return (
    <React.Fragment>
      <li className="nav-header">
        <span className="">{item.title}</span>
      </li>
      {item.links.map((link, index) => {
        const hasAcc = link.permissions ? hasAccess(link.permissions) : true;
        if (!hasAcc) return null;
        if (link.permissions?.[0] === "athletes.validate")
          return <ValidateAthleteRoute key={index} link={link} />;

        if (link.permissions?.[0] === "competitions.validate")
          return <ValidateComptetitionRoute key={index} link={link} />;
        if (
          link.title.includes("Permission") &&
          user?.userType !== "Admin System"
        )
          return null;
        return <RouteItem key={index} link={link} />;
      })}
    </React.Fragment>
  );
}

function AllSideBarLinks() {
  const data = useAdminSidebar();
  return (
    <ul className="side-nav color-gray">
      {data.map((it, index) => {
        return <SideBarLinkItem key={index} item={it} />;
      })}
    </ul>
  );
}
const AdminSideBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();
  return (
    <div className="left-sidebar fixed-sidebar bg-black-300 box-shadow tour-three">
      <div className="sidebar-content">
        <div className="user-info closed">
          <img
            src={getImage(user?.image)}
            alt={getFN(user)}
            className="img-circle profile-img"
          />
          <h6 className="title">
            {user.prenom} {user.nom}
          </h6>
          <small className="info">{user.userType}</small>
        </div>
        <div className="sidebar-nav">
          <AllSideBarLinks />
          <div className="purchase-btn hidden-sm hidden-xs">
            <button
              onClick={() => {
                dispatch(onLogout());
                navigate("/auth/se-connecter", { replace: true });
              }}
              className="btn btn-danger btn-labeled"
            >
              Déconnexion
              <span className="btn-label btn-label-right">
                <i className="fa fa-sign-out"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
