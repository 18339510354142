import moment from "moment";
import { Link } from "react-router-dom";
import { Competition } from "../../../types";
import { getImage } from "../../../utils/api/common.api";
const CompetitionItem = ({
  item,
  md = 6,
  small,
  large,
}: {
  item: Competition;
  md?: number;
  small?: boolean;
  large?: boolean;
}) => {
  const link = `/competition/${item.slug}`;
  const height = small ? 250 : large ? 350 : 250;
  const image = getImage(item.image);
  const isPng = image?.toLowerCase?.()?.includes(".png");

  return (
    <div
      className={`col-md-${md} col-sm-${md === 12 ? 12 : 6} col-xs-12`}
      style={{ marginBottom: 10, marginTop: 10 }}
    >
      <div className="image-post2">
        <div className="post-gallery">
          <img
            src={image}
            alt=""
            style={{
              objectFit: isPng ? "contain" : "cover",
              width: "100%",
              height,
            }}
          />
          <div
            className="hover-box"
            style={{ backgroundColor: "rgba(0,0,0,.5)", height }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height,
                padding: 10,
              }}
            >
              <a href="#" className="category-post world">
                {item.sport.name}
              </a>
              <h4 style={{ color: "#FFF" }}>{item.name}</h4>
              <ul className="post-tags">
                <li>
                  Du {moment(item.startDate).format("DD/MM/YYYY")} au{" "}
                  {moment(item.endDate).format("DD/MM/YYYY")}
                </li>
                <li>{item.nbreEquipe} équipes</li>

                <div style={{ marginTop: 10 }}>
                  <Link to={link} className="read-more-button">
                    <i className="fa fa-arrow-circle-right"></i> Voir
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitionItem;
