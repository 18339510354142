import { User } from "../../../types";
import { UserQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";
import BreadCrumb from "../components/BreadCrumb";
import AbonnesTable from "./abonne-table";

const Abonnes = () => {
  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      userType: ["Abonné"],
    },
    {}
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Abonnés"
        subTitle="Liste des abonnés"
        items={[{ title: "Home" }, { title: "Tous les abonnés" }]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-warning no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des abonnés ({props.total})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <AbonnesTable {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Abonnes;
