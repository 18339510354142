import Table, {
  DeleteButton,
  TableHeader,
  UpdateButton,
} from "../../../components/Table";
import { Sport } from "../../../types";
import { CommonQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { SportApi } from "../../../utils/api/sports.api";
import BreadCrumb from "../components/BreadCrumb";

const Sports = () => {
  const props = usePaginationQuery<Sport, CommonQuery>(
    SportApi.useSportsQuery,
    {}
  );
  const [onDelete] = SportApi.useDeleteMutation();

  const headers: TableHeader<Sport>[] = [
    { name: "name", title: "Nom" },
    { name: "typeScore", title: "Score" },
    { name: "pointsVictoire", title: "Points/Victoire" },
    { name: "pointsDefaite", title: "Points/Défaite" },
    { name: "pointsNull", title: "Points/Nul" },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <UpdateButton
            route={`/dashboard/sports/update/${item.slug}`}
            state={item}
            permission="sports.update"
          />
          <DeleteButton
            id={item.id}
            permission="sports.delete"
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Sport supprimé avec succès"}
          />
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Sports"
        subTitle="Liste des sports"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Toutes les sports" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des sports ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sports;
