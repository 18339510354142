import moment from "moment";
import { LoaderPage } from "../../../components/Loader";
import { User } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import { useUserFromLocation } from "../../../utils/api/user.api";
import { DataItem, DataList } from "../athletes/detail-athlete";
import BreadCrumb from "../components/BreadCrumb";

type DetailUserProps = {
  item: User;
};

const DetailUserComponent = ({ item }: DetailUserProps) => {
  const data: DataItem[] = [
    { title: "Prénom", value: item.prenom },
    { title: "Nom", value: item.nom },
    { title: "Email", value: item.email },
    { title: "Téléphone", value: item.telephone },
    { title: "Fonction", value: item.fonction },
    {
      title: "Date de naissance",
      value: moment(item.birthDate).format("DD/MM/YYYY"),
    },
    { title: "Adresse", value: item.address },
    { title: "Nationalité", value: item.nationality },
  ];
  if (item.userType === "President Fédération") {
    data.splice(3, 0, { title: "Fédération", value: item.federation?.nom });
  }
  return (
    <div className="main-page">
      <BreadCrumb
        title="Agents"
        subTitle={"Détail agent"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: getFN(item),
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - détail</h5>
                  </div>
                </div>
                <div className="panel-body">
                  <DataList data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default function DetailUser() {
  const { item } = useUserFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <DetailUserComponent item={item} />;
}
