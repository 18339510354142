import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import Dashboard from "../pages/admin/Dashboard";
import Agents from "../pages/admin/agents/agents";
import CreateAgent, { UpdateAgent } from "../pages/admin/agents/create-agent";
import DetailAgent from "../pages/admin/agents/detail-agent";
import Abonnes from "../pages/admin/athletes/abonnes";
import Athletes from "../pages/admin/athletes/athletes";
import AthletesAvalider from "../pages/admin/athletes/athletes-a-valider";
import CreateAthlete, {
  UpdateAthlete,
} from "../pages/admin/athletes/create-athlete";
import DetailAthlete from "../pages/admin/athletes/detail-athlete";
import Competitions from "../pages/admin/competitions/competitions";
import CompetitionsAvalider from "../pages/admin/competitions/competitions-a-valider";
import CreateCompetition, {
  UpdateCompetition,
} from "../pages/admin/competitions/create-competition";
import DetailCompetiton from "../pages/admin/competitions/detail-competition";
import CreateGroupe, {
  UpdateGroupe,
} from "../pages/admin/competitions/groupes/create-groupe";
import DetailGroupe from "../pages/admin/competitions/groupes/detail-groupe";
import CreateMatch, {
  UpdateMatch,
} from "../pages/admin/competitions/matches/create-match";
import SelectAthlete from "../pages/admin/competitions/participes/select-athlete";
import SelectEquipe from "../pages/admin/competitions/participes/select-equipe";
import SelectEquipeGroupe from "../pages/admin/competitions/participes/select-equipe-groupe";
import CreatePhase, {
  UpdatePhase,
} from "../pages/admin/competitions/phases/create-phase";
import DetailPhase from "../pages/admin/competitions/phases/detail-phase";
import AdminNavbar from "../pages/admin/components/AdminNavbar";
import AdminSideBar from "../pages/admin/components/AdminSideBar";
import AppConfigs from "../pages/admin/config/app-configs";
import UpdateAppConfig from "../pages/admin/config/create-saison";
import { DetailContact } from "../pages/admin/contacts/contact-form";
import Contacts from "../pages/admin/contacts/contacts";
import CreateEquipe, {
  UpdateEquipe,
} from "../pages/admin/equipes/create-equipe";
import DetailEquipe, { MonEquipe } from "../pages/admin/equipes/detail-equipe";
import Equipes from "../pages/admin/equipes/equipes";
import CreateFede, { UpdateFede } from "../pages/admin/fedes/create-fede";
import Fedes from "../pages/admin/fedes/fedes";
import ShowFede, { MaFederation } from "../pages/admin/fedes/show-fede";
import Matches from "../pages/admin/matches/matches";
import MatchesAValider from "../pages/admin/matches/matches-to-validate";
import CreateNews, { UpdateNews } from "../pages/admin/news/create-news";
import BreakingNews from "../pages/admin/news/news";
import CreateNiveau, {
  UpdateNiveau,
} from "../pages/admin/niveau/create-niveau";
import Niveaux from "../pages/admin/niveau/niveaux";
import Roles from "../pages/admin/roles/roles";
import UpdateRole from "../pages/admin/roles/update-role";
import CreateSaison, {
  UpdateSaison,
} from "../pages/admin/saisons/create-saison";
import Saisons from "../pages/admin/saisons/saisons";
import CreateSport, { UpdateSport } from "../pages/admin/sports/create-sport";
import Sports from "../pages/admin/sports/sports";
import AgentsCno from "../pages/admin/users/agents-cno";
import {
  CreateAgentCNO,
  CreatePresidentFede,
  UpdateUser,
} from "../pages/admin/users/create-user";
import DetailUser from "../pages/admin/users/detail-user";
import PresiUsers from "../pages/admin/users/presi-users";
import UpdatePassword from "../pages/admin/users/update-password";
import ProfileUser from "../pages/admin/users/user-profile";
import Athlete from "../pages/athlete";
import DetailAthleteFront from "../pages/athlete/detailAthlete";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgetPassword from "../pages/auth/forget-password";
import ResetPassword from "../pages/auth/reset-password";
import Competition from "../pages/competition";
import DetailCompetition from "../pages/competition/detailCompetition";
import Contact from "../pages/contact";
import EquipesFront from "../pages/equipe";
import DetailEquipeFront from "../pages/equipe/detailEquipe";
import Federation from "../pages/federation";
import DetailFederation from "../pages/federation/detailFederation";
import HomePage from "../pages/home";
import DetailMatch from "../pages/match/detailMatch";
import Search from "../pages/search";
import { useAppSelector, useUser } from "../redux/store";
import { useAdminScripts, useScript, useScripts } from "../utils/scripts";
import { useStyles } from "../utils/styles";

const commonJs: string[] = [];
const commonCss: string[] = [];

function UnmountOnBlur({
  component: Component,
  verifyIfActive,
}: {
  component: any;
  verifyIfActive: (path: string) => boolean;
}) {
  const location = useLocation();
  const isActive = verifyIfActive(location.pathname);
  if (!isActive) return null;

  return <Component />;
}

function LoadAdminJsAndCss() {
  const user = useUser();
  const loaded = useScript([
    "/admin/js/modernizr/modernizr.min.js",
    "/admin/js/jquery/jquery-2.2.4.min.js",
    "/admin/js/jquery-ui/jquery-ui.min.js",
    "/admin/js/bootstrap/bootstrap.min.js",
    "/admin/js/pace/pace.min.js",
    "/admin/js/lobipanel/lobipanel.min.js",
    "/admin/js/iscroll/iscroll.js",
    "/admin/js/prism/prism.js",
    "/admin/js/waypoint/waypoints.min.js",
    "/admin/js/counterUp/jquery.counterup.min.js",
    "/admin/js/toastr/toastr.min.js",
    "/admin/js/icheck/icheck.min.js",
    "/admin/js/bootstrap-tour/bootstrap-tour.js",
    ...commonJs,
  ]);
  const css = useStyles([
    "/admin/css/bootstrap.min.css",
    "/admin/css/font-awesome.min.css",
    "/admin/css/animate-css/animate.min.css",
    "/admin/css/lobipanel/lobipanel.min.css",
    "/admin/css/prism/prism.css",
    "/admin/js/amcharts/plugins/export/export.css",
    ...commonCss,
    "/admin/css/main.css",
  ]);
  if (!loaded || !css) return null;

  if (!user) return <Navigate to="/" />;

  return <RootAdminLayout />;
}

function RootAdminLayout() {
  useAdminScripts();

  return (
    <div className="main-wrapper">
      <AdminNavbar />
      <div className="content-wrapper">
        <div className="content-container">
          <AdminSideBar />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return null;
}

function LoadFrontJsAndCss() {
  const loaded = useScript([
    "/js/jquery.min.js",
    "/js/jquery.migrate.js",
    "/js/bootstrap.min.js",
    "/js/jquery.imagesloaded.min.js",
    "/js/jquery.isotope.min.js",
    "/js/retina-1.1.0.min.js",
    ...commonJs,
  ]);

  const css = useStyles([
    "http://fonts.googleapis.com/css?family=Lato:300,400,700,900,400italic",
    "https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css",
    "/css/bootstrap.min.css",
    "/css/font-awesome.css",
    ...commonCss,
    "/css/style.css",
  ]);

  if (!loaded || !css) return null;
  return <RootLayout />;
}

function RootLayout() {
  useScripts();
  return (
    <div id="container">
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

function AuthLayout() {
  const user = useAppSelector((s) => s.user.user);

  useEffect(() => {
    if (user?.id) {
      window.location.href = "/dashboard";
    }
  }, []);
  return <Outlet />;
}
function isInDashboard(path: string) {
  return path.indexOf("/dashboard") === 0;
}

const router = createBrowserRouter([
  {
    path: "/",

    element: (
      <UnmountOnBlur
        component={LoadFrontJsAndCss}
        verifyIfActive={(path) => !isInDashboard(path)}
      />
    ),
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/contact", element: <Contact /> },
      {
        path: "/auth",
        element: <AuthLayout />,
        children: [
          { path: "/auth/se-connecter", element: <Login /> },
          { path: "/auth/inscription", element: <Register /> },
          { path: "/auth/mot-passe-oublie", element: <ForgetPassword /> },
          { path: "/auth/reset-password", element: <ResetPassword /> },
        ],
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/athlete",
        element: <Athlete />,
      },
      {
        path: "/athlete/:id",
        element: <DetailAthleteFront />,
      },
      {
        path: "/federation",
        element: <Federation />,
      },
      {
        path: "/federation/:slug",
        element: <DetailFederation />,
      },
      {
        path: "/team",
        element: <EquipesFront />,
      },
      {
        path: "/team/:slug",
        element: <DetailEquipeFront />,
      },

      {
        path: "/match/:matchId",
        element: <DetailMatch />,
      },
      {
        path: "/competition",
        element: <Competition />,
      },
      {
        path: "/competition/:slug",
        element: <DetailCompetition />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <UnmountOnBlur
        component={LoadAdminJsAndCss}
        verifyIfActive={(path) => isInDashboard(path)}
      />
    ),
    children: [
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/dashboard/matches/valider", element: <MatchesAValider /> },
      { path: "/dashboard/matches", element: <Matches /> },
      { path: "/dashboard/roles-et-permissions", element: <Roles /> },
      { path: "/dashboard/roles-et-permissions/:id", element: <UpdateRole /> },
      {
        path: "/dashboard/profil/update-password",
        element: <UpdatePassword />,
      },
      { path: "/dashboard/profil", element: <ProfileUser /> },
      { path: "/dashboard/federations", element: <Fedes /> },
      { path: "/dashboard/federations/create", element: <CreateFede /> },
      {
        path: "/dashboard/federations/update/:slug",
        element: <UpdateFede />,
      },
      {
        path: "/dashboard/federations/ma-federation",
        element: <MaFederation />,
      },
      { path: "/dashboard/federations/:slug", element: <ShowFede /> },

      { path: "/dashboard/sports", element: <Sports /> },
      { path: "/dashboard/sports/create", element: <CreateSport /> },
      {
        path: "/dashboard/sports/update/:slug",
        element: <UpdateSport />,
      },

      { path: "/dashboard/config", element: <AppConfigs /> },
      { path: "/dashboard/config/update", element: <UpdateAppConfig /> },
      { path: "/dashboard/saisons", element: <Saisons /> },
      { path: "/dashboard/saisons/create", element: <CreateSaison /> },
      {
        path: "/dashboard/saisons/update/:slug",
        element: <UpdateSaison />,
      },

      { path: "/dashboard/news", element: <BreakingNews /> },
      { path: "/dashboard/news/create", element: <CreateNews /> },
      {
        path: "/dashboard/news/update/:id",
        element: <UpdateNews />,
      },
      { path: "/dashboard/niveaux", element: <Niveaux /> },
      { path: "/dashboard/niveaux/create", element: <CreateNiveau /> },
      {
        path: "/dashboard/niveaux/update/:slug",
        element: <UpdateNiveau />,
      },
      { path: "/dashboard/agents-cno", element: <AgentsCno /> },
      { path: "/dashboard/agents-cno/create", element: <CreateAgentCNO /> },
      {
        path: "/dashboard/presidents-federations",
        element: <PresiUsers />,
      },
      {
        path: "/dashboard/presidents-federations/create",
        element: <CreatePresidentFede />,
      },
      { path: "/dashboard/users/update/:id", element: <UpdateUser /> },
      { path: "/dashboard/users/detail/:id", element: <DetailUser /> },
      { path: "/dashboard/agents", element: <Agents /> },
      { path: "/dashboard/agents/:id", element: <DetailAgent /> },
      { path: "/dashboard/agents/create", element: <CreateAgent /> },
      {
        path: "/dashboard/agents/update/:id",
        element: <UpdateAgent />,
      },

      { path: "/dashboard/abonnes", element: <Abonnes /> },
      { path: "/dashboard/contacts", element: <Contacts /> },
      { path: "/dashboard/contacts/:id", element: <DetailContact /> },
      { path: "/dashboard/athletes", element: <Athletes /> },
      {
        path: "/dashboard/athletes/valider",
        element: <AthletesAvalider />,
      },
      {
        path: "/dashboard/athletes/create",
        element: <CreateAthlete />,
      },
      {
        path: "/dashboard/athletes/update/:id",
        element: <UpdateAthlete />,
      },
      {
        path: "/dashboard/athletes/:id",
        element: <DetailAthlete />,
      },
      { path: "/dashboard/equipes", element: <Equipes /> },
      { path: "/dashboard/equipes/mon-equipe", element: <MonEquipe /> },
      { path: "/dashboard/equipes/:slug", element: <DetailEquipe /> },
      {
        path: "/dashboard/equipes/create",
        element: <CreateEquipe />,
      },

      {
        path: "/dashboard/equipes/update/:slug",
        element: <UpdateEquipe />,
      },

      { path: "/dashboard/competitions", element: <Competitions /> },
      {
        path: "/dashboard/competitions/valider",
        element: <CompetitionsAvalider />,
      },
      {
        path: "/dashboard/competitions/create",
        element: <CreateCompetition />,
      },
      {
        path: "/dashboard/competitions/update/:slug",
        element: <UpdateCompetition />,
      },
      // Separaor
      {
        path: "/dashboard/competitions/:slug/phases/create",
        element: <CreatePhase />,
      },
      {
        path: "/dashboard/competitions/:slug/phases/update/:phaseId",
        element: <UpdatePhase />,
      },
      {
        path: "/dashboard/competitions/:slug/phases/:phaseId",
        element: <DetailPhase />,
      },
      // Separaor
      {
        path: `/dashboard/competitions/:slug/phases/:phaseId/groupe/:groupeId/matches/create`,
        element: <CreateMatch />,
      },

      {
        path: `/dashboard/competitions/:slug/phases/:phaseId/groupe/:groupeId/matches/:matchId/update`,
        element: <UpdateMatch />,
      },
      //
      {
        path: `/dashboard/competitions/:slug/phases/:phaseId/matches/create`,
        element: <CreateMatch />,
      },

      {
        path: `/dashboard/competitions/:slug/phases/:phaseId/matches/:matchId/update`,
        element: <UpdateMatch />,
      },
      {
        path: `/dashboard/competitions/:slug/phases/:phaseId/groupe/:groupeId/equipes/select`,
        element: <SelectEquipeGroupe />,
      },
      // Separaor
      {
        path: "/dashboard/competitions/:slug/phases/:phaseId/groupe/create",
        element: <CreateGroupe />,
      },
      {
        path: "`/dashboard/competitions/:slug/phases/:phaseId/groupe/:groupeId/update`",
        element: <UpdateGroupe />,
      },
      {
        path: "/dashboard/competitions/:slug/phases/:phaseId/groupe/:groupeId",
        element: <DetailGroupe />,
      },
      // Separaor
      {
        path: "/dashboard/competitions/:slug/equipes/select",
        element: <SelectEquipe />,
      },
      {
        path: "/dashboard/competitions/:slug/athletes/select",
        element: <SelectAthlete />,
      },
      { path: "/dashboard/competitions/:slug", element: <DetailCompetiton /> },
    ],
  },
]);

export default router;
