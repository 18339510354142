import moment from "moment";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useUser } from "../../redux/store";
import { SportApi } from "../../utils/api/sports.api";
import { useAppConfig, useIsDesktop } from "../../utils/hooks";
import FederationItem from "./components/FederationItem";
import CompteDropdown from "./CompteDropdown";

const Header = () => {
  const pathname = useLocation().pathname;
  const secondStyle = !pathname.includes("contact");
  const user = useUser();
  const config = useAppSelector((s) => s.app_config);
  const socials = useAppConfig().filter((f) => f.type === "social" && f.link);
  const isDesktop = useIsDesktop();
  return (
    <header className={secondStyle ? "clearfix second-style" : "clearfix"}>
      {/* <!-- Bootstrap navbar --> */}
      <nav
        className="navbar navbar-default navbar-static-top"
        role="navigation"
      >
        {/* <!-- Top line --> */}
        <div className="top-line">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <ul className="top-line-list">
                  <li>
                    <span className="city-weather">{config.address}</span>
                  </li>
                  <li>
                    <span className="city-weather">{config.telephone}</span>
                  </li>
                  <li>
                    <span className="city-weather">{config.email}</span>
                  </li>
                  <li>
                    <span className="time-now">
                      {moment().format("ddd DD MMM YYYY")}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <ul className="social-icons">
                  {socials.map((s) => (
                    <li key={s.title}>
                      <a className="facebook" target="blank" href={s.link}>
                        {s.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Top line --> */}

        {/* <!-- Logo & advertisement --> */}
        <div className="logo-advertisement">
          <div className="container">
            {/* <!-- Brand and toggle get grouped for better mobile display --> */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link className="navbar-brand" to="/">
                <img src="images/logo-cnos.png" width="150px" alt="" />
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- navbar list container --> */}
        <div className="nav-list-container">
          <div className="container">
            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-left">
                <li>
                  <Link
                    {...(isDesktop
                      ? {}
                      : {
                          "data-toggle": "collapse",
                          "data-target": "#bs-example-navbar-collapse-1",
                        })}
                    className="home"
                    to="/"
                  >
                    Accueil
                  </Link>
                </li>

                <li className="drop">
                  <Link
                    {...(isDesktop
                      ? {}
                      : {
                          "data-toggle": "collapse",
                          "data-target": "#bs-example-navbar-collapse-1",
                        })}
                    to={"/federation"}
                    className="world"
                  >
                    Fédérations
                  </Link>
                  <FedesDropdown />
                </li>

                <li>
                  <Link
                    {...(isDesktop
                      ? {}
                      : {
                          "data-toggle": "collapse",
                          "data-target": "#bs-example-navbar-collapse-1",
                        })}
                    to={"/competition"}
                    className="tech"
                  >
                    Compétitions
                  </Link>
                  <CompteDropdown />
                </li>
                <li>
                  <Link
                    {...(isDesktop
                      ? {}
                      : {
                          "data-toggle": "collapse",
                          "data-target": "#bs-example-navbar-collapse-1",
                        })}
                    to={"/contact"}
                    className="home"
                  >
                    Contact
                  </Link>
                </li>
                {!user && (
                  <>
                    <li>
                      <Link
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                        className="features"
                        to="/auth/se-connecter"
                      >
                        Connexion
                      </Link>
                    </li>

                    <li className="drop">
                      <Link
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                        to={"/auth/inscription"}
                      >
                        S'inscrire
                      </Link>
                    </li>
                  </>
                )}
                {!!user && (
                  <>
                    <li className="drop">
                      <a href={"/dashboard"}>Dashboard</a>
                    </li>
                  </>
                )}
              </ul>
              <SearchForm />
            </div>
            {/* <!-- /.navbar-collapse --> */}
          </div>
        </div>
        {/* <!-- End navbar list container --> */}
      </nav>
      {/* <!-- End Bootstrap navbar --> */}
    </header>
  );
};

export default Header;

function FedesDropdown() {
  const { data: sports } = SportApi.useSportsSelectQuery();
  return (
    <ul className="dropdown fede-dropdown">
      <FederationItem name="All" link="federation" />
      {sports?.map((c) => (
        <FederationItem
          key={c.id}
          name={c.name}
          link={`/federation?sport=${c.slug}`}
        />
      ))}
    </ul>
  );
}

function SearchForm() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (search.trim().length > 1) {
          navigate(`/search?search=${search}`);
        }
      }}
      className="navbar-form navbar-right"
      role="search"
    >
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        id="search"
        name="search"
        placeholder="Rechercher"
      />
      <button type="submit" id="search-submit">
        <i className="fa fa-search"></i>
      </button>
    </form>
  );
}
