import { useCallback, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Input, { Select } from "../../components/Input";
import { SelectOptions, UserFormData } from "../../types";
import { useFedesSelect, useFedesToSelect } from "../../utils/api/fedes.api";
import { Sexes, UserType } from "../../utils/const-fields";
import { COLORS } from "../../utils/constants";
import {
  AthleteForm,
  useCreateAthlete,
} from "../admin/athletes/create-athlete";

const Register = () => {
  const [params] = useSearchParams();
  const initType = params.get("type");
  const [type, setType] = useState<UserType>(
    initType == "1" ? "Athlète" : "Abonné"
  );

  const [options] = useState<SelectOptions>([
    { label: "Abonné", value: "Abonné" },
    { label: "Athlète", value: "Athlète" },
  ]);
  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="block-content">
              <div className="grid-box">
                <div className="contact-form-box">
                  <div className="title-section">
                    <h1>
                      <span>S'inscrire</span>
                    </h1>
                  </div>
                  <div className="row" style={{ marginBottom: 10 }}>
                    <Select
                      required
                      label="Type"
                      containerClass="col-md-12 col-xs-12 col-sm-12"
                      value={type}
                      onChange={(e) => setType(e.target.value as any)}
                      options={options}
                    />
                  </div>
                  {type === "Athlète" && <RegisterAthleteForm />}
                  {type === "Abonné" && <RegisterAbonneForm />}
                </div>
              </div>

              {/* <GoogleAds /> */}
            </div>
          </div>

          {/* <div className="col-sm-4">
            <SideBar />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Register;

function RegisterAthleteForm() {
  const navigate = useNavigate();
  const federations = useFedesSelect();
  const fedesSelects = useFedesToSelect(federations);
  const validate = useCallback(
    (data: UserFormData) => {
      const fede = federations.find((f) => f.id == data.federation);
      let error: string | null = null;
      if (!fede) {
        error = "Choissiez une fédération";
      } else if (fede.type === "Equipe" && !data.equipe) {
        error = "Choissiez une équipe";
      }
      if (error) {
        Swal.fire({
          icon: "error",
          iconColor: COLORS.red,
          confirmButtonColor: COLORS.red,
          title: error,
        });
        return false;
      }
      return true;
    },
    [federations]
  );
  const [params] = useSearchParams();
  const next = params.get("next");

  const {
    form,
    isLoading,
    onChange,
    onSubmit,
    onSubmitForm,
    register,
    equipes,
    fedeId,
    fede,
    fedes,
  } = useCreateAthlete(
    () => {
      if (next) {
        navigate(next);
      } else {
        navigate("/auth/se-connecter");
      }
    },
    undefined,
    "Inscription terminé avec succès"
  );

  return (
    <form id="contact-form" onSubmit={onSubmitForm(onSubmit)}>
      <AthleteForm
        register={register}
        isIndu={!fede || fede?.type === "Individuel"}
        onAdChange={onChange}
        adval={form.address}
        containerClass="marigin-normal"
        inputClass="bg-grey"
        fedes={fedes}
        fedeId={fedeId}
        equipes={equipes}
      />
      <div className="row">
        <Input
          containerClass="col-md-12 col-xs-12 col-sm-12"
          type="password"
          {...register("password")}
          placeholder={"Nouveau mot de passe"}
          label={"Nouveau mot de passe"}
        />
        <Input
          type="password"
          containerClass="col-md-12 col-xs-12 col-sm-12"
          {...register("password_confirm")}
          placeholder={"Confirmer mot de passe"}
          label={"Confirmer mot de passe"}
        />
      </div>
      <Button
        title="Envoyer ma demande"
        icon={<i className="fa fa-paper-plane" />}
        id="submit-contact"
        type="submit"
        loading={isLoading}
      />
      &nbsp;&nbsp;
      <span>
        déjà inscrit ? <Link to="/auth/se-connecter">Se connecter</Link>
      </span>
    </form>
  );
}

function RegisterAbonneForm() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const next = params.get("next");
  const { form, isLoading, onChange, onSubmit, onSubmitForm, register } =
    useCreateAthlete(
      () => {
        if (next) {
          navigate(next);
        } else {
          navigate("/auth/se-connecter");
        }
      },
      undefined,
      "Inscription terminé avec succès",
      true
    );
  return (
    <form id="contact-form" onSubmit={onSubmitForm(onSubmit)}>
      <div className="row">
        <Input
          placeholder={"Prénom"}
          label="Prénom"
          {...register("prenom")}
          containerClass="col-md-6 col-xs-12"
          inputClass="bg-grey"
          type="text"
        />

        <Input
          placeholder={"Nom"}
          label="Nom"
          {...register("nom")}
          containerClass="col-md-6 col-xs-12"
          inputClass="bg-grey"
          type="text"
        />

        <Input
          placeholder={"Email"}
          label="Email"
          {...register("email")}
          containerClass="col-md-6 col-xs-12"
          inputClass="bg-grey"
          type="email"
        />

        <Input
          placeholder={"Téléphone"}
          label="Téléphone"
          {...register("telephone")}
          containerClass="col-md-6 col-xs-12"
          inputClass="bg-grey"
          type="text"
        />
        <Select
          placeholder={"Choisir le sexe"}
          {...register("sexe")}
          label="Sexe"
          containerClass="col-md-6 col-xs-12"
          inputClass="bg-grey"
          options={Sexes.map((s) => ({
            label: s,
            value: s,
          }))}
        />
        <Input
          containerClass="col-md-6 col-xs-12"
          type="password"
          {...register("password")}
          placeholder={"Nouveau mot de passe"}
          label={"Nouveau mot de passe"}
        />
        <Input
          type="password"
          containerClass="col-md-6 col-xs-12"
          {...register("password_confirm")}
          placeholder={"Confirmer mot de passe"}
          label={"Confirmer mot de passe"}
        />
      </div>
      <Button
        title="Envoyer ma demande"
        icon={<i className="fa fa-paper-plane" />}
        id="submit-contact"
        type="submit"
        loading={isLoading}
      />
      &nbsp;&nbsp;
      <span>
        déjà inscrit ? <Link to="/auth/se-connecter">Se connecter</Link>
      </span>
    </form>
  );
}
