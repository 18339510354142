import SideBar from "../../components/SideBar/SideBar";
import { ListEquipes } from "../federation/detailFederation";

const EquipesFront = () => {
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="forum-box">
                  <div className="title-section">
                    <h1>
                      <span>Equipes</span>
                    </h1>
                  </div>

                  <ListEquipes />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EquipesFront;
