import { Link } from "react-router-dom";
import { User } from "../../../types";
import { getFN, getImage } from "../../../utils/api/common.api";
const AthleteItem = ({ item }: { item: User }) => {
  return (
    <>
      <div className="col-md-4 col-sm-6 col-xs-6">
        <div className="news-post video-post">
          <img
            alt=""
            src={getImage(item.image)}
            style={{ width: "100%", height: 200, objectFit: "cover" }}
          />

          <div className="hover-box">
            <h2>
              <Link to={`/athlete/${item.id}`}>{getFN(item)}</Link>
            </h2>
            <ul className="post-tags">
              <li>
                {item.federation?.acronyme}, {item.sport?.name}
              </li>
              {!!item.equipe && <li>{item.equipe?.nom}</li>}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default AthleteItem;
