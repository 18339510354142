// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Saison, SaisonFormData, SelectOptions } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const SaisonApi = createApi({
  tagTypes: ["saisons", "saisonsSelect", "saison"],
  reducerPath: "SaisonApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    saisons: builder.query<PaginationResults<Saison>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/saisons/`, query),
        method: "GET",
      }),
      providesTags: ["saisons"],
    }),
    saisonsSelect: builder.query<Saison[], void>({
      query: () => ({
        url: `/saisons/select/`,
        method: "GET",
      }),
      providesTags: ["saisonsSelect"],
    }),
    findById: builder.query<Saison, string>({
      query: (id) => ({
        url: `/saisons/${id}/`,
        method: "GET",
      }),
      providesTags: (a, b, id) => [{ type: "saison", id: id }],
    }),
    maj: builder.mutation<Saison, { id?: number; data: SaisonFormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/saisons/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/saisons/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["saisons", "saisonsSelect"],
    }),
    delete: builder.mutation<Saison, number>({
      query: (id) => ({
        url: `/saisons/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["saisons", "saisonsSelect"],
    }),
  }),
});

export function useSaisonsSelect() {
  const [items, setItems] = useState<SelectOptions>([]);
  const { data } = SaisonApi.useSaisonsSelectQuery();
  useEffect(() => {
    if (data) {
      setItems(data.map((i) => ({ value: i.id, label: i.name })));
    }
  }, [data]);
  return items;
}

export function useSaisonFromLocation() {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [fetch, { data }] = SaisonApi.useLazyFindByIdQuery();
  const [item, setItem] = useState<Saison>(location.state);

  useEffect(() => {
    if (id) fetch(id);
  }, [id]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
