const defualtStorage = window.localStorage;
export const AppStorage = {
  getItem<T>(key: string, defualtValue?: T | null): T | null | undefined {
    const data = defualtStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return defualtValue;
  },
  setItem(key: string, value: any) {
    defualtStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key: string) {
    defualtStorage.removeItem(key);
  },
  clear() {
    defualtStorage.clear();
  },
};
