// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { News, NewsFormData } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const NewsApi = createApi({
  tagTypes: ["news"],
  reducerPath: "NewsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    news: builder.query<PaginationResults<News>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/news/`, query),
        method: "GET",
      }),
      providesTags: ["news"],
    }),
    published: builder.query<News[], void>({
      query: () => ({
        url: `/news/published/`,
        method: "GET",
      }),
      providesTags: [{ id: -1, type: "news" }],
    }),
    findById: builder.query<News, string>({
      query: (slug) => ({
        url: `/news/${slug}/`,
        method: "GET",
      }),
      providesTags: (a, b, id) => [{ type: "news", id: id }],
    }),
    maj: builder.mutation<News, { id?: number; data: NewsFormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/news/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/news/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["news"],
    }),
    delete: builder.mutation<News, number>({
      query: (id) => ({
        url: `/news/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["news"],
    }),
  }),
});

export function useNewsFromLocation() {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [fetch, { data }] = NewsApi.useLazyFindByIdQuery();
  const [item, setItem] = useState<News>(location.state);

  useEffect(() => {
    if (id) fetch(id);
  }, [id]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
