import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";
import ReactSelect from "react-select";
import { ErrorForm } from "../utils/form";
import GooglePlaceInput, { GooglePlaceData } from "./GooglePlaceInput";

type CommonInputProps = {
  error?: string | boolean | null;
  helperText?: any;
  helperBlock?: any;
  label?: string;
  required?: boolean;
  containerClass?: string;
  inputClass?: string;
  placeholder?: string;
};

type InputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "required" | "error"
> &
  CommonInputProps;

const Input = ({
  helperText,
  error,
  id,
  label,
  required,
  containerClass,
  inputClass,
  helperBlock,
  ...props
}: InputProps) => {
  return (
    <div className={containerClass}>
      {!!label && (
        <label htmlFor={id}>
          {label}
          {required && "*"}
        </label>
      )}
      <input
        style={error ? { marginBottom: 0 } : {}}
        className={inputClass}
        id={id}
        {...props}
        required={!!required}
      />
      {!!helperText && helperText}
      {!helperText && error && typeof error === "string" && (
        <ErrorForm error={error} id={id} />
      )}
      {helperBlock}
    </div>
  );
};

export default Input;

type SelectProps = Omit<
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
  "required" | "error"
> &
  CommonInputProps & {
    options: any[];
  };

export const Select = ({
  helperText,
  error,
  id,
  label,
  required,
  containerClass,
  helperBlock,
  placeholder,
  options,
  inputClass,
  onChange,
  ...props
}: SelectProps) => {
  const selectOptions = [
    ...(placeholder ? [{ value: "", label: `-- ${placeholder} --` }] : []),
    ...options,
  ];
  const selected = selectOptions.find((s) => s.value == props.value);
  return (
    <div className={containerClass}>
      {!!label && <label htmlFor={id}>{label}</label>}

      {/*@ts-ignore */}
      <ReactSelect
        options={selectOptions}
        {...props}
        value={selected}
        placeholder={placeholder}
        onChange={(e: any) => {
          if (onChange) {
            onChange({ target: { value: e.value as any } } as any);
          }
        }}
        // className={inputClass}
        id={id}
      />
      {!!helperText && helperText}
      {!helperText && error && typeof error === "string" && (
        <ErrorForm error={error} id={id} />
      )}
      {helperBlock}
    </div>
  );
};

type TextAreaProps = Omit<
  DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "required" | "error"
> &
  CommonInputProps;

export const TextArea = ({
  helperText,
  error,
  id,
  label,
  required,
  containerClass,
  helperBlock,
  placeholder,
  inputClass,
  ...props
}: TextAreaProps) => {
  return (
    <div className={containerClass}>
      {!!label && (
        <label htmlFor={id}>
          {label}
          {required && "*"}
        </label>
      )}
      <textarea className={inputClass} {...props} id={id} />
      {!!helperText && helperText}
      {!helperText && error && typeof error === "string" && (
        <ErrorForm error={error} id={id} />
      )}
      {helperBlock}
    </div>
  );
};

export function GoogleInput({
  onChange,
  value,
  containerClass,
  error,
  helperBlock,
  helperText,
  inputClass,
  label,
  placeholder,
  required,
}: {
  value?: string;
  onChange: (data: GooglePlaceData) => void;
} & CommonInputProps) {
  return (
    <div className={containerClass}>
      {!!label && <label>{label}</label>}
      <GooglePlaceInput
        onChange={(data) => {
          onChange(data);
        }}
        value={value ?? ""}
        className={inputClass}
        placeholder={placeholder}
      />
      {!!helperText && helperText}
      {!helperText && error && typeof error === "string" && (
        <ErrorForm error={error} id={""} />
      )}
      {helperBlock}
    </div>
  );
}
