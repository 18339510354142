import moment from "moment";
import { Link } from "react-router-dom";
import { Competition } from "../../../types";
import { getImage } from "../../../utils/api/common.api";

const CompetitionItem = ({ item }: { item: Competition }) => {
  return (
    <>
      <div className="table-row">
        <div className="forum-post">
          <img src={getImage(item.image)} alt="" />
          <div className="post-autor-date">
            <h2>
              <Link to={`/competition/${item.slug}`}>{item.name}</Link>
            </h2>
            <p>
              <Link to={`/federation/${item.slug}`}>{item.federation.nom}</Link>
            </p>
            <p>
              <a>{item.sport.name}</a>
            </p>
            <p>
              Du <a href="#">{moment(item.startDate).format("DD MMM")}</a> au{" "}
              <a href="#">{moment(item.endDate).format("DD MMM YYYY")}</a>
            </p>
          </div>
        </div>
        <div className="forum-topics">
          <p>
            <span>{item.nbreEquipe}</span>{" "}
            {item.type === "Equipe" ? "Equipes" : "Athlètes"}
          </p>
          {/* <p>
            <span>Tire au but : </span> {item.tirsAuxBut ? "Oui" : "Non"}
          </p>
          <p>
            <span>Prolongation : </span> {item.prolongation ? "Oui" : "Non"}
          </p> */}
        </div>
      </div>
    </>
  );
};
export default CompetitionItem;
