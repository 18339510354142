import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OwnCarousel = ({
  children,
  num,
  showDots = false,
}: {
  num: number;
  children?: any;
  showDots?: boolean;
}) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: num,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: num,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel showDots={showDots} autoPlay responsive={responsive}>
      {children}
    </Carousel>
  );
};

export default OwnCarousel;
