/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { imageFormatter } from "../../../components/Table";
import { onLogout } from "../../../redux/slices/user.slice";
import { useAppDispatch, useUser } from "../../../redux/store";
import { getFN } from "../../../utils/api/common.api";
import { usePermissions } from "../../../utils/permissions";
import { SideBarLink, useAdminSidebar } from "./AdminSideBar";

const AdminNavbar = () => {
  return (
    <nav className="navbar top-navbar bg-white box-shadow">
      <div className="container-fluid">
        <div className="row ignore-flex">
          <div className="navbar-header no-padding">
            <Link className="navbar-brand" to="/dashboard">
              <img
                src="/admin/images/logo-cnos_2.png"
                alt="Options - Admin Template"
                className="logo"
              />
            </Link>
            <span className="small-nav-handle hidden-sm hidden-xs">
              <i className="fa fa-outdent"></i>
            </span>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <i className="fa fa-ellipsis-v"></i>
            </button>
            <button type="button" className="navbar-toggle mobile-nav-toggle">
              <i className="fa fa-bars"></i>
            </button>
          </div>
          {/* <!-- /.navbar-header --> */}

          <div className="collapse navbar-collapse" id="navbar-collapse-1">
            <ul
              className="nav navbar-nav"
              data-dropdown-in="fadeIn"
              data-dropdown-out="fadeOut"
            >
              <li className="hidden-sm hidden-xs">
                <a href="#" className="full-screen-handle">
                  <i className="fa fa-arrows-alt"></i>
                </a>
              </li>
              <li className="hidden-xs hidden-xs"></li>
              <li className="">
                <a href="/" className="color-primary">
                  <i className="fa fa-diamond"></i> Frontend
                </a>
              </li>
            </ul>

            <ul
              className="nav navbar-nav navbar-right"
              data-dropdown-in="fadeIn"
              data-dropdown-out="fadeOut"
            >
              <AddButtonsDropdown />
              {/* <!-- /.dropdown --> */}
              <UserDropdown />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;

function UserDropdown() {
  const user = useUser();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <li className={`dropdown ${show ? "open" : ""}`}>
      <a
        href="#"
        className="dropdown-toggle"
        role="button"
        onClick={() => setShow((old) => !old)}
      >
        {user?.prenom} {user?.nom} <span className="caret"></span>
      </a>
      <ul className="dropdown-menu profile-dropdown">
        <li className="profile-menu bg-gray">
          <div className="">
            {imageFormatter(user, 50)}
            <div className="profile-name">
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  textAlign: "center",
                  display: "block",
                }}
              >
                {getFN(user)}
              </span>
              <span
                style={{
                  fontSize: 10,
                  display: "block",
                  textAlign: "center",
                }}
              >
                {user.userType}
              </span>
            </div>
            <div className="clearfix"></div>
          </div>
        </li>
        <li>
          <Link onClick={() => setShow(false)} to="/dashboard/profil">
            <i className="fa fa-user"></i> Porfil
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setShow(false)}
            to="/dashboard/profil/update-password"
          >
            <i className="fa fa-cog"></i> Mot de passe
          </Link>
        </li>
        <li role="separator" className="divider"></li>
        <li>
          <a
            onClick={() => {
              setShow(false);
              dispatch(onLogout());
              navigate("/auth/se-connecter", { replace: true });
            }}
            href="#"
            className="color-danger text-center"
          >
            <i className="fa fa-sign-out"></i> Déconnexion
          </a>
        </li>
      </ul>
    </li>
  );
}

function AddButtonsDropdown() {
  const [show, setShow] = useState(false);
  const { hasAccess } = usePermissions();
  const hasCreatePerm = (link: SideBarLink) =>
    link.permissions &&
    link.permissions?.findIndex((p) => p.includes(".create")) !== -1;
  const links = useAdminSidebar().reduce(
    (acc, cur) => [...acc, ...cur.links.filter((link) => hasCreatePerm(link))],
    [] as SideBarLink[]
  );

  const showAddButton = links.some(
    (link) => !link.permissions || hasAccess(link.permissions)
  );
  if (!showAddButton) return null;

  return (
    <li className={`dropdown ${show ? "open" : ""}`}>
      <a
        href="#"
        className={`dropdown-toggle bg-primary`}
        onClick={() => setShow((old) => !old)}
      >
        <i className="fa fa-plus-circle"></i> Ajouter{" "}
        <span className="caret"></span>
      </a>
      <ul className="dropdown-menu">
        {links.map((link) => {
          const isAcc = !link.permissions || hasAccess(link.permissions);
          if (!isAcc) return null;

          return (
            <li key={link.route}>
              <Link onClick={() => setShow(false)} to={link.route}>
                {link.icon} {link.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}
