import Button from "../../../../components/Button";
import { LoaderPage } from "../../../../components/Loader";
import { Competition } from "../../../../types";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import BreadCrumb from "../../components/BreadCrumb";
import { ChoiceEquipeTable } from "../../equipes/equipe-table";
import { useSelectEquipeForm } from "./select-equipe";

const SelectAthleteComponent = ({
  competition,
}: {
  competition: Competition;
}) => {
  const { disabled, onSubmit, loading, selections, setSelections, props } =
    useSelectEquipeForm(competition, "athlete");

  return (
    <div className="main-page">
      <BreadCrumb
        title="Athlètes"
        subTitle={"Choisir les athlètes"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: "Choisir les athlètes",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <div className="row">
                      <div className="col-md-7">
                        <h5>Liste des athlètes</h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <Button
                          type="button"
                          className="btn bg-black btn-wide"
                          title={`Ajouter les ${selections.length} athlètes`}
                          loading={loading}
                          disabled={disabled}
                          onClick={() => {
                            onSubmit();
                          }}
                          icon={<i className="fa fa-arrow-right" />}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>
                          {selections.length}/{competition.nbreEquipe}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <ChoiceEquipeTable
                    selections={selections}
                    setSelections={setSelections}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function SelectAthlete() {
  const { item: comp } = useCompetitionFromLocation();
  if (!comp) return <LoaderPage />;
  return <SelectAthleteComponent competition={comp} />;
}

export default SelectAthlete;
