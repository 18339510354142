// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Federation, SelectOptions } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const FedesApi = createApi({
  tagTypes: ["federations", "federationsSelect", "federation"],
  reducerPath: "FedesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    federations: builder.query<PaginationResults<Federation>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/federations/`, query),
        method: "GET",
      }),
      providesTags: ["federations"],
    }),
    federationsSelect: builder.query<Federation[], void>({
      query: () => ({
        url: `/federations/select/`,
        method: "GET",
      }),
      providesTags: ["federationsSelect"],
    }),
    findBySlug: builder.query<Federation, string>({
      query: (slug) => ({
        url: `/federations/slug/${slug}/`,
        method: "GET",
      }),
      providesTags: (a, b, slug) => [{ type: "federation", id: slug }],
    }),
    maj: builder.mutation<Federation, { id?: number; data: FormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/federations/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/federations/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["federations", "federationsSelect"],
    }),
    delete: builder.mutation<Federation, number>({
      query: (id) => ({
        url: `/federations/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["federations", "federationsSelect"],
    }),
  }),
});

export function useFedeFromLocation() {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [fetch, { data }] = FedesApi.useLazyFindBySlugQuery();
  const [item, setItem] = useState<Federation>(location.state);

  useEffect(() => {
    if (slug) fetch(slug);
  }, [slug]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}

export function useFedesSelect() {
  const [items, setItems] = useState<Federation[]>([]);
  const [empty] = useState<Federation[]>([]);

  const { data, isFetching, isLoading } = FedesApi.useFederationsSelectQuery();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const loading = isLoading || isFetching;
  return loading ? empty : items;
}

export type FedeSelect = SelectOptions[number] & { type: Federation["type"] };

export function useFedesToSelect(data: Federation[]) {
  const [items, setItems] = useState<FedeSelect[]>([]);

  useEffect(() => {
    setItems(data.map((c) => ({ label: c.nom, value: c.id, type: c.type })));
  }, [data]);

  return items;
}

export function useFedeSelectItems() {
  const items = useFedesSelect();
  const data = useFedesToSelect(items);
  return data;
}
