/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Input, { TextArea } from "../../components/Input";
import SideBar from "../../components/SideBar/SideBar";
import { MessageContactFormData } from "../../types";
import { ContactApi } from "../../utils/api/contact.api";
import { COLORS } from "../../utils/constants";
import { useFormData } from "../../utils/form";
import { useScript } from "../../utils/scripts";

const Contact = () => {
  useEffect(() => {
    $(document).ready(function ($: any) {
      var contact = { lat: "-38.3945495", lon: "144.9187974" };

      try {
        var mapContainer = $("#map");
        mapContainer.gmap3(
          {
            action: "addMarker",
            marker: {
              options: {
                // @ts-ignore
                icon: new google.maps.MarkerImage("images/marker.png"),
              },
            },
            latLng: [contact.lat, contact.lon],
            map: {
              center: [contact.lat, contact.lon],
              zoom: 10,
            },
          },
          { action: "setOptions", args: [{ scrollwheel: false }] }
        );
      } catch (err) {}
    });
  }, []);
  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-xs-12">
            {/* <!-- block content --> */}
            <div className="block-content">
              {/* <!-- contact info box --> */}
              <div className="contact-info-box">
                <div className="title-section">
                  <h1>
                    <span>Contact Us</span>
                  </h1>
                </div>

                <div id="map"></div>
              </div>
              {/* <!-- End contact info box --> */}

              {/* <!-- contact form box --> */}
              <div className="contact-form-box">
                <div className="title-section">
                  <h1>
                    <span>Nous contacter</span>
                  </h1>
                </div>
                <ContactUs />
              </div>
              {/* <!-- End contact form box --> */}
            </div>
            {/* <!-- End block content --> */}
          </div>

          <div className="col-md-4 col-xs-12">
            <SideBar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => {
  const loaded = useScript([
    "http://maps.google.com/maps/api/js?sensor=false",
    "/js/gmap3.min.js",
  ]);
  if (!loaded) return null;
  return <Contact />;
};

function ContactUs() {
  const [exec, { isLoading }] = ContactApi.useMajMutation();

  const { setErrors, register, onSubmitForm, onChange } =
    useFormData<MessageContactFormData>(
      {
        email: "",
        message: "",
        name: "",
      },
      (yup) => ({
        message: yup.string().required().label("Message"),
        name: yup.string().required().label("Prénom & Nom"),
        email: yup.string().required().email().label("Email"),
      })
    );

  const onSubmit = React.useCallback(async (data: MessageContactFormData) => {
    const res = await exec({ data: data });
    if ("error" in res) {
      const error = res.error as any;
      setErrors({
        name:
          error.data?.message ||
          "Veuillez vous assurer d'entrer les bonnes données",
      });
    } else {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.primary,
        confirmButtonColor: COLORS.primary,
        title: `Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais`,
      });
      onChange({
        email: "",
        message: "",
        name: "",
      });
    }
  }, []);

  return (
    <form id="contact-form" onSubmit={onSubmitForm(onSubmit)}>
      <div className="row">
        <Input
          type="email"
          required
          label="Email"
          containerClass="col-md-6 col-xs-12"
          {...register("email")}
        />

        <Input
          type="text"
          required
          label="Prénom & Nom"
          containerClass="col-md-6  col-xs-12"
          {...register("name")}
        />

        <TextArea
          required
          label="Message"
          containerClass="col-md-12  col-xs-12"
          {...register("message")}
        />
      </div>
      <Button
        title="Se connecter"
        icon={<i className="fa fa-paper-plane" />}
        id="submit-contact"
        type="submit"
        loading={isLoading}
      />
    </form>
  );
}
