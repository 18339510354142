import React from "react";
import * as Yup from "yup";
import { COLORS } from "./constants";
type InputProps =
  | React.InputHTMLAttributes<HTMLInputElement>
  | React.SelectHTMLAttributes<HTMLSelectElement>
  | React.TextareaHTMLAttributes<HTMLTextAreaElement>;

type RegisterReurnValue = {
  name: string;
  value?: any;
  onChange: InputProps["onChange"] | any;
  checked?: boolean;
  error: boolean | string;
  id: string;
  helperText: any;
};
type Errors<T> = Partial<{ [key in keyof T]: string | undefined | null }>;
export type UserFormDataReturnValue<T> = {
  data: T;
  onChange: (fieldName: Partial<T> | keyof T, value?: any) => any;
  register: (fieldName: keyof T) => RegisterReurnValue;
  errors: Errors<T>;
  validate: (data: Partial<T>) => Promise<any>;
  setErrors: (errors: Errors<T>) => any;
  onSubmitForm: (cb: (data: T) => any) => (e: any) => any;
};
export function useFormData<T>(
  formData: T,
  cbErrors: (yup: typeof Yup) => any
): UserFormDataReturnValue<T> {
  const { errors, validate, setErrors } = useFormValidation<T>({}, cbErrors);
  const [data, setData] = React.useState<T>(formData);
  const onDataChange = React.useCallback(
    (fieldName: Partial<T> | keyof T, value?: any) => {
      if (typeof fieldName === "object") {
        setData((old) => ({ ...old, ...fieldName }));
      } else if (typeof fieldName === "string") {
        let val = value;
        if (fieldName === "email") {
          val = val?.trim();
        }
        setData((old) => {
          const newData = { ...old };
          // @ts-ignore
          newData[fieldName] = val;
          return newData;
        });
      }
    },
    [setData]
  );
  const onSubmitForm = React.useCallback(
    (cb: (data: T) => any) => {
      return (e: any) => {
        if (e?.preventDefault) {
          e.preventDefault();
        }
        validate(data as any)
          .then(() => {
            if (
              Object.values(data as any).filter((val) => val instanceof File)
                .length > 0
            ) {
              const fd = new FormData();
              for (let key of Object.keys(data as any)) {
                fd.append(key, (data as any)[key]);
              }
              //@ts-ignore
              cb(fd);
            } else {
              cb(data);
            }
          })
          .catch((ex) => null);
      };
    },
    [data, validate]
  );
  const register = React.useCallback(
    (fieldName: keyof T): RegisterReurnValue => {
      const val = data[fieldName];
      const ret: RegisterReurnValue = {
        name: fieldName as string,
        id: fieldName as string,
        checked: !!val,
        error: !!errors[fieldName],
        helperText: (
          <FormError
            id={fieldName as string}
            error={errors[fieldName] as any}
          />
        ),
        onChange: (e: any) => {
          if (e.target.type === "file") {
            onDataChange(fieldName, e.target.files[0]);
          } else if (e.target.type === "checkbox") {
            onDataChange(fieldName, e.target.checked);
          } else {
            onDataChange(fieldName, e.target.value);
          }
        },
      };
      if (!(val instanceof File)) {
        ret.value = val;
      }
      return ret;
    },
    [data, errors, onDataChange]
  );
  return {
    data,
    onChange: onDataChange,
    errors,
    validate,
    setErrors,
    register,
    onSubmitForm,
  };
}

export type FormValidationProps<T> = {
  errors: UserFormDataReturnValue<T>["errors"];
  validate: UserFormDataReturnValue<T>["validate"];
  setErrors: UserFormDataReturnValue<T>["setErrors"];
};

export function useFormValidation<T>(
  initalErrors: Errors<T>,
  cbRules: (yup: typeof Yup) => any
): FormValidationProps<T> {
  const [errors, setErrors] = React.useState<Errors<T>>(initalErrors);
  const Schema = Yup.object().shape(cbRules(Yup));
  const validate = React.useCallback(
    (data: Partial<T>) =>
      new Promise((resolve, reject) => {
        setErrors({});
        Schema.validate(data, { abortEarly: false })
          .then(() => {
            resolve(data);
          })
          .catch((err: Yup.ValidationError) => {
            const newErrors: Errors<T> = {};
            for (const e of err.inner) {
              if (e.path) {
                const [error] = e.errors;
                // @ts-ignore
                newErrors[e.path] = error;
              }
            }
            setErrors(newErrors);
            reject(newErrors);
          });
      }),
    []
  );
  return { errors, validate, setErrors };
}

export function FormError({ error, id }: { error?: string; id?: string }) {
  if (!error) return null;
  return (
    <small style={{ color: COLORS.red }} id={id}>
      {error}
    </small>
  );
}

export function ErrorForm({ error, id }: { error?: string; id?: string }) {
  if (!error) return null;
  return <FormError {...{ error, id }} />;
}
