import moment from "moment";
import { Link } from "react-router-dom";
import { Competition } from "../../types";
import { getImage } from "../../utils/api/common.api";
import { CompetitionApi } from "../../utils/api/competition.api";
import CompetitionItem from "../federation/components/CompetitionItem";
import CompetSlider from "./CompetSlider";

const TopCompet = () => {
  const { data, isLoading, isFetching } = CompetitionApi.useActivesQuery();

  const {
    data: becomes,
    isLoading: becomesIsLoading,
    isFetching: becomesIsFetching,
  } = CompetitionApi.useBecomesQuery();

  const loading = isLoading || isFetching;
  const becomesLoading = becomesIsLoading || becomesIsFetching;

  if (loading || becomesLoading) {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div>
              <span className="top-stories">COMPETITIONS A VENIR</span>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="row"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        {!!becomes?.length && (
          <div className="col-md-6 col-xs-12">
            <div>
              <span className="top-stories">COMPETITIONS A VENIR</span>
              <CompetSlider competitions={becomes} />
            </div>
          </div>
        )}
        <div className={`col-md-${!!becomes?.length ? 6 : 12}`}>
          <div className="row">
            {data?.map((item) => {
              return (
                <CompetitionItem
                  md={becomes?.length ? 6 : 3}
                  small
                  key={item.id}
                  item={item}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCompet;

export function CompetItem({ item }: { item: Competition }) {
  return (
    <div className={`image-post`}>
      <img
        src={getImage(item.image)}
        alt=""
        style={{ width: "100%", height: 180, objectFit: "contain" }}
      />
      <div className="hover-box">
        <div className="inner-hover">
          <a className="category-post travel" href="#">
            {item.sport?.name}
          </a>
          <h2>
            <Link to={`/competition/${item.slug}`}>{item.name}</Link>
          </h2>
          <ul className="post-tags">
            <li>
              <i className="fa fa-clock-o"></i>
              <span>{moment(item.startDate).format("DD MMM YYYY")}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
