// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Score, ScoreFormData } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
export type ScoreQuery = CommonQuery & ScoreSelectQuery;

export type ScoreSelectQuery = {
  match?: number;
  participe?: number;
  athlete?: number;
};
// Define a service using a base URL and expected endpoints
export const ScoreApi = createApi({
  tagTypes: ["scores", "scoreItem", "select"],
  reducerPath: "ScoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    scores: builder.query<PaginationResults<Score>, ScoreQuery>({
      query: (query) => ({
        url: QueryUrl(`/scores/`, query),
        method: "GET",
      }),
      providesTags: ["scores"],
    }),
    select: builder.query<Score[], ScoreSelectQuery>({
      query: (query) => ({
        url: QueryUrl(`/scores/select/`, query),
        method: "GET",
      }),
      providesTags: ["select"],
    }),
    scoreById: builder.query<Score, string | number>({
      query: (id) => ({
        url: `/scores/${id}/`,
        method: "GET",
      }),
      providesTags: ["scoreItem"],
    }),

    maj: builder.mutation<Score, { id?: number; data: ScoreFormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/matches/score/update/${id}`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/matches/score/create/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["scores", "scoreItem", "select"],
    }),
    delete: builder.mutation<Score, number>({
      query: (id) => ({
        url: `/matches/score/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["scores", "scoreItem", "select"],
    }),
  }),
});
