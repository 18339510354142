// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Competition, CompetitionFormData } from "../../types";
import { ApiUrl } from "./URL";
import {
  CommonQuery,
  PaginationResults,
  QueryUrl,
  hasFile,
  prepareFormData,
} from "./common.api";
import { prepareHeaders } from "./utils";
export type CompetitionQuery = CommonQuery & {
  federation?: number | string;
  isValidated?: boolean;
  isRejected?: boolean;
  sport?: number | string;
  niveau?: number | string;
  saison?: number | string;
};
// Define a service using a base URL and expected endpoints
export const CompetitionApi = createApi({
  tagTypes: ["competitions", "competitionItem", "validates"],
  reducerPath: "CompetitionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    competitions: builder.query<
      PaginationResults<Competition>,
      CompetitionQuery
    >({
      query: (query) => ({
        url: QueryUrl(`/competitions/`, query),
        method: "GET",
      }),
      providesTags: ["competitions"],
    }),
    becomes: builder.query<Competition[], void>({
      query: () => ({
        url: `/competitions/a-vanir`,
        method: "GET",
      }),
      providesTags: [{ type: "competitions", id: -1 }],
    }),
    actives: builder.query<Competition[], void>({
      query: () => ({
        url: `/competitions/actives-and-pasteds`,
        method: "GET",
      }),
      providesTags: [{ type: "competitions", id: -2 }],
    }),
    toValidate: builder.query<PaginationResults<Competition>, CompetitionQuery>(
      {
        query: (query) => ({
          url: QueryUrl(`/competitions/to-validate`, query),
          method: "GET",
        }),
        providesTags: ["validates"],
      }
    ),
    competitionBySlug: builder.query<Competition, string | number>({
      query: (slug) => ({
        url: `/competitions/slug/${slug}/`,
        method: "GET",
      }),
      providesTags: ["competitionItem"],
    }),

    maj: builder.mutation<
      Competition,
      { id?: number; data: FormData | CompetitionFormData }
    >({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/competitions/${id}/`,
            method: "PUT",
            body: hasFile(data) ? prepareFormData(data) : data,
          };
        }
        return {
          url: `/competitions/`,
          method: "POST",
          body: prepareFormData(data),
        };
      },
      invalidatesTags: ["competitions", "competitionItem", "validates"],
    }),
    delete: builder.mutation<Competition, number>({
      query: (id) => ({
        url: `/competitions/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["competitions", "competitionItem", "validates"],
    }),
  }),
});

export function useCompetitionFromLocation() {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [fetch, { data }] = CompetitionApi.useLazyCompetitionBySlugQuery();
  const state = location.state;

  const [item, setItem] = useState<Competition>(
    state && "saison" in state ? state : undefined
  );

  useEffect(() => {
    if (slug) fetch(slug);
  }, [slug]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
