import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppConfig } from "../../types";
import { AppStorage } from "../../utils/storage";
const { setItem, getItem } = AppStorage;

type State = Partial<AppConfig>;
const def = getItem<State>("AppConfig", {});

const initialState: State = def || {};

export const AppConfigSlice = createSlice({
  name: "app_config",
  initialState,
  reducers: {
    setAppConfig: (state, action: PayloadAction<State>) => {
      state = action.payload;
      setItem("AppConfig", state);
      return state;
    },
  },
});

export const { setAppConfig } = AppConfigSlice.actions;

export default AppConfigSlice.reducer;
