// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Newsletter, NewsletterFormData } from "../../types";
import { ApiUrl } from "./URL";
import { prepareHeaders } from "./utils";

// Define a service using a base URL and expected endpoints
export const NewsletterApi = createApi({
  tagTypes: ["newsletter"],
  reducerPath: "NewsletterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    newsletters: builder.query<Newsletter, void>({
      query: () => ({
        url: "/newsletter",
        method: "GET",
      }),
      providesTags: ["newsletter"],
    }),
    maj: builder.mutation<Newsletter, NewsletterFormData>({
      query: (data) => {
        return {
          url: `/newsletter/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["newsletter"],
    }),
    delete: builder.mutation<Newsletter, number>({
      query: (id) => {
        return {
          url: `/newsletter/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["newsletter"],
    }),
  }),
});
