import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import Table, {
  DeleteButton,
  TableHeader,
  TableProps,
  UpdateButton,
  ViewButton,
} from "../../../../components/Table";

import { Competition, Groupe, Phase } from "../../../../types";
import { GroupeApi } from "../../../../utils/api/groupe.api";

type GroupeTableProps = Omit<TableProps<Groupe>, "keyField" | "headers"> & {
  competition: Competition;
  phase: Phase;
};
const GroupeTable = ({ competition, phase, ...props }: GroupeTableProps) => {
  const [onDelete] = GroupeApi.useDeleteMutation();
  const headers: TableHeader<Groupe>[] = [
    { name: "name", title: "Nom" },
    {
      name: "nbreEquipe",
      title: "Nombre d'équipes",
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/competitions/${competition.slug}/phases/${phase.id}/groupe/${item.id}`}
            state={item}
          />
          <UpdateButton
            route={`/dashboard/competitions/${competition.slug}/phases/${phase.id}/groupe/${item.id}/update`}
            state={item}
            permission="competitions.create"
          />
          <DeleteButton
            permission="competitions.create"
            id={item.id}
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Groupe supprimé avec succès"}
          />
        </>
      ),
    },
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default GroupeTable;
