import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { onSetUserToken } from "../../redux/slices/user.slice";
import { useAppDispatch } from "../../redux/store";
import { AuthFormData } from "../../types";
import { AuthApi } from "../../utils/api/auth.api";
import { useFormData } from "../../utils/form";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const next = params.get("next");
  const [exec, { isLoading }] = AuthApi.useLoginMutation();
  const { setErrors, onSubmitForm, register } = useFormData<AuthFormData>(
    {
      email: "",
      password: "",
    },
    (Yup) => ({
      password: Yup.string().required().label("Mot de passe"),
      email: Yup.string().email().required().label("Email"),
    })
  );

  const onSubmit = React.useCallback(
    async (data: AuthFormData) => {
      const res = await exec(data);
      if ("data" in res) {
        if (res.data?.user) {
          if (
            [
              "Agents Fédération",
              "Agents Équipe",
              "Athlète",
              "President Fédération",
            ].includes(res.data.user?.userType) &&
            !res.data.user.federation
          ) {
            setErrors({ email: "Pas de fédération trouvée" });
            return;
          }
        }

        dispatch(onSetUserToken(res.data));
        if (next) {
          navigate(next);
        } else {
          navigate("/dashboard");
        }
      } else {
        const err = res.error as any;
        const message =
          err?.data?.message || "Identifiant ou mot de passe incorrect";
        setErrors({ email: message });
      }
    },
    [next]
  );
  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="block-content">
              <div className="grid-box">
                <div className="contact-form-box">
                  <div className="title-section">
                    <h1>
                      <span>Connexion</span>
                    </h1>
                  </div>
                  <form id="contact-form" onSubmit={onSubmitForm(onSubmit)}>
                    <div className="row">
                      <Input
                        type="email"
                        required
                        label="Email"
                        containerClass="col-md-12 col-xs-12 col-sm-12"
                        {...register("email")}
                      />
                      <Input
                        type="password"
                        required
                        label="Mot de passe"
                        containerClass="col-md-12 col-xs-12 col-sm-12"
                        {...register("password")}
                      />
                    </div>
                    <Button
                      title="Se connecter"
                      icon={<i className="fa fa-paper-plane" />}
                      id="submit-contact"
                      type="submit"
                      loading={isLoading}
                    />
                    &nbsp;&nbsp;
                    <span>
                      Mot de passe oublié ?{" "}
                      <Link to="/auth/mot-passe-oublie">
                        Réinitialiser son mot de passe
                      </Link>
                    </span>{" "}
                    - &nbsp;&nbsp;
                    <span>
                      Pas encore inscrit ?{" "}
                      <Link
                        to={
                          next
                            ? `/auth/inscription?next=${next}`
                            : "/auth/inscription"
                        }
                      >
                        S'inscrire
                      </Link>
                    </span>
                  </form>
                </div>
              </div>

              {/* <GoogleAds /> */}
            </div>
          </div>

          {/* <div className="col-sm-4">
            <SideBar />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Login;
