import { Link } from "react-router-dom";
type BreadCrumbItem = {
  route?: string | number;
  title: string;
};
type BreadCrumbProps = {
  title: string;
  subTitle: string;
  items: BreadCrumbItem[];
};
const BreadCrumb = ({ items, subTitle, title }: BreadCrumbProps) => {
  return (
    <div className="container-fluid">
      <div className="row page-title-div">
        <div className="col-sm-12">
          <h2 className="title">{title}</h2>
          <p className="sub-title">{subTitle}</p>
        </div>
      </div>
      <div className="row breadcrumb-div">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            {items.map((item, index) => {
              const active = index === items.length - 1;
              if (active)
                return (
                  <li key={index} className="active">
                    {item.title}
                  </li>
                );
              return (
                <li key={index}>
                  <Link to={item.route as string}>
                    <i className="fa fa-home"></i> {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
