import Table, {
  DeleteButton,
  TableHeader,
  UpdateButton,
} from "../../../components/Table";
import { News } from "../../../types";
import { CommonQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { NewsApi } from "../../../utils/api/news.api";
import { NiveauApi } from "../../../utils/api/niveau.api";
import BreadCrumb from "../components/BreadCrumb";

const BreakingNews = () => {
  const props = usePaginationQuery<News, CommonQuery>(NewsApi.useNewsQuery, {});
  const [onDelete] = NiveauApi.useDeleteMutation();

  const headers: TableHeader<News>[] = [
    { name: "content", title: "Contenu" },
    {
      name: "published",
      title: "Publié",
      formatter: (item) => {
        return item.published ? "OUI" : "NON";
      },
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <UpdateButton
            route={`/dashboard/news/update/${item.id}`}
            state={item}
            permission="news"
          />
          <DeleteButton
            id={item.id}
            permission="news"
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"News supprimé avec succès"}
          />
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Breaking News"
        subTitle="Breaking News"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Breaking News" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Breaking News ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BreakingNews;
