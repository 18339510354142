import { useUser } from "../../../redux/store";
import { Competition } from "../../../types";
import { usePaginationQuery } from "../../../utils/api/common.api";
import {
  CompetitionApi,
  CompetitionQuery,
} from "../../../utils/api/competition.api";
import { usePermissions } from "../../../utils/permissions";
import BreadCrumb from "../components/BreadCrumb";
import CompetitionTable from "./competition-table";

const Competitions = () => {
  const user = useUser();
  const { hasAccess } = usePermissions();
  const props = usePaginationQuery<Competition, CompetitionQuery>(
    CompetitionApi.useCompetitionsQuery,
    {
      federation: user?.federation?.id,
      isValidated: hasAccess(["competitions.create"]) ? undefined : true,
    },
    { niveau: true, federation: !user?.federation, sport: true, saison: true }
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Compétitions"
        subTitle="Liste des compétitions"
        items={[{ title: "Home" }, { title: "Tous les compétitions" }]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-warning no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des compétitions ({props.total})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <CompetitionTable {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Competitions;
