import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Competition, Equipe, Federation, User } from "../types";
import { ApiUrl } from "../utils/api/URL";
import { COLORS } from "../utils/constants";
import { PermissionValue, usePermissions } from "../utils/permissions";
import { AppStorage } from "../utils/storage";
export type TableHeader<T> = {
  formatter?: (item: T) => any;
  name: keyof T;
  title: string;
};

export type TableProps<T> = {
  headers: TableHeader<T>[];
  data: T[];
  keyField: keyof T;
  limit?: number;
  onLimitChange?: (limit: number) => void;
  onPageChange?: (page: number) => void;
  page?: number;
  total?: number;
  isLoading?: boolean;
  renderSearch?: () => any;
};

function Table<T>({
  data,
  headers,
  keyField,
  isLoading,
  limit,
  onLimitChange,
  renderSearch,
  onPageChange,
  page,
  total,
}: TableProps<T>) {
  const handleChangePage = (newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (limit: number) => {
    if (onLimitChange) onLimitChange(limit);
    if (onPageChange) onPageChange(1);
    AppStorage.setItem("defaultLimit", limit);
  };
  const emptyRows = data.length <= 0 && !isLoading;
  return (
    <>
      {renderSearch && renderSearch()}
      <table
        className="display table table-striped table-bordered"
        cellSpacing="0"
        width="100%"
      >
        <thead>
          <tr>
            {headers.map((item) => (
              <th key={item.name as string}>{item.title}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((item) => {
            const key = item[keyField] as string;
            return (
              <tr key={key}>
                {headers.map((header) => {
                  const value = header.formatter
                    ? header.formatter(item)
                    : item[header.name];

                  return (
                    <td key={`${key}_${header.name as string}`}>{value}</td>
                  );
                })}
              </tr>
            );
          })}
          {isLoading && !data.length && (
            <tr>
              <td colSpan={headers.length}>Chargement....</td>
            </tr>
          )}
          {emptyRows && !isLoading && (
            <tr>
              <td colSpan={headers.length}>Pas de données pour le moment</td>
            </tr>
          )}
        </tbody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        count={total || 0}
        rowsPerPage={limit ?? 1}
        page={page ?? 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default Table;

export type TableWithoutPaginationProps<T> = {
  headers: TableHeader<T>[];
  data: T[];
  keyField: keyof T;
  isLoading?: boolean;
  renderSearch?: () => any;
};

export function TableWithoutPagination<T>({
  data,
  headers,
  keyField,
  isLoading,
  renderSearch,
}: TableWithoutPaginationProps<T>) {
  const emptyRows = data.length <= 0 && !isLoading;
  return (
    <>
      {renderSearch && renderSearch()}
      <table
        className="display table table-striped table-bordered"
        cellSpacing="0"
        width="100%"
      >
        <thead>
          <tr>
            {headers.map((item) => (
              <th key={item.name as string}>{item.title}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((item) => {
            const key = item[keyField] as string;
            return (
              <tr key={key}>
                {headers.map((header) => {
                  const value = header.formatter
                    ? header.formatter(item)
                    : item[header.name];

                  return (
                    <td key={`${key}_${header.name as string}`}>{value}</td>
                  );
                })}
              </tr>
            );
          })}
          {isLoading && !data.length && (
            <tr>
              <td colSpan={headers.length}>Chargement....</td>
            </tr>
          )}
          {emptyRows && !isLoading && (
            <tr>
              <td colSpan={headers.length}>Pas de données pour le moment</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

type TablePaginationProps = {
  rowsPerPageOptions: number[];
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (perPage: number) => void;
};

function TablePagination({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps) {
  const pages = new Array(Math.ceil(count / rowsPerPage)).fill(0);
  if (pages.length <= 1) return null;
  return (
    <nav
      style={{ display: "flex", alignItems: "center", gap: 20 }}
      aria-label="Page navigation example"
    >
      <select
        value={rowsPerPage}
        onChange={(e) => {
          const val = e.target.value;
          onRowsPerPageChange(parseInt(val));
        }}
        style={{ padding: 5 }}
      >
        {rowsPerPageOptions.map((opt) => (
          <option selected={opt === rowsPerPage} key={opt}>
            {opt}
          </option>
        ))}
      </select>
      <ul className="pagination">
        {pages.map((_, index) => {
          const active = index + 1 === page;
          return (
            <li
              onClick={(e) => {
                e.preventDefault();
                onPageChange(index);
              }}
              key={index}
              className={`page-item ${active ? "active" : ""}`}
            >
              <a className="page-link" href="#">
                {index + 1}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export function DeleteButton({
  deleteFunction,
  success,
  error,
  permission,
  id,
}: {
  deleteFunction: any;
  permission: PermissionValue;
  success: string;
  error: string;
  id: number;
}) {
  const { hasAccess } = usePermissions();
  const isAc = hasAccess([permission]);
  const onDelete = useDeleteItem(deleteFunction, success, error);
  if (!isAc) return null;
  return (
    <button
      type="button"
      onClick={() => onDelete(id)}
      className="btn btn-danger btn-xs icon-only"
    >
      <i className="fa fa-trash-o"></i>
    </button>
  );
}

export function ApprouveButton({
  approuvFunction,
  success,
  error,
  permission,
  title = "Approve",
}: {
  approuvFunction: any;
  permission: PermissionValue;
  success: string;
  error: string;
  title?: string;
}) {
  const { hasAccess } = usePermissions();
  const isAc = hasAccess([permission]);
  const onApprouve = useUpdateItem(approuvFunction, success, error);
  if (!isAc) return null;
  return (
    <button
      onClick={() => onApprouve()}
      type="button"
      className="btn btn-success btn-xs btn-labeled"
    >
      {title}
      <span className="btn-label btn-label-right">
        <i className="fa fa-check"></i>
      </span>
    </button>
  );
}

export function CreateScoreButton({
  permission,
  onClick,
}: {
  permission: PermissionValue;
  onClick: () => void;
}) {
  const { hasAccess } = usePermissions();
  const isAc = hasAccess([permission]);
  if (!isAc) return null;
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className="btn btn-success btn-xs btn-labeled"
    >
      Ajouter score
      <span className="btn-label btn-label-right">
        <i className="fa fa-plus"></i>
      </span>
    </button>
  );
}

export function RejectButton({
  rejectFunction,
  success,
  error,
  permission,
}: {
  rejectFunction: any;
  permission: PermissionValue;
  success: string;
  error: string;
}) {
  const { hasAccess } = usePermissions();
  const isAc = hasAccess([permission]);
  const onReject = useUpdateItem(rejectFunction, success, error);
  if (!isAc) return null;
  return (
    <button
      type="button"
      onClick={() => onReject()}
      style={{ margin: "0px 2px" }}
      className="btn btn-danger btn-xs btn-labeled next-btn"
    >
      Reject
      <span className="btn-label btn-label-right">
        <i className="fa fa-times"></i>
      </span>
    </button>
  );
}

export function UpdateButton<T>({
  permission,
  route,
  state,
  title,
}: {
  route: string;
  state: T;
  title?: string;
  permission?: PermissionValue;
}) {
  const { hasAccess } = usePermissions();
  const isAc = permission ? hasAccess([permission]) : true;
  if (!isAc) return null;
  return (
    <Link
      to={route}
      className="btn btn-warning btn-xs icon-only"
      state={state}
      style={{ margin: "0px 2px" }}
    >
      {title ?? ""}
      <span className="btn-label btn-label-right">
        <i className="fa fa-pencil"></i>
      </span>
    </Link>
  );
}

export function ViewButton<T>({
  permission,
  route,
  state,
}: {
  route: string;
  state?: T;
  permission?: PermissionValue;
}) {
  const { hasAccess } = usePermissions();
  const isAc = !permission || hasAccess([permission]);
  if (!isAc) return null;
  return (
    <Link
      to={route}
      className="btn btn-warning btn-xs icon-only"
      state={state}
      style={{ margin: "0px 2px" }}
    >
      <i className="fa fa-eye"></i>
    </Link>
  );
}

export const useDeleteItem = (
  deleteFunction: (id: number) => Promise<any>,
  success: string,
  error: string
) => {
  const onDelete = async (id: number) => {
    Swal.fire({
      title: "Êtes vous sûres ?",
      icon: "warning",
      showCancelButton: true,
      iconColor: COLORS.primary,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: COLORS.primary,
      cancelButtonColor: COLORS.red,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await deleteFunction(id);
          if ("data" in res) {
            Swal.fire({
              icon: "success",
              title: success,
              showConfirmButton: false,
              timer: 5000,
              iconColor: COLORS.primary,
              confirmButtonColor: COLORS.primary,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error,
              showConfirmButton: false,
              timer: 5000,
              iconColor: COLORS.red,
              confirmButtonColor: COLORS.red,
            });
          }
        } catch (ex) {
          Swal.fire({
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
            iconColor: COLORS.red,
            confirmButtonColor: COLORS.red,
          });
        }
      },
    });
  };

  return onDelete;
};

export const useUpdateItem = (
  updateFunction: () => Promise<any>,
  success: string,
  error: string
) => {
  const onDelete = async () => {
    Swal.fire({
      title: "Êtes vous sûres ?",
      icon: "warning",
      showCancelButton: true,
      iconColor: COLORS.primary,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: COLORS.primary,
      cancelButtonColor: COLORS.red,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const res = await updateFunction();
          if ("data" in res) {
            Swal.fire({
              icon: "success",
              title: success,
              showConfirmButton: false,
              timer: 5000,
              iconColor: COLORS.primary,
              confirmButtonColor: COLORS.primary,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error,
              showConfirmButton: false,
              timer: 5000,
              iconColor: COLORS.red,
              confirmButtonColor: COLORS.red,
            });
          }
        } catch (ex) {
          Swal.fire({
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
            iconColor: COLORS.red,
            confirmButtonColor: COLORS.red,
          });
        }
      },
    });
  };

  return onDelete;
};

export function validateImg(yup: typeof Yup) {
  return yup
    .mixed()
    .test("fileType", "Format d'image invalide", (value?: File) => {
      if (value && value instanceof File) {
        return value.type.indexOf("image/") !== -1;
      }
      return true;
    });
}

export function validatePdf(yup: typeof Yup) {
  return yup
    .mixed()
    .test("fileType", "Format de fichier invalide", (value?: File) => {
      if (value && value instanceof File) {
        const supportedFormats = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        return supportedFormats.includes(value.type);
      }
      return true;
    });
}

export function validateVideo(yup: typeof Yup) {
  return yup
    .mixed()
    .test("fileType", "Format de video invalide", (value?: File) => {
      if (value && value instanceof File) {
        return value.type.indexOf("video/") !== -1;
      }
      return true;
    });
}

export function validateYtbLink(yup: typeof Yup) {
  return yup
    .mixed()
    .test("fileType", "Lien youtube invalid", (value?: string) => {
      if (value) {
        const youtubeRegex =
          /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        // Test the URL against the regular expression
        return youtubeRegex.test(value);
      }
      return true;
    });
}

export function imageFormatter(
  item: Federation | User | Equipe | Competition,
  width = 40,
  rounded = true
) {
  const src = item.image
    ? ApiUrl + "/" + item.image
    : `https://ui-avatars.com/api/?name=${
        "nom" in item ? item.nom : item.name
      }`;
  return (
    <img
      src={src}
      alt={""}
      style={{
        width,
        height: width,
        objectFit: "cover",
        borderRadius: rounded ? "50%" : undefined,
      }}
    />
  );
}
