import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import moment from "moment";
import Table, {
  DeleteButton,
  TableHeader,
  TableProps,
  TableWithoutPagination,
  TableWithoutPaginationProps,
  UpdateButton,
  ViewButton,
  imageFormatter,
} from "../../../components/Table";

import Input from "../../../components/Input";
import { Equipe } from "../../../types";
import { EquipeApi } from "../../../utils/api/equipe.api";

type EquipeTableProps = Omit<TableProps<Equipe>, "keyField" | "headers">;
type EquipeSelectTableProps = Omit<
  TableWithoutPaginationProps<Equipe>,
  "keyField" | "headers"
>;

const commonHeaders: TableHeader<Equipe>[] = [
  { name: "image", title: "Image", formatter: imageFormatter },
  { name: "nom", title: "Nom" },
  { name: "sport", title: "Sport", formatter: (i) => i.sport.name },
  { name: "type", title: "Type", formatter: (i) => i.type },
  { name: "niveau", title: "Catégorie", formatter: (i) => i.niveau.name },
];

const EquipeTable = ({ ...props }: EquipeTableProps) => {
  const [onDelete] = EquipeApi.useDeleteMutation();
  const headers: TableHeader<Equipe>[] = [
    ...commonHeaders,
    {
      name: "createdDate",
      title: "Date de création",
      formatter: (i) => moment(i.createdDate).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/equipes/${item.slug}`}
            state={item}
            permission="equipes.view"
          />

          <UpdateButton
            route={`/dashboard/equipes/update/${item.slug}`}
            state={item}
            permission="equipes.update"
          />

          <DeleteButton
            id={item.id}
            deleteFunction={onDelete}
            permission="equipes.delete"
            error={"Une erreur est survenue lors de la suppression"}
            success={"Fédération supprimée avec succès"}
          />
        </>
      ),
    },
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default EquipeTable;

export const ChoiceEquipeTable = ({
  selections,
  setSelections,
  ...props
}: EquipeSelectTableProps & {
  selections: number[];
  setSelections: any;
}) => {
  const headers = [
    ...commonHeaders,
    {
      name: "id",
      title: "Selections",
      formatter: (item) => {
        const checked = selections.includes(item.id);

        return (
          <div className="text-center">
            <Input
              checked={checked}
              onChange={(e) => {
                if (checked) {
                  setSelections((old: number[]) =>
                    old.filter((f) => f !== item.id)
                  );
                } else {
                  setSelections((old: number[]) => [...old, item.id]);
                }
              }}
              type="checkbox"
            />
          </div>
        );
      },
    } as TableHeader<Equipe>,
  ];
  return (
    <TableWithoutPagination keyField={"id"} {...props} headers={headers} />
  );
};
