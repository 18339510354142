import moment from "moment";
import Ticker, { NewsTicker } from "nice-react-ticker";
import { NewsApi } from "../../utils/api/news.api";
const News = () => {
  const { data = [] } = NewsApi.usePublishedQuery();
  if (data.length === 0) return null;
  return (
    <div
      className="ticker-news-box"
      style={{ display: "flex", alignItems: "center" }}
    >
      <span className="breaking-news">breaking news</span>
      <div>
        <Ticker slideSpeed={50} isNewsTicker={true}>
          {data.map((item) => (
            <NewsTicker
              id={item.id}
              key={item.id}
              title={item.content}
              url={"#"}
              meta={moment(item.createdAt).format("DD/MM HH:mm")}
            />
          ))}
        </Ticker>
      </div>
    </div>
  );
};

export default News;
