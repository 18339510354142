// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { AppConfig, AppConfigFormData } from "../../types";
import { ApiUrl } from "./URL";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const AppConfigApi = createApi({
  tagTypes: ["app-config"],
  reducerPath: "AppConfigApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    appConig: builder.query<AppConfig, void>({
      query: () => ({
        url: "/app-config",
        method: "GET",
      }),
      providesTags: ["app-config"],
    }),
    maj: builder.mutation<AppConfig, AppConfigFormData>({
      query: (data) => {
        return {
          url: `/app-config/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["app-config"],
    }),
  }),
});
