import { LoaderPage } from "../../components/Loader";
import AthleteItem from "../../components/SideBar/PostWidgetItem/AthleteItem";
import { Equipe, Match, User } from "../../types";
import {
  FollowButton,
  useLoadMoreQuery,
  UserQuery,
} from "../../utils/api/common.api";
import { EquipeApi, useEquipeFromLocation } from "../../utils/api/equipe.api";
import { MatchApi, MatchQuery } from "../../utils/api/match.api";
import { ParticipeApi, ParticipeQuery } from "../../utils/api/participe.api";
import { UserApi } from "../../utils/api/user.api";
import StatsItem from "../athlete/components/StatsItem";
import MatchItem from "../competition/components/MatchItem";
const DetailEquipe = ({ item }: { item: Equipe }) => {
  const {
    data = {
      buts: 0,
      competitions: 0,
      competitionsWins: 0,
      draws: 0,
      loses: 0,
      matches: 0,
      wins: 0,
    },
    isLoading,
  } = EquipeApi.usePerformencesQuery(item.id);
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="grid-box">
                  <div className="title-section d-flex justify-content-beetwen">
                    <h1>
                      <span>Information sur l'équipe </span>
                    </h1>
                    <FollowButton subscriberId={item.id} field="equipe" />
                  </div>

                  <ul className="autor-list">
                    <li>
                      <div className="athlete-box">
                        <img
                          className="athlete-img"
                          src="https://api.cnosd.org/uploads/federations/img_689_2005241736_1716219369900.jpg"
                          alt=""
                        />

                        <div className="autor-content">
                          <div className="autor-title">
                            <h4>{item.nom}</h4>
                          </div>
                          <div className="d-flex flex-wrap gap-10 flex-column">
                            <p>
                              Type :{" "}
                              <span className="text-bold">{item.type}</span>
                            </p>

                            <p>
                              Sport :{" "}
                              <span className="text-bold">
                                {item.sport.name}{" "}
                              </span>
                            </p>
                            <p>
                              Fédération :
                              <span className="text-bold">
                                {" "}
                                {item.federation.nom}{" "}
                              </span>
                            </p>
                            <p>
                              Catégorie :{" "}
                              <span className="text-bold">
                                {item.niveau.name}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div>
                    <div className="title-section">
                      <h1>
                        <span>Statistiques de l'équipe</span>
                      </h1>
                    </div>
                    <div className="hexagon-box">
                      <StatsItem
                        title="Compétitions"
                        value={data.competitions}
                      />
                      <StatsItem
                        title="Compétitions gagnées"
                        value={data.competitionsWins}
                      />
                      <StatsItem title="Matchs gagnés" value={data.wins} />
                      <StatsItem title="Matchs perdus" value={data.loses} />
                      <StatsItem title="Matchs nulls" value={data.draws} />
                      <StatsItem title="Buts marqués" value={data.buts} />
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="title-section">
                      <h1>
                        <span>Historique des matchs</span>
                      </h1>
                    </div>
                    <ul className="autor-list">
                      <DisplayMatchesFront showCompetName equipe={item.id} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              <div className="sidebar">
                <AthletesList equipe={item.id} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

type AthletesListProps = UserQuery;

export function AthletesList(
  props: AthletesListProps & { showTitle?: boolean }
) {
  const { renderButton, data, isLoading } = useLoadMoreQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      ...props,
      userType: ["Athlète"],
      limit: 6,
    }
  );

  return (
    <DisplayAthletes
      data={data}
      loading={isLoading}
      renderButton={renderButton}
      showTitle={props.showTitle}
    />
  );
}

export function AthletesSinglesList(
  props: ParticipeQuery & { showTitle?: boolean }
) {
  const { renderButton, data, isLoading } = useLoadMoreQuery<
    User,
    ParticipeQuery
  >(ParticipeApi.useSinglesQuery, {
    ...props,
    limit: 6,
  });

  return (
    <DisplayAthletes
      data={data}
      loading={isLoading}
      renderButton={renderButton}
      showTitle={props.showTitle}
    />
  );
}

type DisplayAthletesProps = {
  showTitle?: boolean;
  renderButton: () => any;
  data: User[];
  loading: boolean;
};
export function DisplayAthletes({
  data,
  renderButton,
  showTitle,
  loading,
}: DisplayAthletesProps) {
  console.log("cond", !loading && data.length);
  return (
    <div className="widget tab-posts-widget">
      {showTitle !== false && (
        <div className="title-section">
          <h1>
            <span>Liste des athlètes</span>
          </h1>
        </div>
      )}
      <div className="tab-content">
        <div className="tab-pane active" id="option1">
          <ul className="list-posts">
            {!loading && data.length === 0 && (
              <li>Pas d'athlète pour le moment</li>
            )}
            {data?.map((c) => (
              <AthleteItem item={c} key={c.id} />
            ))}
          </ul>
          {renderButton()}
        </div>
      </div>
    </div>
  );
}

export default function DetailEquipeFront() {
  const { item } = useEquipeFromLocation();
  if (!item) return <LoaderPage />;
  return <DetailEquipe item={item} />;
}

export function DisplayMatchesFront(
  props: MatchQuery & { showCompetName?: boolean }
) {
  const { data, isLoading, renderButton } = useLoadMoreQuery<Match, MatchQuery>(
    MatchApi.useMatchsQuery,
    { limit: 6, ...props }
  );

  return (
    <>
      {!isLoading && data.length === 0 && <p>Pas de matche pour le moment</p>}
      {data.map((c) => (
        <MatchItem showCompetName={props.showCompetName} item={c} key={c.id} />
      ))}
      {renderButton()}
    </>
  );
}
