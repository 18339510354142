import { Federation } from "../types";

export type TypeFede = TypeCompetition | "Both";
export const TypeFedes: TypeFede[] = ["Equipe", "Individuel", "Both"];

export type UserType =
  | "President Fédération"
  | "Admin System"
  | "Agents CNO"
  | "Agents Fédération"
  | "Agents Équipe"
  | "Athlète"
  | "Abonné"
  | "Juge"
  | "Inspecteur";

export const UserTypes: UserType[] = [
  "Admin System",
  "President Fédération",
  "Agents Fédération",
  "Agents CNO",
  "Agents Équipe",
  "Juge",
  "Inspecteur",
];

export const CreateAgentTypes: UserType[] = [
  "Agents Fédération",
  "Agents Équipe",
  "Juge",
  "Inspecteur",
];

export const CreateAgentTypesIndividuel: UserType[] = [
  "Agents Fédération",
  "Juge",
  "Inspecteur",
];

export const CreateUserTypes: UserType[] = [
  "Admin System",
  "President Fédération",
  "Agents CNO",
];

export type Sexe = "Homme" | "Femme" | "Autre";

export const Sexes: Sexe[] = ["Homme", "Femme", "Autre"];

export type TypeEquipe = "Masculin" | "Féminin";
export const TypeEquipes: TypeEquipe[] = ["Masculin", "Féminin"];

export type MessageContactStatus = "Nouveau" | "Traité";
export const MessageContactStatusList: MessageContactStatus[] = [
  "Nouveau",
  "Traité",
];

export type TypePhase = "Groupe" | "Championat" | "Éliminatoire";

export const TypePhases: TypePhase[] = ["Groupe", "Championat", "Éliminatoire"];

export type TypeCompetition = "Equipe" | "Individuel";
export const TypeCompetitions: TypeCompetition[] = ["Equipe", "Individuel"];

export type TypeScore = "Buts" | "Points";

export const TypeScores: TypeScore[] = ["Buts", "Points"];

export type MatchStatus = "En attente" | "En cours" | "Terminé";

export const MatchStatusList: MatchStatus[] = [
  "En attente",
  "En cours",
  "Terminé",
];

export function getCompetTypes(type?: Federation["type"]): TypeCompetition[] {
  if (!type) return [];
  if (type !== "Both") return [type];
  return ["Equipe", "Individuel"];
}
