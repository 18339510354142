import { Link } from "react-router-dom";
import { Federation } from "../../../types";
import { getImage } from "../../../utils/api/common.api";

const FederationItem = ({ item }: { item: Federation }) => {
  return (
    <li>
      <img src={getImage(item.image)} alt="" />
      <div className="post-content">
        <h2>
          <Link to={`/federation/${item.slug}`}>
            {item.nom} - {item.sport.name}
          </Link>
        </h2>
        <ul className="post-tags d-flex flex-column">
          <li>
            <i className="fa fa-location-arrow"></i>
            {item.address}
          </li>
          <li>
            <i className="fa fa-phone"></i>
            {item.telephone}
          </li>
        </ul>
      </div>
    </li>
  );
};

export default FederationItem;
