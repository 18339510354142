/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import CompetitionItem from "../../pages/federation/components/CompetitionItem";
import { CompetitionApi } from "../../utils/api/competition.api";
import { useSportsSelect } from "../../utils/api/sports.api";
import OwnCarousel from "../OwnCarousel";

const CompteDropdown = () => {
  const sports = useSportsSelect();
  const [sport, setSport] = useState<number | string>("");
  const { data, isLoading, isFetching } = CompetitionApi.useCompetitionsQuery({
    sport,
    limit: 100,
    isValidated: true,
  });
  const loading = isLoading || isFetching;
  const onSelect = (e: any, value: string | number) => {
    e.stopPropagation();
    e.preventDefault();
    setSport(value);
  };
  return (
    <div className="megadropdown">
      <div className="container">
        <div className="inner-megadropdown tech-dropdown">
          <div className="owl-wrapper">
            <ul className="horizontal-filter-posts">
              <li>
                <a
                  onClick={(e) => onSelect(e, "")}
                  className={sport === "" ? "active" : ""}
                  href="#"
                >
                  Tout
                </a>
              </li>
              {sports.map((s) => {
                return (
                  <li
                    onClick={(e) => {
                      onSelect(e, s.value);
                    }}
                    key={s.value}
                  >
                    <a className={s.value == sport ? "active" : ""} href="#">
                      {s.label}
                    </a>
                  </li>
                );
              })}
            </ul>
            {!loading && (
              <>
                {!!data?.results?.length && (
                  <OwnCarousel num={4}>
                    {data?.results?.map((item) => (
                      <div
                        key={item.id}
                        className="row"
                        style={{ marginRight: 2 }}
                      >
                        <CompetitionItem md={12} item={item} />
                      </div>
                    ))}
                  </OwnCarousel>
                )}
                {!data?.results?.length && (
                  <h4 className="text-center my-5">
                    Pas de compétitions trouvé
                  </h4>
                )}
              </>
            )}
            {loading && <h4 className="text-center my-5">Chargements...</h4>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompteDropdown;
