import { useUser } from "../../../redux/store";
import { User } from "../../../types";
import { UserQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";
import BreadCrumb from "../components/BreadCrumb";
import AthleteTable from "./athlete-table";

const Athletes = () => {
  const user = useUser();
  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      federation: user?.federation?.id,
      userType: ["Athlète"],
    },
    {
      sport: true,
      niveau: true,
      equipe: { federation: user?.federation?.id },
      federation: !user?.federation,
    }
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Athlètes"
        subTitle="Liste des athlètes"
        items={[{ title: "Home" }, { title: "Tous les athlètes" }]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-warning no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des athlètes ({props.total})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <AthleteTable {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Athletes;
