import moment from "moment";
import { Link } from "react-router-dom";
import { Federation } from "../../../types";
import { getFN, getImage } from "../../../utils/api/common.api";

const FederationItem = ({ item }: { item: Federation }) => {
  return (
    <>
      <div className="table-row">
        <div className="forum-post">
          <img src={getImage(item.image)} alt="" />
          <div className="post-autor-date">
            <h2>
              <Link to={`/federation/${item.slug}`}>{item.nom}</Link>
            </h2>
            <p>
              <a href="#">{item.sport.name}</a>
            </p>
            <p>
              Crée par <a href="#">{getFN(item.president)}</a> -{" "}
              {moment(item.createdDate).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
        <div className="forum-topics">
          {/* <p>
            <span>10 </span> Compétitions
          </p>
          <p>
            <span>200</span> Athlètes
          </p>
          <p>
            <span>32</span> Juges
          </p> */}
        </div>
      </div>
    </>
  );
};
export default FederationItem;
