import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import moment from "moment";
import Table, {
  DeleteButton,
  TableHeader,
  TableProps,
  UpdateButton,
  ViewButton,
  imageFormatter,
} from "../../../components/Table";
import { User } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";

type AgentTableProps = Omit<TableProps<User>, "keyField" | "headers">;
const AgentTable = ({ ...props }: AgentTableProps) => {
  const [onDelete] = UserApi.useDeleteMutation();
  const headers: TableHeader<User>[] = [
    { name: "image", title: "Image", formatter: imageFormatter },
    { name: "prenom", title: "Nom", formatter: getFN },
    {
      name: "federation",
      title: "Fédération",
      formatter: (i) => i.federation?.acronyme,
    },
    { name: "equipe", title: "Équipe", formatter: (i) => i.equipe?.nom ?? "" },
    {
      name: "userType",
      title: "Type",
      formatter: (i) => (
        <span className="label label-info label-wide">{i.userType}</span>
      ),
    },
    {
      name: "createdAt",
      title: "Date d'inscription",
      formatter: (i) => moment(i.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/agents/${item.id}`}
            state={item}
            permission="agents.view"
          />

          <UpdateButton
            route={`/dashboard/agents/update/${item.id}`}
            state={item}
            permission="agents.update"
          />

          <DeleteButton
            permission="agents.delete"
            id={item.id}
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Agent supprimé avec succès"}
          />
        </>
      ),
    },
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default AgentTable;
