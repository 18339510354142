// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Niveau, NiveauFormData, SelectOptions } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const NiveauApi = createApi({
  tagTypes: ["niveaux", "niveauxSelect", "niveau"],
  reducerPath: "NiveauApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    niveaux: builder.query<PaginationResults<Niveau>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/niveaux/`, query),
        method: "GET",
      }),
      providesTags: ["niveaux"],
    }),
    niveauxSelect: builder.query<Niveau[], void>({
      query: () => ({
        url: `/niveaux/select/`,
        method: "GET",
      }),
      providesTags: ["niveauxSelect"],
    }),
    findBySlug: builder.query<Niveau, string>({
      query: (slug) => ({
        url: `/niveaux/slug/${slug}/`,
        method: "GET",
      }),
      providesTags: (a, b, slug) => [{ type: "niveau", id: slug }],
    }),
    maj: builder.mutation<Niveau, { id?: number; data: NiveauFormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/niveaux/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/niveaux/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["niveaux", "niveauxSelect"],
    }),
    delete: builder.mutation<Niveau, number>({
      query: (id) => ({
        url: `/niveaux/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["niveaux", "niveauxSelect"],
    }),
  }),
});

export function useNiveauxsSelect() {
  const [items, setItems] = useState<SelectOptions>([]);
  const { data } = NiveauApi.useNiveauxSelectQuery();
  useEffect(() => {
    if (data) {
      setItems(data.map((i) => ({ value: i.id, label: i.name })));
    }
  }, [data]);
  return items;
}

export function useNiveauFromLocation() {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [fetch, { data }] = NiveauApi.useLazyFindBySlugQuery();
  const [item, setItem] = useState<Niveau>(location.state);

  useEffect(() => {
    if (slug) fetch(slug);
  }, [slug]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
