import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input, {
  GoogleInput,
  Select,
  TextArea,
} from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { validateImg } from "../../../components/Table";
import { Federation, FederationFormData } from "../../../types";
import { FedesApi, useFedeFromLocation } from "../../../utils/api/fedes.api";
import { useSportsSelect } from "../../../utils/api/sports.api";
import { useUsersSelect } from "../../../utils/api/user.api";
import { TypeFedes } from "../../../utils/const-fields";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import { usePermissions } from "../../../utils/permissions";
import BreadCrumb from "../components/BreadCrumb";

type CreateFedeProps = {
  item?: Federation;
};
const CreateFede = ({ item }: CreateFedeProps) => {
  const navigate = useNavigate();
  const sports = useSportsSelect();
  const { hasAccess } = usePermissions();
  const { users, refetch: refetchUsers } = useUsersSelect(
    { userType: ["President Fédération"] },
    (u) => {
      if (item) {
        return u.id === item.president?.id || !u.federation;
      } else {
        return !u.federation;
      }
    }
  );
  const [exec, { isLoading }] = FedesApi.useMajMutation();
  const { setErrors, errors, register, onSubmitForm, data, onChange } =
    useFormData<FederationFormData>(
      {
        nom: item?.nom || "",
        acronyme: item?.acronyme || "",
        address: item?.address || "",
        description: item?.description || "",
        email: item?.email || "",
        president: item?.president?.id || "",
        sport: item?.sport?.id || "",
        telephone: item?.telephone || "",
        latitude: item?.latitude || "",
        longitude: item?.longitude || "",
        webSite: item?.webSite || "",
        type: item?.type || "",
        createdDate: item?.createdDate || "",
      },
      (yup) => ({
        nom: yup.string().required().label("Nom"),
        acronyme: yup.string().required().label("Acronyme"),
        address: yup.string().required().label("Address"),
        description: yup.string().required().label("Description"),
        email: yup.string().email().required().label("Email"),
        president: yup.string().required().label("Prédident"),
        sport: yup.string().required().label("Sport"),
        telephone: yup.string().required().label("Téléphone"),
        image: item
          ? validateImg(yup).notRequired().label("Image principale")
          : validateImg(yup).required().label("Image principale"),
        logo: item
          ? validateImg(yup).notRequired().label("Logo")
          : validateImg(yup).required().label("Logo"),
      })
    );

  const onSubmit = React.useCallback(
    async (data: FederationFormData) => {
      let fd = new FormData();
      if (data instanceof FormData) {
        fd = data;
      } else {
        for (let [k, v] of Object.entries(data)) {
          fd.append(k, v as any);
        }
      }
      const res = await exec({ id: item?.id, data: fd });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          nom:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Fédération ${item ? "modifiée" : "ajoutée"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  React.useEffect(() => {
    const listner = function () {
      if (document.visibilityState === "visible") {
        refetchUsers();
      }
    };
    document.addEventListener("visibilitychange", listner);
    return () => {
      document.removeEventListener("visibilitychange", listner);
    };
  }, []);
  const { value: val0, ...logoProps } = register("logo");
  const { value: val1, ...imgProps } = register("image");
  return (
    <div className="main-page">
      <BreadCrumb
        title="Fédération"
        subTitle={
          item
            ? "Formulaire de modification d'une fédération"
            : "Formulaire de création d'une fédération"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Toutes les fédérations",
            route: "/dashboard/federations",
          },
          {
            title: item
              ? "Modification d'une fédération"
              : "Création d'une fédération",
          },
        ]}
      />
      <div className="container-fluid">
        <div className="row page-title-div">
          <div className="col-sm-6">
            <h2 className="title"></h2>
            <p className="sub-title"></p>
          </div>
        </div>
      </div>

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} d'une
                      fédération{" "}
                    </h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <h5 className="underline mt-n">Fédé Info</h5>

                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir un type"}
                          {...register("type")}
                          label="Type"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={TypeFedes.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir un sport"}
                          label="Sport"
                          {...register("sport")}
                          containerClass="form-group"
                          inputClass="form-control"
                          options={sports}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nom complet"}
                          label="Nom complet"
                          {...register("nom")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Acronyme"}
                          label="Acronyme"
                          {...register("acronyme")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Email"}
                          label="Email"
                          {...register("email")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="email"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Téléphone"}
                          label="Téléphone"
                          {...register("telephone")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="tel"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"URL Site web"}
                          label="URL Site web"
                          {...register("webSite")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="url"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir un Président"}
                          label="Choisir un Président"
                          {...register("president")}
                          containerClass="form-group"
                          inputClass="form-control"
                          helperBlock={
                            hasAccess(["president-fede.create"]) && (
                              <span className="help-block mt-5">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    window.open(
                                      `${window.location.origin}/dashboard/presidents-federations/create`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  Créer un nouveau président ?.
                                </span>
                              </span>
                            )
                          }
                          options={users}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <TextArea
                          placeholder={"Description"}
                          label="Description"
                          {...register("description")}
                          containerClass="form-group"
                          inputClass="form-control"
                        />
                      </div>
                    </div>

                    <h5 className="underline mt-30">
                      Informations complétaires
                    </h5>

                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <GoogleInput
                          {...register("address")}
                          value={data.address}
                          label="Adresse"
                          placeholder="Adresse"
                          inputClass="form-control"
                          onChange={(data) => {
                            onChange({
                              address: data.address,
                              latitude: data.latitude,
                              longitude: data.longitude,
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Date de création"}
                          label="Date de création"
                          {...register("createdDate")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="date"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Logo"}
                          label="Logo"
                          {...logoProps}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="file"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Image principale"}
                          label="Image principale"
                          {...imgProps}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="file"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateFede;

export function UpdateFede() {
  const { item } = useFedeFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateFede item={item} />;
}
