import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "../../types";
import { AppStorage } from "../../utils/storage";
const { setItem, getItem } = AppStorage;
export type UserState = Auth;

const def: UserState = {
  user: undefined,
  access_token: undefined,
};
const it = getItem<Auth>("auth", def) ?? def;

const initialState: UserState = it;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onSetUser: (state, action: PayloadAction<UserState["user"]>) => {
      state.user = action.payload;
      setItem("auth", state);
    },
    onSetToken: (state, action: PayloadAction<UserState["access_token"]>) => {
      state.access_token = action.payload;
      setItem("auth", state);
    },
    onSetUserToken: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload.user;
      state.access_token = action.payload.access_token;
      setItem("auth", state);
    },
    onLogout: (state) => {
      state.user = undefined;
      state.access_token = undefined;
      setItem("auth", state);
    },
  },
});

export const { onLogout, onSetToken, onSetUser, onSetUserToken } =
  userSlice.actions;

export default userSlice.reducer;
