import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { useAppSelector } from "../../../redux/store";
import { AppConfigFormData } from "../../../types";
import { AppConfigApi } from "../../../utils/api/app-config.api";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import BreadCrumb from "../components/BreadCrumb";

const UpdateAppConfig = () => {
  const navigate = useNavigate();
  const [exec, { isLoading }] = AppConfigApi.useMajMutation();
  const configs = useAppSelector((s) => s.app_config);

  const { onSubmitForm, register } = useFormData<AppConfigFormData>(
    {
      address: configs.address || "",
      telephone: configs.telephone || "",
      email: configs.email || "",
      facebook: configs.facebook || "",
      instagram: configs.instagram || "",
      linkedin: configs.linkedin || "",
      twitter: configs.twitter || "",
      youtube: configs.youtube || "",
    },
    (Yup) => ({})
  );

  const onSubmit = React.useCallback(async (data: AppConfigFormData) => {
    const res = await exec(data);
    if ("error" in res) {
      const error = res.error as any;
      Swal.fire({
        icon: "error",
        iconColor: COLORS.red,
        confirmButtonColor: COLORS.red,
        title:
          error.data?.message ||
          "Veuillez vous assurer d'entrer les bonnes données",
      });
    } else {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.primary,
        confirmButtonColor: COLORS.primary,
        title: `Données modifiées avec succès`,
      });
      navigate(-1);
    }
  }, []);

  return (
    <div className="main-page">
      <BreadCrumb
        title="Coordonnées"
        subTitle={"Coordonnées"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Coordonnées",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - Coordonnées</h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Adresse"}
                          label="Adresse"
                          {...register("address")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Téléphone"}
                          label="Téléphone"
                          {...register("telephone")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="tel"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Email"}
                          label="Email"
                          {...register("email")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="email"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Facebook"}
                          label="Facebook"
                          {...register("facebook")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Instagram"}
                          label="Instagram"
                          {...register("instagram")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="url"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"LinkedIn"}
                          label="LinkedIn"
                          {...register("linkedin")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="url"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Twitter"}
                          label="Twitter"
                          {...register("twitter")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="url"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Youtube"}
                          label="Youtube"
                          {...register("youtube")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="url"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdateAppConfig;
