import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import DashboardItem from "../../../components/DashboardItem";
import { LoaderPage } from "../../../components/Loader";
import { useUser } from "../../../redux/store";
import { Competition, Equipe, Federation, User } from "../../../types";
import {
  UserQuery,
  getFN,
  usePaginationQuery,
} from "../../../utils/api/common.api";
import {
  CompetitionApi,
  CompetitionQuery,
} from "../../../utils/api/competition.api";
import { EquipeApi, EquipeQuery } from "../../../utils/api/equipe.api";
import { useFedeFromLocation } from "../../../utils/api/fedes.api";
import { UserApi } from "../../../utils/api/user.api";
import { useTabsControl } from "../../../utils/hooks";
import { PermissionValue, usePermissions } from "../../../utils/permissions";
import AgentTable from "../agents/agent-table";
import AthleteTable from "../athletes/athlete-table";
import { DataItem, DataList } from "../athletes/detail-athlete";
import CompetitionTable from "../competitions/competition-table";
import BreadCrumb from "../components/BreadCrumb";
import EquipeTable from "../equipes/equipe-table";

type Dashboard = {
  agents: number;
  equipes: number;
  athletes: number;
  competitions: number;
};

type Data = {
  title: string;
  component: any;
  dashboardField: keyof Dashboard;
  bg: string;
  icon: any;
  permission?: PermissionValue;
};
const ShowFedeComponent = ({ item }: { item: Federation }) => {
  const { active, setActive } = useTabsControl(-1);
  const [counts, setCounts] = useState<Dashboard>({
    agents: 0,
    athletes: 0,
    equipes: 0,
    competitions: 0,
  });

  const { hasAccess } = usePermissions();

  let DATA: Data[] = [
    {
      title: "Agents",
      component: Agents,
      bg: "bg-warning",
      dashboardField: "agents",
      icon: <i className="fa fa-users" />,
      permission: "agents.list",
    },
    {
      title: "Equipes",
      component: Equipes,
      bg: "bg-danger",
      dashboardField: "equipes",
      icon: <i className="fa fa-flag-o" />,
    },
    {
      title: "Athlètes",
      component: Athletes,
      bg: "bg-success",
      dashboardField: "athletes",
      icon: <i className="fa fa-users" />,
    },
    {
      title: "Compétitions",
      component: Competitions,
      bg: "bg-primary",
      dashboardField: "competitions",
      icon: <i className="fa fa-area-chart" />,
    },
  ];

  if (item.type === "Individuel") {
    DATA.splice(1, 1);
  }

  DATA = DATA.filter((i) => !i.permission || hasAccess([i.permission]));

  return (
    <>
      <div className="main-page">
        <BreadCrumb
          title={item.nom}
          subTitle="Détail fédération"
          items={[
            {
              title: "Home",
              route: "/dashboard",
            },
            { title: "Détail fédération" },
          ]}
        />
        <section className="section pt-10">
          <div className="container-fluid">
            <div className="row mt-5 pb-5">
              {DATA.map((it) => (
                <DashboardItem
                  cols={4}
                  title={it.title}
                  bg={it.bg}
                  count={counts[it.dashboardField]}
                  key={it.dashboardField}
                  icon={it.icon}
                />
              ))}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel border-primary no-border border-3-top">
                  <div className="panel-body p-20">
                    <ul className="tabs">
                      <li
                        role="presentation"
                        className={active === -1 ? "active" : ""}
                      >
                        <a
                          href={`#infos`}
                          onClick={() => setActive(-1)}
                          aria-controls={"infos"}
                          role="tab"
                          style={{ fontSize: 12 }}
                          data-toggle="tab"
                        >
                          Infos fédération
                        </a>
                      </li>
                      {DATA.map((ut, index) => (
                        <li
                          role="presentation"
                          className={index === active ? "active" : ""}
                        >
                          <a
                            href={`#${ut.title}`}
                            onClick={() => setActive(index)}
                            aria-controls={ut.title}
                            role="tab"
                            style={{ fontSize: 12 }}
                            data-toggle="tab"
                          >
                            {ut.title}
                          </a>
                        </li>
                      ))}
                    </ul>

                    <div className="tabs-container">
                      <div
                        role="tabpanel"
                        className={`${active === -1 ? "active" : ""}`}
                        id={"infos"}
                      >
                        <InfosFederations item={item} />
                      </div>
                      {DATA.map(
                        (
                          {
                            title,
                            component: Component,
                            dashboardField,
                            permission,
                          },
                          index
                        ) => (
                          <div
                            role="tabpanel"
                            className={`${index === active ? "active" : ""}`}
                            id={title}
                            key={dashboardField}
                          >
                            <Component
                              item={item}
                              setCounts={setCounts}
                              field={dashboardField}
                              permission={permission}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default function ShowFede() {
  const { item } = useFedeFromLocation();
  if (!item) return <LoaderPage />;
  return <ShowFedeComponent item={item} />;
}

export function MaFederation() {
  const navigate = useNavigate();
  const { federation } = useUser();
  useEffect(() => {
    if (federation) {
      navigate(`/dashboard/federations/${federation.slug}`, { replace: true });
    } else {
      navigate("/error-404", { replace: true });
    }
  }, [federation]);

  return <LoaderPage />;
}

type CommonProps = {
  setCounts: any;
  field: string;
  item: Federation;
  permission?: PermissionValue;
};

export function useSetCount(setCounts: any, field: string, total?: number) {
  useEffect(() => {
    if (total !== undefined) {
      setCounts((old: any) => ({ ...old, [field]: total }));
    }
  }, [total]);
}
function Agents({ item, field, setCounts, permission }: CommonProps) {
  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      userType: ["Agents Équipe", "Agents Fédération", "Juge", "Inspecteur"],
      federation: item.id,
    },
    {
      equipe: { federation: item.id },
      userType: true,
    }
  );
  useSetCount(setCounts, field, props.total);

  return <AgentTable {...props} />;
}

function Equipes({ item, field, setCounts }: CommonProps) {
  const props = usePaginationQuery<Equipe, EquipeQuery>(
    EquipeApi.useEquipesQuery,
    {
      federation: item.id,
    },
    { sport: true, niveau: true }
  );
  useSetCount(setCounts, field, props.total);
  return <EquipeTable {...props} />;
}

function Athletes({ item, field, setCounts }: CommonProps) {
  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      userType: ["Athlète"],
      federation: item.id,
    },
    {
      sport: true,
      niveau: true,
      equipe: { federation: item.id },
    }
  );
  useSetCount(setCounts, field, props.total);
  return <AthleteTable {...props} />;
}

function Competitions({ item, field, setCounts }: CommonProps) {
  const props = usePaginationQuery<Competition, CompetitionQuery>(
    CompetitionApi.useCompetitionsQuery,
    {
      federation: item.id,
      isValidated: true,
    },
    { niveau: true, sport: true }
  );
  useSetCount(setCounts, field, props.total);

  return <CompetitionTable {...props} />;
}

function InfosFederations({ item }: { item: Federation }) {
  const { hasAccess } = usePermissions();
  const navigate = useNavigate();
  const DATA: DataItem[] = [
    { title: "Nom", value: item.nom },
    { title: "Type", value: item.type },
    { title: "Sport", value: item.sport?.name },
    { title: "Acronyme", value: item?.acronyme },
    { title: "Email", value: item.email },
    { title: "Téléphone", value: item.telephone },
    {
      title: "Date de création",
      value: moment(item.createdDate).format("DD/MM/YYYY"),
    },
    { title: "Adresse", value: item.address },
    { title: "Président", value: getFN(item.president) },
    { title: "Site web", value: item.webSite },
    { title: "Description", value: item.description },
    {
      title: "Dernière modification",
      value: moment(item.updatedAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Modifiée par",
      value: getFN(item.updatedBy),
    },
  ];

  return (
    <div>
      <div className="row" style={{ marginBottom: 10 }}>
        <div className="col-md-12">
          {hasAccess(["fede.update"]) && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title={"Modifier"}
              onClick={() => {
                navigate(`/dashboard/federations/update/${item.slug}`);
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
        </div>
      </div>
      <DataList data={DATA} />
    </div>
  );
}
