import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { ResetPasswordFormData } from "../../types";
import { AuthApi } from "../../utils/api/auth.api";
import { COLORS } from "../../utils/constants";
import { useFormData } from "../../utils/form";

const ResetPassword = () => {
  const [params] = useSearchParams();
  const [exec, { isLoading }] = AuthApi.useResetPasswordMutation();
  const navigate = useNavigate();
  const { onSubmitForm, setErrors, register } =
    useFormData<ResetPasswordFormData>(
      {
        email: params.get("email") || "",
        code: "",
        password: "",
        passwordConfirm: "",
      },
      (Yup) => ({
        email: Yup.string().required().label("Email"),
        code: Yup.string().required().label("Code"),
        password: Yup.string().required().label("Mot de passe"),
        passwordConfirm: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe ne correspondent pas"
        ),
      })
    );

  const onSubmit = React.useCallback(async (data: ResetPasswordFormData) => {
    const res = await exec(data);

    if ("error" in res) {
      const error = res.error as any;
      setErrors({
        code:
          error.data?.error ||
          "Veuillez vous assurer d'entrer les bonnes données",
      });
    } else {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.primary,
        confirmButtonColor: COLORS.primary,
        title: "Votre mot de passe a été modifié avec succès",
      });
      navigate("/auth/se-connecter");
    }
  }, []);

  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="block-content">
              <div className="grid-box">
                <div className="contact-form-box">
                  <div className="title-section">
                    <h1>
                      <span>Mot de passe oublié</span>
                    </h1>
                  </div>
                  <form id="contact-form" onSubmit={onSubmitForm(onSubmit)}>
                    <div className="row">
                      <Input
                        type="email"
                        required
                        label="Email"
                        containerClass="col-md-12"
                        {...register("email")}
                      />

                      <Input
                        containerClass="col-md-12"
                        type="text"
                        {...register("code")}
                        placeholder={"Code"}
                        label={"Code"}
                      />

                      <Input
                        containerClass="col-md-12"
                        type="password"
                        {...register("password")}
                        placeholder={"Nouveau mot de passe"}
                        label={"Nouveau mot de passe"}
                      />
                      <Input
                        type="password"
                        containerClass="col-md-12"
                        {...register("passwordConfirm")}
                        placeholder={"Confirmer mot de passe"}
                        label={"Confirmer mot de passe"}
                      />
                    </div>
                    <Button
                      title="Envoyer"
                      icon={<i className="fa fa-paper-plane" />}
                      type="submit"
                      id="submit-contact"
                      loading={isLoading}
                    />
                    &nbsp;&nbsp;
                    <span>
                      <Link to="/auth/se-connecter">Se connecter</Link>
                    </span>
                  </form>
                </div>
              </div>

              {/* <GoogleAds /> */}
            </div>
          </div>

          {/* <div className="col-sm-4">
            <SideBar />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
