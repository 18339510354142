import { Match } from "../../../types";
import { useQuery } from "../../../utils/api/common.api";
import { MatchApi, MatchSelectQuery } from "../../../utils/api/match.api";
import { useMatchFilter } from "../../../utils/hooks";
import { DisplayMatches } from "../competitions/matches/match-table";
import BreadCrumb from "../components/BreadCrumb";

const MatchesAValider = () => {
  const defaultFilter: MatchSelectQuery = {
    status: "Terminé",
    validated: false,
  };
  const filter = useMatchFilter();
  const query = { ...filter, ...defaultFilter };

  const props = useQuery<Match, MatchSelectQuery>(
    MatchApi.useSelectQuery,
    query
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Matches"
        subTitle="Score à valider"
        items={[
          { title: "Home", route: "/dashboard" },
          { title: "Score à valider" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-warning no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Score à valider</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <DisplayMatches
                    data={props.data}
                    isLoading={props.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MatchesAValider;
