import {
  TableHeader,
  TableWithoutPagination,
  UpdateButton,
} from "../../../components/Table";
import { Role } from "../../../types";
import { useQuery } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";
import BreadCrumb from "../components/BreadCrumb";

const Roles = () => {
  const props = useQuery<Role, {}>(UserApi.useRolesQuery, {});

  const headers: TableHeader<Role>[] = [
    { name: "name", title: "Nom" },

    {
      name: "permissions",
      title: "Permissions",
      formatter(item) {
        return item.permissions.length;
      },
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <UpdateButton
            route={`/dashboard/roles-et-permissions/${item.id}`}
            state={item}
          />
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Roles & Permissions"
        subTitle="Liste des roles"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Liste des roles" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des roles</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <TableWithoutPagination
                    {...props}
                    headers={headers}
                    keyField="id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Roles;
