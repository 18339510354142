// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Phase, PhaseFormData } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";

export type PhaseQuery = CommonQuery & {
  competition?: number;
};
// Define a service using a base URL and expected endpoints
export const PhaseApi = createApi({
  tagTypes: ["phases", "phaseItem"],
  reducerPath: "PhaseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    phases: builder.query<PaginationResults<Phase>, PhaseQuery>({
      query: (query) => ({
        url: QueryUrl(`/phases/`, query),
        method: "GET",
      }),
      providesTags: ["phases"],
    }),
    phaseById: builder.query<Phase, string | number>({
      query: (id) => ({
        url: `/phases/${id}/`,
        method: "GET",
      }),
      providesTags: ["phaseItem"],
    }),

    maj: builder.mutation<
      Phase,
      { id?: number; data: FormData | PhaseFormData }
    >({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/phases/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/phases/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["phases", "phaseItem"],
    }),
    delete: builder.mutation<Phase, number>({
      query: (id) => ({
        url: `/phases/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["phases", "phaseItem"],
    }),
  }),
});

export function usePhaseFromLocation() {
  const location = useLocation();
  const { phaseId } = useParams<{ phaseId: string }>();
  const [fetch, { data }] = PhaseApi.useLazyPhaseByIdQuery();
  const state = location.state;
  const [item, setItem] = useState<Phase>(
    state && "competition" in state ? state : undefined
  );

  useEffect(() => {
    if (phaseId) fetch(phaseId);
  }, [phaseId]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
