import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { useUser } from "../../../redux/store";

import { imageFormatter } from "../../../components/Table";
import { User } from "../../../types";
import BreadCrumb from "../components/BreadCrumb";
import { AthleteForm, useCreateAthlete } from "./create-athlete";

type ProfileAthleteProps = {
  item: User;
};

const ProfileAthleteComponent = ({ item }: ProfileAthleteProps) => {
  const navigate = useNavigate();

  const {
    form,
    isLoading,
    onChange,
    onSubmit,
    onSubmitForm,
    register,
    fede,
    fedes,
    equipes,
    fedeId,
  } = useCreateAthlete(() => {
    navigate(-1);
  }, item);

  return (
    <div className="main-page">
      <BreadCrumb
        title="Profil"
        subTitle={"Modification de profil"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Modification de profil",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - Profil</h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12 text-center mb-15">
                        {imageFormatter(item, 200, true)}
                      </div>
                    </div>
                    <AthleteForm
                      register={register}
                      isIndu={fede?.type === "Individuel"}
                      onAdChange={onChange}
                      adval={form.address}
                      fedeId={fedeId}
                      fedes={fedes}
                      equipes={equipes}
                      containerClass="form-group"
                      inputClass="form-control"
                      profile
                    />

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default function ProfileAthlete() {
  const user = useUser();

  return <ProfileAthleteComponent item={user} />;
}
