import { useState } from "react";
import { CompetitionApi } from "../../utils/api/competition.api";
import { FedesApi } from "../../utils/api/fedes.api";
import { UserApi } from "../../utils/api/user.api";
import AthleteItem from "./PostWidgetItem/AthleteItem";
import CompetitionItem from "./PostWidgetItem/CompetitionItem";
import FederationItem from "./PostWidgetItem/FederationItem";

const PostsWidget = () => {
  const [active, setActive] = useState(0);
  return (
    <div className="widget tab-posts-widget">
      <ul className="nav nav-tabs" id="myTab">
        <li className={active === 0 ? "active" : ""}>
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActive(0);
            }}
            href="#"
          >
            Nos Derniers Athlètes
          </a>
        </li>
        <li className={active === 1 ? "active" : ""}>
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActive(1);
            }}
            href="#"
          >
            Dernières Compétitions
          </a>
        </li>
        <li className={active === 2 ? "active" : ""}>
          <a
            href="#"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActive(2);
            }}
          >
            Dernières Fédérations
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div
          className={`tab-pane ${active === 0 ? "active" : ""}`}
          id="option1"
        >
          <Athletes />
        </div>

        <div
          className={`tab-pane ${active === 1 ? "active" : ""}`}
          id="option2"
        >
          <Compets />
        </div>
        <div
          className={`tab-pane ${active === 2 ? "active" : ""}`}
          id="option3"
        >
          <Fedes />
        </div>
      </div>
    </div>
  );
};

export default PostsWidget;

function Athletes() {
  const { data } = UserApi.useUsersQuery({ userType: ["Athlète"], limit: 6 });
  return (
    <ul className="list-posts">
      {data?.results?.map((c) => (
        <AthleteItem key={c.id} item={c} />
      ))}
    </ul>
  );
}

function Compets() {
  const { data, isLoading } = CompetitionApi.useCompetitionsQuery({
    limit: 6,
    isValidated: true,
  });
  return (
    <ul className="list-posts">
      {data?.results?.map((c) => (
        <CompetitionItem key={c.id} item={c} />
      ))}
    </ul>
  );
}

function Fedes() {
  const { data, isLoading } = FedesApi.useFederationsQuery({ limit: 6 });
  return (
    <ul className="list-posts">
      {data?.results?.map((c) => (
        <FederationItem key={c.id} item={c} />
      ))}
    </ul>
  );
}
