import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input, { Select } from "../../components/Input";
import {
  TableProps,
  TableWithoutPaginationProps,
} from "../../components/Table";
import { useUser } from "../../redux/store";
import { Subscribe, User } from "../../types";
import { CreateAgentTypes } from "../const-fields";
import { useFormData } from "../form";
import { AppStorage } from "../storage";
import { ApiUrl } from "./URL";
import { EquipeQuery, useEquipeSelect } from "./equipe.api";
import { useFedeSelectItems } from "./fedes.api";
import { useNiveauxsSelect } from "./niveau.api";
import { useSaisonsSelect } from "./saison.api";
import { useSportsSelect } from "./sports.api";
import { SubscribeApi } from "./subscribe.api";

export type CommonQuery = {
  order?: "asc" | "desc";
  orderBy?: string;
  limit?: number;
  page?: number;
  search?: string;
};

export type UserQuery = CommonQuery & {
  userType?: User["userType"][];
  federation?: number | string;
  equipe?: number | string;
};

export type PaginationResults<T> = {
  count?: number;
  nextPage: number | null;
  prevPage: number | null;
  nbPage: number;
  limit: number;
  results: T[];
};

export type AnyQuery<T> = { [key: string]: T };
export function hasFile(data: any) {
  const keys = Object.keys(data);
  return keys.filter((k) => data[k] instanceof File).length > 0;
}
export function prepareFormData(data: any) {
  if (data instanceof FormData) return data;
  const fd = new FormData();
  for (const [k, v] of Object.entries(data) as any) {
    if (typeof v === "boolean") {
      fd.append(k, v ? "1" : "0");
    } else {
      fd.append(k, v);
    }
  }
  return fd;
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = `&${val.map((v) => `${key}=${v}`).join("&")}`;
          if (baseUrl.includes("?")) {
            baseUrl += string;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export function getImage(image: string) {
  if (image?.startsWith("http")) return image;
  if (image) return ApiUrl + "/" + image;
  return "https://ui-avatars.com/api/?name=Pas Image";
}

type FilterType = {
  sport?: boolean;
  niveau?: boolean;
  saison?: boolean;
  federation?: boolean;
  equipe?: EquipeQuery;
  userType?: boolean;
};

type SearchForm = {
  search: string;
  sport: string;
  niveau: string;
  federation: string;
  equipe: string;
  userType: string;
  saison: string;
};
function useRenderSearch(
  setPage?: (page: number) => void,
  filter?: FilterType
) {
  const { data, onChange } = useFormData<SearchForm>(
    {
      niveau: "",
      search: "",
      sport: "",
      federation: "",
      saison: "",
      equipe: "",
      userType: "",
    },
    () => ({})
  );

  const renderSearch = useCallback(() => {
    return (
      <div className="row" id="filter-row">
        <div className="col-md-2">
          <Input
            placeholder={"Rechercher..."}
            label=""
            value={data.search}
            onChange={(e) => {
              if (setPage) {
                setPage(1);
              }
              onChange("search", e.target.value);
            }}
            containerClass="form-group"
            inputClass="form-control form-control-search"
            type="search"
          />
        </div>
        {filter?.saison && (
          <SaisonFilter
            value={data.saison}
            onChange={(saison) => {
              if (setPage) {
                setPage(1);
              }
              onChange("saison", saison);
            }}
          />
        )}
        {filter?.federation && (
          <FederationFilter
            value={data.federation}
            onChange={(federation) => {
              if (setPage) {
                setPage(1);
              }
              onChange("federation", federation);
            }}
          />
        )}
        {filter?.equipe && (
          <EquipeFilter
            query={filter.equipe}
            value={data.equipe}
            onChange={(equipe) => {
              if (setPage) {
                setPage(1);
              }
              onChange("equipe", equipe);
            }}
          />
        )}
        {filter?.sport && (
          <SportFilter
            value={data.sport}
            onChange={(sport) => {
              if (setPage) {
                setPage(1);
              }
              onChange("sport", sport);
            }}
          />
        )}
        {filter?.niveau && (
          <NiveauFilter
            value={data.niveau}
            onChange={(niveau) => {
              if (setPage) {
                setPage(1);
              }
              onChange("niveau", niveau);
            }}
          />
        )}
        {filter?.userType && (
          <UserTypeFilter
            value={data.userType}
            onChange={(userType) => {
              if (setPage) {
                setPage(1);
              }
              onChange("userType", userType);
            }}
          />
        )}
      </div>
    );
  }, [data]);

  const deleteIfEmpty = (data: SearchForm) => {
    const it: any = {};
    for (let [k, v] of Object.entries(data)) {
      if (!!v) it[k] = v;
    }
    return it;
  };

  return {
    renderSearch,
    data: deleteIfEmpty(data),
    onChange,
  };
}

type InputFilterProps = {
  value?: string;
  onChange: (value: string) => void;
  query?: any;
};
function SportFilter({ value, onChange }: InputFilterProps) {
  const sports = useSportsSelect();
  return (
    <div className="col-md-2">
      <Select
        placeholder={"Sport"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label=""
        containerClass="form-group"
        inputClass="form-control"
        options={sports}
      />
    </div>
  );
}

function UserTypeFilter({ value, onChange }: InputFilterProps) {
  return (
    <div className="col-md-2">
      <Select
        placeholder={"Type"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label=""
        containerClass="form-group"
        inputClass="form-control"
        options={CreateAgentTypes.map((c) => ({ label: c, value: c }))}
      />
    </div>
  );
}

function NiveauFilter({ value, onChange }: InputFilterProps) {
  const niveaux = useNiveauxsSelect();
  return (
    <div className="col-md-3">
      <Select
        placeholder={"Catégorie"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label=""
        containerClass="form-group"
        inputClass="form-control"
        options={niveaux}
      />
    </div>
  );
}

function FederationFilter({ value, onChange }: InputFilterProps) {
  const items = useFedeSelectItems();
  return (
    <div className="col-md-3">
      <Select
        placeholder={"Fédération"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label=""
        containerClass="form-group"
        inputClass="form-control"
        options={items}
      />
    </div>
  );
}

function SaisonFilter({ value, onChange }: InputFilterProps) {
  const items = useSaisonsSelect();
  useEffect(() => {
    if (items.length > 0 && !value) {
      const maxId = items.reduce((max, obj) => {
        return (obj.value as number) > max ? (obj.value as number) : max;
      }, -1);
      onChange(String(maxId));
    }
  }, [items.length]);
  return (
    <div className="col-md-2">
      <Select
        placeholder={"Saison"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label=""
        containerClass="form-group"
        inputClass="form-control"
        options={items}
      />
    </div>
  );
}

function EquipeFilter({ value, onChange, query }: InputFilterProps) {
  const items = useEquipeSelect(query.federation);
  return (
    <div className="col-md-2">
      <Select
        placeholder={"Équipe"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label=""
        containerClass="form-group"
        inputClass="form-control"
        options={items}
      />
    </div>
  );
}

export function usePaginationQuery<T, P>(
  query: any,
  params: P,
  filter?: FilterType
): Pick<
  TableProps<T>,
  | "data"
  | "limit"
  | "onLimitChange"
  | "onPageChange"
  | "page"
  | "total"
  | "isLoading"
> & { renderSearch: () => any } {
  const [emptyArray] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const { data: searchQuery, renderSearch } = useRenderSearch(setPage, filter);
  const [limit, setLimit] = useState(
    AppStorage.getItem<number>("defaultLimit", 10) ?? 10
  );
  const {
    data,
    isLoading,
    isFetching,
  }: { data?: PaginationResults<T>; isLoading: boolean; isFetching: boolean } =
    query({
      limit,
      page,
      ...params,
      ...searchQuery,
    } as CommonQuery);

  return {
    data: data?.results ?? emptyArray,
    isLoading: isFetching || isLoading,
    limit,
    page,
    onLimitChange: setLimit,
    onPageChange: setPage,
    total: data?.count ?? 0,
    renderSearch,
  };
}

export function useQuery<T, P>(
  query: any,
  params: P,
  filter?: FilterType
): Pick<TableWithoutPaginationProps<T>, "data" | "isLoading"> & {
  renderSearch: () => any;
} {
  const [emptyArray] = useState<T[]>([]);
  const { data: searchQuery, renderSearch } = useRenderSearch(
    undefined,
    filter
  );

  const {
    data,
    isLoading,
    isFetching,
  }: { data?: T[]; isLoading: boolean; isFetching: boolean } = query({
    ...searchQuery,
    ...params,
  } as CommonQuery);

  return {
    data: data ?? emptyArray,
    isLoading: isFetching || isLoading,
    renderSearch,
  };
}

export function getFN(u?: User) {
  if (u) return `${u.prenom} ${u.nom}`;
  return "";
}

export function getId<T extends { id: number }>(item?: T | number) {
  if (typeof item === "number") return item;
  return item?.id;
}

export function useLoadMoreQuery<T extends { id: number }, P>(
  query: any,
  params: P
) {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<T[]>([]);

  const {
    data,
    isLoading,
    isFetching,
  }: { data?: PaginationResults<T>; isLoading: boolean; isFetching: boolean } =
    query({
      page,
      ...params,
    } as CommonQuery);

  useEffect(() => {
    if (data?.results) {
      setItems((old) => {
        const items: T[] = [...old];
        for (let i of data.results) {
          const index = items.findIndex((o) => o.id === i.id);
          if (index === -1) items.push(i);
          else items[index] = i;
        }
        return items;
      });
    }
  }, [data]);
  const loading = isFetching || isLoading;

  const fetchMore = () => {
    if (loading) return;
    setPage((old) => old + 1);
  };
  const renderButton = () => {
    if (!data?.nextPage) return null;

    return (
      <div className="center-button">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            fetchMore();
          }}
        >
          <i className="fa fa-refresh"></i>{" "}
          {loading ? "Chargements..." : "Voir plus"}
        </a>
      </div>
    );
  };

  return {
    data: items,
    isLoading: loading,
    renderButton,
  };
}

type FollowButtonProps = {
  field: keyof Subscribe;
  subscriberId: number;
};

export function FollowButton({ field, subscriberId }: FollowButtonProps) {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: count = 0 } = SubscribeApi.useCountQuery({
    [field]: subscriberId,
  });
  if (!user) {
    return (
      <SubsButton
        count={count}
        subscribed={false}
        onClick={() => {
          navigate(`/auth/se-connecter?next=${location.pathname}`);
        }}
      />
    );
  }

  return (
    <SubscribeButton field={field} subscriberId={subscriberId} count={count} />
  );
}
export function SubscribeButton({
  field,
  subscriberId,
  count,
}: FollowButtonProps & { count: number }) {
  const [subscribed, setSubscribed] = useState(false);
  const [toggle, { isLoading: toggeling }] = SubscribeApi.useToggleMutation();
  const { data, isLoading, isFetching } = SubscribeApi.useMeQuery({
    [field]: subscriberId,
  });

  useEffect(() => {
    if (data) {
      setSubscribed(!!data.find((i) => !!i[field]));
    } else {
      setSubscribed(false);
    }
  }, [data]);

  const loading = isLoading || isFetching || toggeling;
  if (loading) {
    return <button className="follow-button">Chargement...</button>;
  }
  return (
    <SubsButton
      onClick={async () => {
        toggle({ [field]: subscriberId });
      }}
      subscribed={subscribed}
      count={count}
    />
  );
}

type SubsButtonProps = {
  onClick: () => void;
  subscribed: boolean;
  count: number;
};
function SubsButton({ onClick, count, subscribed }: SubsButtonProps) {
  return (
    <button onClick={onClick} className="follow-button">
      {subscribed ? "Abonné" : "Suivre"}
      <span
        style={{ marginLeft: 5, display: "inline-block" }}
        className="fa fa-user"
      ></span>
      <span style={{ marginLeft: 2, display: "inline-block" }}>{count}</span>
    </button>
  );
}
