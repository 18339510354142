import Table, {
  DeleteButton,
  TableHeader,
  UpdateButton,
} from "../../../components/Table";
import { Saison } from "../../../types";
import { CommonQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { SaisonApi } from "../../../utils/api/saison.api";
import BreadCrumb from "../components/BreadCrumb";

const Saisons = () => {
  const props = usePaginationQuery<Saison, CommonQuery>(
    SaisonApi.useSaisonsQuery,
    {}
  );
  const [onDelete] = SaisonApi.useDeleteMutation();

  const headers: TableHeader<Saison>[] = [
    { name: "name", title: "Nom" },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <UpdateButton
            route={`/dashboard/saisons/update/${item.slug}`}
            state={item}
            permission="saisons.update"
          />
          <DeleteButton
            id={item.id}
            permission="saisons.delete"
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Sport supprimé avec succès"}
          />
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Saisons"
        subTitle="Liste des saisons"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Toutes les saisons" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des saisons ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Saisons;
