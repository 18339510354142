import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import Table, {
  TableHeader,
  TableProps,
  TableWithoutPagination,
  TableWithoutPaginationProps,
  ViewButton,
  imageFormatter,
} from "../../../../components/Table";

import Input from "../../../../components/Input";
import { Competition, Participe } from "../../../../types";
import { getFN } from "../../../../utils/api/common.api";

type ParticipeTableProps = Omit<
  TableProps<Participe>,
  "keyField" | "headers"
> & {
  type: Competition["type"];
};

type SelectParticipeTableProps = Omit<
  TableWithoutPaginationProps<Participe>,
  "keyField" | "headers"
> & {
  type: Competition["type"];
};

const ParticipeTable = ({ type, ...props }: ParticipeTableProps) => {
  let headers: TableHeader<Participe>[] = [
    {
      name: "athlete",
      title: "Image",
      formatter: (item) => imageFormatter(item.equipe),
    },
    { name: "equipe", title: "Nom", formatter: (item) => item.equipe.nom },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/equipes/${item.equipe.slug}`}
            permission="equipes.view"
          />
        </>
      ),
    },
  ];
  if (type === "Individuel") {
    headers = [
      {
        name: "equipe",
        title: "Image",
        formatter: (item) => imageFormatter(item.athlete),
      },
      {
        name: "athlete",
        title: "Nom",
        formatter: (item) => getFN(item.athlete),
      },
      {
        name: "id",
        title: "Actions",
        formatter: (item) => (
          <>
            <ViewButton
              route={`/dashboard/athletes/${item.athlete.id}`}
              permission="athletes.view"
            />
          </>
        ),
      },
    ];
  }
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default ParticipeTable;

export const ChoiceParticipeTable = ({
  selections,
  setSelections,
  type,
  ...props
}: SelectParticipeTableProps & {
  selections: number[];
  setSelections: any;
}) => {
  const action: TableHeader<Participe> = {
    name: "id",
    title: "Selections",
    formatter: (item) => {
      const checked = selections.includes(item.id);
      return (
        <div className="text-center">
          <Input
            checked={checked}
            onChange={(e) => {
              if (checked) {
                setSelections((old: number[]) =>
                  old.filter((f) => f !== item.id)
                );
              } else {
                setSelections((old: number[]) => [...old, item.id]);
              }
            }}
            type="checkbox"
          />
        </div>
      );
    },
  };
  let headers: TableHeader<Participe>[] = [
    {
      name: "athlete",
      title: "Image",
      formatter: (item) => imageFormatter(item.equipe),
    },
    { name: "equipe", title: "Nom", formatter: (item) => item.equipe.nom },
    action,
  ];
  if (type === "Individuel") {
    headers = [
      {
        name: "equipe",
        title: "Image",
        formatter: (item) => imageFormatter(item.athlete),
      },
      {
        name: "athlete",
        title: "Nom",
        formatter: (item) => getFN(item.athlete),
      },
      action,
    ];
  }

  return (
    <TableWithoutPagination keyField={"id"} {...props} headers={headers} />
  );
};
