import { Auth } from "../../types";
import { AppStorage } from "../storage";

export const prepareHeaders = async (headers: Headers) => {
  const auth = AppStorage.getItem<Auth>("auth");
  if (auth?.access_token) {
    headers.set("Authorization", `Bearer ${auth?.access_token}`);
  }
  return headers;
};
