import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector, useUser } from "../redux/store";
import { Competition, Match, Participe, User } from "../types";
import { UserQuery, getFN, usePaginationQuery } from "./api/common.api";
import { CompetitionApi, CompetitionQuery } from "./api/competition.api";
import { MatchSelectQuery } from "./api/match.api";
import { UserApi } from "./api/user.api";
export const useUserToValidate = () => {
  const user = useUser();

  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useToValidateQuery,
    {
      federation: user?.federation?.id,
      equipe: user?.equipe?.id,
      userType: ["Athlète"],
    }
  );
  return props;
};

export const useCompetitionToValidate = () => {
  const user = useUser();

  const props = usePaginationQuery<Competition, CompetitionQuery>(
    CompetitionApi.useToValidateQuery,
    {
      federation: user?.federation?.id,
    }
  );
  return props;
};

export function getCompetTypeLabel(type?: Competition["type"] | "") {
  return type === "Individuel" ? "athlète" : "équipe";
}
export function getParticipeLabel(item: Participe) {
  if (item.athlete) return getFN(item.athlete);
  return item.equipe.nom;
}

export function getMatchTitle(item: Match) {
  return `${getParticipeLabel(item.participeDom)} vs ${getParticipeLabel(
    item.participeExt
  )}`;
}

export function useTabsControl(initTab = 0) {
  const [params, setParms] = useSearchParams();
  const [active, setActive] = useState(initTab);
  const onTabChange = (value: number) => {
    params.set("tab", String(value));
    setParms(params);
    setActive(value);
  };
  useEffect(() => {
    let prevTab = parseInt(params.get("tab") ?? String(initTab), 10);
    if (prevTab && !isNaN(prevTab)) {
      onTabChange(prevTab);
    }
  }, []);

  return { active, setActive: onTabChange };
}

export function useMatchFilter() {
  const user = useUser();

  const filter: MatchSelectQuery = {
    federation: user?.federation?.id,
    equipe: user?.equipe?.id,
  };

  if (user.userType === "Juge") filter.juge = user.id;
  if (user.userType === "Athlète" && !user.equipe) {
    filter.athlete = user.id;
  }
  if (user.userType === "Inspecteur") filter.inspecteur = user.id;

  return filter;
}

export type SocialItem = {
  title: string;
  icon: any;
  link: string;
  type: "social" | "contact";
};

export function useAppConfig() {
  const config = useAppSelector((s) => s.app_config);

  return [
    {
      title: "Facebook",
      link: config.facebook,
      type: "social",
      icon: <span className="fa fa-facebook"></span>,
    },
    {
      title: "Instagram",
      type: "social",
      link: config.instagram,
      icon: <span className="fa fa-instagram"></span>,
    },
    {
      title: "LinkedIn",
      type: "social",
      link: config.linkedin,
      icon: <span className="fa fa-linkedin"></span>,
    },
    {
      title: "Twitter",
      type: "social",
      link: config.twitter,
      icon: <span className="fa fa-twitter"></span>,
    },
    {
      title: "Youtube",
      type: "social",
      link: config.youtube,
      icon: <span className="fa fa-youtube"></span>,
    },
    {
      title: "Email",
      type: "contact",
      link: config.email,
      icon: <span className="fa fa-envelope"></span>,
    },
    {
      title: "Téléphone",
      type: "contact",
      link: config.telephone,
      icon: <span className="fa fa-phone"></span>,
    },
    {
      title: "Adresse",
      type: "contact",
      link: config.address,
      icon: <span className="fa fa-location-arrow"></span>,
    },
  ] as SocialItem[];
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useIsDesktop() {
  const { width } = useWindowSize();
  return width && width > 767;
}
