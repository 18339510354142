import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input, { GoogleInput, Select } from "../../../components/Input";
import { useUser } from "../../../redux/store";

import { GooglePlaceData } from "../../../components/GooglePlaceInput";
import { LoaderPage } from "../../../components/Loader";
import { validateImg, validatePdf } from "../../../components/Table";
import { SelectOptions, User, UserFormData } from "../../../types";
import { useEquipeSelect } from "../../../utils/api/equipe.api";
import { FedeSelect, useFedeSelectItems } from "../../../utils/api/fedes.api";
import { useNiveauxsSelect } from "../../../utils/api/niveau.api";
import { useSportsSelect } from "../../../utils/api/sports.api";
import { UserApi, useUserFromLocation } from "../../../utils/api/user.api";
import { Sexes } from "../../../utils/const-fields";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import BreadCrumb from "../components/BreadCrumb";

type CreateAthleteProps = {
  item?: User;
};

const CreateAthlete = ({ item }: CreateAthleteProps) => {
  const navigate = useNavigate();

  const {
    form,
    isLoading,
    onChange,
    onSubmit,
    onSubmitForm,
    register,
    fede,
    fedes,
    equipes,
    fedeId,
  } = useCreateAthlete(() => {
    navigate(-1);
  }, item);

  return (
    <div className="main-page">
      <BreadCrumb
        title="Athlète"
        subTitle={
          item
            ? "Formulaire de modification d'athlète"
            : "Formulaire de création d'athlète"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard/presidents",
          },
          {
            title: item ? "Modification d'athlète" : "Création d'athlète",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="panel border-primary no-border border-3-top">
            <div className="panel-heading">
              <div className="panel-title">
                <h5>
                  GESTION ADMIN - {item ? "Modification" : "Création"} d'athlète{" "}
                </h5>
              </div>
            </div>
            <div className="panel-body">
              <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                <AthleteForm
                  register={register}
                  isIndu={fede?.type === "Individuel"}
                  onAdChange={onChange}
                  adval={form.address}
                  equipes={equipes}
                  fedes={fedes}
                  fedeId={fedeId}
                  containerClass="form-group"
                  inputClass="form-control"
                />
                {!item && (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <Input
                        placeholder={"Mot de passe"}
                        label="Mot de passe"
                        {...register("password")}
                        containerClass="form-group"
                        inputClass="form-control"
                        type="password"
                      />
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <Input
                        placeholder={"Confirmer mot de passe"}
                        label="Confirmer mot de passe"
                        {...register("password_confirm")}
                        containerClass="form-group"
                        inputClass="form-control"
                        type="password"
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12">
                    <div className="btn-group pull-right mt-10" role="group">
                      <button
                        type="reset"
                        onClick={() => navigate(-1)}
                        className="btn btn-gray btn-wide"
                      >
                        <i className="fa fa-times"></i>Annuler
                      </button>
                      <Button
                        type="submit"
                        className="btn bg-black btn-wide"
                        title="Enregistrer"
                        loading={isLoading}
                        icon={<i className="fa fa-arrow-right" />}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateAthlete;

export function UpdateAthlete() {
  const { item } = useUserFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateAthlete item={item} />;
}

type AthleteFormProps = {
  register: any;
  addtionalFields?: any;
  isIndu: boolean;
  fedeId?: number;
  adval?: string;
  onAdChange: (data: GooglePlaceData) => void;
  containerClass?: string;
  inputClass?: string;
  profile?: boolean;
  equipes: SelectOptions;
  fedes: FedeSelect[];
};

export function AthleteForm({
  isIndu,
  register,
  addtionalFields,
  fedeId,
  adval,
  onAdChange,
  containerClass,
  inputClass,
  profile,
  equipes,
  fedes,
}: AthleteFormProps) {
  const sports = useSportsSelect();
  const niveaux = useNiveauxsSelect();
  const { value: _1, ...docProps } = register("document");
  const { value: _2, ...imgProps } = register("image");
  const { value: _3, ...cardProps } = register("idCard");
  const adProps = register("address");
  return (
    <>
      {/* <h5 className="underline mt-n">Informations</h5> */}
      <div className="row">
        {!profile && (
          <>
            {!fedeId && (
              <div className="col-md-6 col-xs-12">
                <Select
                  placeholder={"Choisir une fédération"}
                  {...register("federation")}
                  label="Fédération"
                  containerClass="form-group"
                  inputClass="form-control"
                  options={fedes}
                />
              </div>
            )}
            {addtionalFields && addtionalFields}
            {!isIndu && (
              <div className="col-md-6 col-xs-12">
                <Select
                  placeholder={"Choisir une équipe"}
                  {...register("equipe")}
                  label="Equipe"
                  containerClass={containerClass}
                  inputClass={inputClass}
                  options={equipes}
                />
              </div>
            )}
          </>
        )}

        <div className="col-md-6 col-xs-12">
          <Select
            placeholder={"Choisir le sexe"}
            {...register("sexe")}
            label="Sexe"
            containerClass={containerClass}
            inputClass={inputClass}
            options={Sexes.map((s) => ({
              label: s,
              value: s,
            }))}
          />
        </div>

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Prénom"}
            label="Prénom"
            {...register("prenom")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="text"
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Nom"}
            label="Nom"
            {...register("nom")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="text"
          />
        </div>

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Email"}
            label="Email"
            {...register("email")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="email"
          />
        </div>

        {!profile && (
          <div className="col-md-6 col-xs-12">
            <Select
              placeholder={"Choisir une catégorie"}
              {...register("niveau")}
              label="Catégorie"
              containerClass={containerClass}
              inputClass={inputClass}
              options={niveaux}
            />
          </div>
        )}

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Téléphone"}
            label="Téléphone"
            {...register("telephone")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="text"
          />
        </div>
        {!profile && (
          <div className="col-md-6 col-xs-12">
            <Select
              placeholder={"Choisir le sport"}
              {...register("sport")}
              label="Sport"
              containerClass={containerClass}
              inputClass={inputClass}
              options={sports}
            />
          </div>
        )}
        <div className="col-md-6 col-xs-12">
          <GoogleInput
            {...adProps}
            value={adval}
            label="Adresse"
            placeholder="Adresse"
            containerClass={containerClass}
            inputClass={inputClass}
            onChange={(data) => {
              onAdChange({
                address: data.address,
                latitude: data.latitude,
                longitude: data.longitude,
                ville: data.ville,
              });
            }}
          />
        </div>

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Date de naissance"}
            label="Date de naissance"
            {...register("birthDate")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="date"
          />
        </div>

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Nationalité"}
            label="Nationalité"
            {...register("nationality")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="text"
          />
        </div>

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Noms de famille"}
            label="Noms de famille"
            {...register("nomsFamille")}
            containerClass={containerClass}
            inputClass={inputClass}
            type="text"
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Photo d'identité"}
            label="Photo d'identité"
            {...imgProps}
            containerClass={containerClass}
            inputClass={inputClass}
            type="file"
          />
        </div>

        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Licence"}
            label="Licence"
            {...docProps}
            containerClass={containerClass}
            inputClass={inputClass}
            type="file"
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Input
            placeholder={"Pièces d’identité"}
            label="Pièces d’identité"
            {...cardProps}
            containerClass={containerClass}
            inputClass={inputClass}
            type="file"
          />
        </div>
      </div>
    </>
  );
}

export function useCreateAthlete(
  onDone: () => void,
  item?: User,
  successsMessage?: string,
  isAbonne?: boolean
) {
  const user = useUser();
  const fedeId = item?.federation?.id || user?.federation?.id;
  const fedes = useFedeSelectItems();

  const validateAthlete = (yup: any) => ({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prénom"),
    email: yup.string().email().required().label("Email"),
    telephone: yup.string().required().label("Téléphone"),
    address: yup.string().required().label("Adresse"),
    birthDate: yup.string().required().label("Date de naissance"),
    niveau: yup.string().required().label("Catégorie"),
    idCard: item ? validateImg(yup).notRequired() : validateImg(yup).required(),
    federation: fedeId
      ? yup.string().notRequired()
      : yup.string().required().label("Fédération"),
    document: item
      ? validatePdf(yup).notRequired()
      : validatePdf(yup).required(),
    image: item ? validateImg(yup).notRequired() : validateImg(yup).required(),
    nomsFamille: yup.string().required().label("Noms de famille"),
    nationality: yup.string().required().label("Nationalité"),
    sexe: yup.string().required().label("Sexe"),
    password: !item
      ? yup.string().required().label("Mot de passe")
      : yup.string().notRequired(),
    password_confirm: !item
      ? yup
          .string()
          .oneOf(
            [yup.ref("password"), null],
            "Les mots de passe ne correspondent pas"
          )
      : yup.string().notRequired(),
  });
  const validateAbonne = (yup: any) => ({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prénom"),
    email: yup.string().email().required().label("Email"),
    telephone: yup.string().required().label("Téléphone"),
    password: !item
      ? yup.string().required().label("Mot de passe")
      : yup.string().notRequired(),
    password_confirm: !item
      ? yup
          .string()
          .oneOf(
            [yup.ref("password"), null],
            "Les mots de passe ne correspondent pas"
          )
      : yup.string().notRequired(),
  });
  const [exec, { isLoading }] = UserApi.useMajMutation();
  const {
    setErrors,
    errors,
    register,
    onSubmitForm,
    data: form,
    onChange,
  } = useFormData<UserFormData>(
    {
      nom: item?.nom || "",
      prenom: item?.prenom || "",
      email: item?.email || "",
      telephone: item?.telephone || "",
      address: item?.address || "",
      birthDate: item?.birthDate || "",
      nationality: item?.nationality || "",
      sexe: item?.sexe || "",
      latitude: item?.latitude || "",
      longitude: item?.latitude || "",
      niveau: item?.niveau?.id || "",
      nomsFamille: item?.nomsFamille || "",
      sport: item?.sport?.id || "",
      userType: isAbonne ? "Abonné" : "Athlète",
      federation: fedeId || "",
      equipe: item?.equipe?.id || "",
      ...(item ? {} : { password: "", password_confirm: "" }),
    },
    isAbonne ? validateAbonne : validateAthlete
  );

  const equipes = useEquipeSelect(form.federation);
  const fede = fedes.find(
    (f) =>
      parseInt(String(f.value), 10) === parseInt(String(form.federation), 10)
  );

  const onSubmit = React.useCallback(
    async (data: UserFormData) => {
      if (!isAbonne && fede?.type === "Equipe" && !form.equipe) {
        Swal.fire({
          icon: "error",
          iconColor: COLORS.red,
          confirmButtonColor: COLORS.red,
          title: "Choisissez une équipe",
        });
        return;
      }
      if (!form.equipe) {
        if (data instanceof FormData) {
          data.delete("equipe");
        } else {
          data = { ...data };
          delete data.equipe;
        }
      }
      if (!form.niveau) {
        if (data instanceof FormData) {
          data.delete("niveau");
        } else {
          data = { ...data };
          delete data.niveau;
        }
      }
      if (!form.sport) {
        if (data instanceof FormData) {
          data.delete("sport");
        } else {
          data = { ...data };
          delete data.sport;
        }
      }
      if (!form.federation) {
        if (data instanceof FormData) {
          data.delete("federation");
        } else {
          data = { ...data };
          delete data.federation;
        }
      }
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        Swal.fire({
          icon: "error",
          iconColor: COLORS.red,
          confirmButtonColor: COLORS.red,
          title:
            error.data?.message ||
            `Veuillez vous assurer d'entrer les bonnes données`,
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title:
            successsMessage ||
            `Athlète ${item ? "modifiée" : "ajoutée"} avec succès`,
        });
        onDone();
      }
    },
    [item, form, fede?.type]
  );

  return {
    setErrors,
    errors,
    register,
    onSubmitForm,
    form,
    onChange,
    onSubmit,
    isLoading,
    equipes,
    fedes,
    fede,
    isAbonne,
    fedeId,
  };
}
