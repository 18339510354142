import { LoaderPage } from "../../components/Loader";
import { Match } from "../../types";
import { useLoadMoreQuery } from "../../utils/api/common.api";
import {
  MatchApi,
  MatchHistoryQuery,
  useMatchFromLocation,
} from "../../utils/api/match.api";
import MatchItem from "../competition/components/MatchItem";

const MatchCmp = ({ item }: { item: Match }) => {
  return (
    <>
      <div className="container">
        <h1 className="text-center">
          {item.phase.name} - {item.phase.competition.name}
        </h1>
        <ul className="autor-list">
          <MatchItem showCompetName item={item} />
        </ul>

        <MatchHistory item={item} />
      </div>
    </>
  );
};

export default function DetailMatch() {
  const { item } = useMatchFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <MatchCmp item={item} />;
}

function MatchHistory({ item }: { item: Match }) {
  const { participeDom, participeExt } = item;
  const { equipe: equipeDom, athlete: athleteDom } = participeDom;
  const { equipe: equipeExt, athlete: athleteExt } = participeExt;
  const athletes = athleteDom ? [athleteDom.id, athleteExt.id] : undefined;
  const equipes = equipeDom ? [equipeDom.id, equipeExt.id] : undefined;
  const { data, isLoading, renderButton } = useLoadMoreQuery<
    Match,
    MatchHistoryQuery
  >(MatchApi.useHistoryQuery, { athletes, equipes });
  if (data.length <= 1) return null;
  return (
    <>
      <h1 className="text-center">Historique des confrontations</h1>
      <ul className="autor-list">
        {data.map((c) => (
          <MatchItem item={c} key={c.id} />
        ))}
      </ul>
      {renderButton()}
    </>
  );
}
