import SideBar from "../../components/SideBar/SideBar";
import { AthletesList } from "../equipe/detailEquipe";

const Athlete = () => {
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="grid-box">
                  <div className="title-section">
                    <h1>
                      <span>Nos athlètes </span>
                    </h1>
                  </div>

                  <AthletesList showTitle={false} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Athlete;
