import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { Select } from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { Competition, Groupe, Match, Participe, Phase } from "../../../types";
import {
  getFN,
  usePaginationQuery,
  useQuery,
} from "../../../utils/api/common.api";
import {
  CompetitionApi,
  useCompetitionFromLocation,
} from "../../../utils/api/competition.api";
import { GroupeApi, GroupeSelectQuery } from "../../../utils/api/groupe.api";
import { MatchApi, MatchSelectQuery } from "../../../utils/api/match.api";
import { ParticipeApi, ParticipeQuery } from "../../../utils/api/participe.api";
import { PhaseApi, PhaseQuery } from "../../../utils/api/phase.api";
import { getCompetTypeLabel, useTabsControl } from "../../../utils/hooks";
import { usePermissions } from "../../../utils/permissions";
import { DataItem, DataList } from "../athletes/detail-athlete";
import BreadCrumb from "../components/BreadCrumb";
import ClassmentsTable from "./matches/classments";
import ParticipeTable from "./participes/participe-table";
import PhaseTable from "./phases/phase-table";

type DetailCompetitonProps = {
  item: Competition;
};

const DetailCompetitonComponent = ({ item }: DetailCompetitonProps) => {
  const { active, setActive } = useTabsControl(0);
  const [isChamptionat, setIsChampionat] = useState(false);

  const DATA = [
    {
      title: "Infos",
      component: Details,
    },
    {
      title: item.type === "Equipe" ? "Equipes" : "Athlètes",
      component: ListEquipes,
    },
    {
      title: isChamptionat ? "Journées" : "Phases",
      component: Phases,
    },
    {
      title: "Vainqueur",
      component: Winner,
    },
  ];

  if (isChamptionat) {
    DATA.splice(3, 0, {
      title: "Classements",
      component: Classements,
    });
  }

  return (
    <div className="main-page">
      <BreadCrumb
        title="Compétitions"
        subTitle={"Détail compétition"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: item.name,
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - détails compétition</h5>
                  </div>
                </div>
                <div className="panel-body">
                  <ul className="tabs">
                    {DATA.map((ut, index) => (
                      <li
                        role="presentation"
                        className={index === active ? "active" : ""}
                      >
                        <a
                          href={`#${ut.title}`}
                          onClick={() => setActive(index)}
                          aria-controls={ut.title}
                          role="tab"
                          style={{ fontSize: 12 }}
                          data-toggle="tab"
                        >
                          {ut.title}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <div className="tabs-container">
                    {DATA.map(({ title, component: Component }, index) => (
                      <div
                        role="tabpanel"
                        className={`${index === active ? "active" : ""}`}
                        id={title}
                        key={title}
                      >
                        <Component
                          setIsChampionat={setIsChampionat}
                          item={item}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
type Props = { item: Competition; setIsChampionat: (ok: boolean) => void };
function Details({ item }: Props) {
  const label = getCompetTypeLabel(item.type);

  const data: DataItem[] = [
    { title: "Nom", value: item.name },
    { title: "Nom court", value: item.short_name },
    { title: "Fédération", value: item.federation?.nom },
    { title: "Inspecteur", value: getFN(item.inspecteur) },
    { title: "Catégorie", value: item.niveau?.name },
    { title: `Nombre d'${label}`, value: item.nbreEquipe },
    { title: `Sexe`, value: item.sexe },
    { title: "Sport", value: item.sport?.name },
    { title: "Saison", value: item.saison?.name },
    {
      title: "Date début",
      value: moment(item.startDate).format("DD/MM/YYYY"),
    },
    {
      title: "Date fin",
      value: moment(item.endDate).format("DD/MM/YYYY"),
    },
    {
      title: "tirs aux but?",
      value: item.tirsAuxBut ? "Oui" : "Non",
    },
    {
      title: "Prolongation?",
      value: item.prolongation ? "Oui" : "Non",
    },
    {
      title: "Dernière modification",
      value: moment(item.updatedAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Modifiée par",
      value: getFN(item.updatedBy),
    },
  ];
  return <DataList data={data} />;
}

export default function DetailCompetiton() {
  const { item } = useCompetitionFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <DetailCompetitonComponent item={item} />;
}

const Phases = ({ item, setIsChampionat }: Props) => {
  const { hasAccess } = usePermissions();
  const navigate = useNavigate();

  const props = usePaginationQuery<Phase, PhaseQuery>(PhaseApi.usePhasesQuery, {
    competition: item.id,
  });
  const isChamptonat = props.data && props.data?.[0]?.type === "Championat";

  useEffect(() => {
    setIsChampionat(isChamptonat);
  }, [isChamptonat]);

  return (
    <div className="row">
      {hasAccess(["competitions.create"]) && (
        <div className="col-md-12 text-right">
          {isChamptonat && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title="Ajouter une journée"
              onClick={() => {
                navigate(
                  `/dashboard/competitions/${item.slug}/phases/create?type=Championat`
                );
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
          {!isChamptonat && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title="Ajouter une phase"
              onClick={() => {
                navigate(`/dashboard/competitions/${item.slug}/phases/create`);
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
        </div>
      )}
      <div className="col-md-12">
        <PhaseTable {...props} competition={item} />
      </div>
    </div>
  );
};

const ListEquipes = ({ item }: Props) => {
  const navigate = useNavigate();
  const { hasAccess } = usePermissions();
  const props = usePaginationQuery<Participe, ParticipeQuery>(
    ParticipeApi.useParticipesQuery,
    { competition: item.id }
  );
  return (
    <div className="row">
      {hasAccess(["competitions.create"]) && (
        <div className="col-md-12 text-right">
          {item.type === "Equipe" && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title="Choisir les équipes"
              onClick={() => {
                navigate(`/dashboard/competitions/${item.slug}/equipes/select`);
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
          {item.type === "Individuel" && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title="Choisir les athlètes"
              onClick={() => {
                navigate(
                  `/dashboard/competitions/${item.slug}/athletes/select`
                );
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
        </div>
      )}
      <div className="col-md-12">
        <ParticipeTable type={item.type} {...props} />
      </div>
    </div>
  );
};

export const Classements = ({ item }: Props) => {
  const { data: matches, isLoading: loading1 } = useQuery<
    Match,
    MatchSelectQuery
  >(MatchApi.useSelectQuery, {
    competition: item.id,
  });

  const { isLoading: loading2, data: participes } = useQuery<
    Participe,
    ParticipeQuery
  >(ParticipeApi.useParticipesSelectQuery, { competition: item.id });

  return (
    <ClassmentsTable
      matches={matches}
      participes={participes}
      isLoading={loading1 || loading2 || false}
      competition={item}
    />
  );
};

export const Groupes = ({
  item,
  phaseId,
}: {
  phaseId?: number;
  item: Competition;
}) => {
  type Data = { matches: Match[]; groupe: Groupe; participes: Participe[] };
  const [data, setData] = useState<Data[]>([]);

  const { data: matches, isLoading: loading1 } = useQuery<
    Match,
    MatchSelectQuery
  >(MatchApi.useSelectQuery, {
    competition: item.id,
  });

  const { isLoading: loading2, data: participes } = useQuery<
    Participe,
    ParticipeQuery
  >(ParticipeApi.useParticipesSelectQuery, { competition: item.id });

  const { data: groupes, isLoading: loading3 } = useQuery<
    Groupe,
    GroupeSelectQuery
  >(GroupeApi.useSelectQuery, {
    phase: phaseId,
  });

  useEffect(() => {
    const items: Data[] = [];
    for (let g of groupes) {
      items.push({
        groupe: g,
        participes: participes.filter((c) => c.groupe?.id === g.id),
        matches: matches.filter((m) => m.groupe?.id === g.id),
      });
    }
    setData(items);
  }, [matches, groupes, participes]);

  return (
    <>
      {data.map(({ groupe, matches, participes }) => (
        <div style={{ marginBottom: 10 }} key={groupe.id}>
          <h4>{groupe.name}</h4>
          <ClassmentsTable
            matches={matches}
            participes={participes}
            isLoading={loading1 || loading2 || loading3 || false}
            competition={item}
          />
        </div>
      ))}
    </>
  );
};

const Winner = ({ item }: Props) => {
  const { hasAccess } = usePermissions();
  const [update, { isLoading }] = CompetitionApi.useMajMutation();
  const { data = [] } = ParticipeApi.useParticipesSelectQuery({
    competition: item.id,
  });

  const finded = data.find((f) => f.id === item.winner?.id);

  return (
    <div className="row">
      {hasAccess([
        "competitions.create",
        "competitions.update",
        "competitions.validate",
        "score.create",
        "score.validate",
        "score.create",
      ]) && (
        <div className="col-md-12">
          <Select
            placeholder={"Choisir le vainqueur"}
            value={item.winner?.id}
            onChange={(e) => {
              const val = e.target.value;
              // @ts-ignore
              update({ data: { winner: val || null }, id: item.id });
            }}
            label=""
            containerClass="form-group"
            inputClass="form-control"
            options={data.map((c) => ({
              value: c.id,
              label: c.athlete ? getFN(c.athlete) : c.equipe.nom,
            }))}
          />
        </div>
      )}
      {!!finded && (
        <div className="col-md-12">
          <h1 className="text-center">
            {finded?.athlete ? getFN(finded.athlete) : finded?.equipe.nom}
          </h1>
        </div>
      )}
    </div>
  );
};
