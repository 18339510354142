import { Link } from "react-router-dom";

type DashboardItemProps = {
  title: string;
  bg: string;
  count: number;
  icon?: any;
  cols?: 4 | 3;
  onClick?: () => void;
};
const DashboardItem = ({
  bg,
  count,
  title,
  icon,
  cols = 4,
  onClick,
}: DashboardItemProps) => {
  const className =
    cols === 3
      ? "col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-5"
      : "col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-5";
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className={className}
    >
      <Link className={`dashboard-stat ${bg}`} to="#">
        <span className="number counter">{count}</span>
        <span className="name">{title}</span>
        <span className="bg-icon">{icon}</span>
      </Link>
    </div>
  );
};

export default DashboardItem;
