import moment from "moment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import Input, { Select } from "../../../../components/Input";
import { LoaderPage } from "../../../../components/Loader";
import { Competition, Phase, PhaseFormData } from "../../../../types";
import { getFN } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import {
  PhaseApi,
  usePhaseFromLocation,
} from "../../../../utils/api/phase.api";
import { TypePhase, TypePhases } from "../../../../utils/const-fields";
import { COLORS } from "../../../../utils/constants";
import { useFormData } from "../../../../utils/form";
import { getCompetTypeLabel } from "../../../../utils/hooks";
import BreadCrumb from "../../components/BreadCrumb";

type CreatePhaseProps = {
  item?: Phase;
  competition: Competition;
};
const CreatePhaseComponent = ({ item, competition }: CreatePhaseProps) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  let type = params.get("type") as TypePhase | null | "";
  if (!type || !TypePhases.includes(type)) {
    type = "";
  }

  const [exec, { isLoading }] = PhaseApi.useMajMutation();
  const { setErrors, register, onSubmitForm, data } =
    useFormData<PhaseFormData>(
      {
        name: item?.name || "",
        competition: competition.id,
        nbreEquipe: "",
        type: item?.type || type,
      },
      (yup) => ({
        name: yup.string().required().label("Nom"),
        type: yup.string().required().label("Type"),
      })
    );

  const onSubmit = React.useCallback(
    async (data: PhaseFormData) => {
      const sendData = { ...data };
      if (sendData.type !== "Éliminatoire") {
        sendData.nbreEquipe = competition.nbreEquipe;
      } else if (!data.nbreEquipe) {
        setErrors({ nbreEquipe: "Ce champ est obligatoir" });
        return;
      }
      const res = await exec({ id: item?.id, data: sendData });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          name:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Phase ${item ? "modifiée" : "ajoutée"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  const label = getCompetTypeLabel(competition.type);

  return (
    <div className="main-page">
      <BreadCrumb
        title="Phases"
        subTitle={
          item
            ? "Formulaire de modification de phase"
            : "Formulaire de création de phase"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: item ? "Modification de phase" : "Création de phase",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} de
                      phase
                    </h5>
                    {!!item && (
                      <>
                        <div>
                          Dernière modification:{" "}
                          {moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                        </div>
                        <div>Modifiée par: {getFN(item.updatedBy)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("name")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      {!type && (
                        <div className="col-md-12">
                          <Select
                            placeholder={"Choisir le type"}
                            {...register("type")}
                            label="Type"
                            containerClass="form-group"
                            inputClass="form-control"
                            options={TypePhases.map((s) => ({
                              label: s,
                              value: s,
                            }))}
                          />
                        </div>
                      )}
                      {data.type === "Éliminatoire" && (
                        <div className="col-md-12">
                          <Input
                            placeholder={`Nombre d'${label}`}
                            label={`Nombre d'${label}`}
                            {...register("nbreEquipe")}
                            containerClass="form-group"
                            inputClass="form-control"
                            type="number"
                          />
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function CreatePhase({ item }: { item?: Phase }) {
  const { item: comp } = useCompetitionFromLocation();
  if (!comp) return <LoaderPage />;
  return <CreatePhaseComponent item={item} competition={comp} />;
}

export default CreatePhase;

export function UpdatePhase() {
  const { item } = usePhaseFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreatePhase item={item} />;
}
