// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Subscribe, SubscribeFormData } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";

export type SubscribeSelectQuery = {
  competition?: number;
  federation?: number;
  equipe?: number;
  athlete?: number;
};

export type SubscribeQuery = CommonQuery & SubscribeSelectQuery;

// Define a service using a base URL and expected endpoints
export const SubscribeApi = createApi({
  tagTypes: ["subscribes", "selects"],
  reducerPath: "SubscribeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    subscribes: builder.query<PaginationResults<Subscribe>, SubscribeQuery>({
      query: (query) => ({
        url: QueryUrl(`/subscribes/`, query),
        method: "GET",
      }),
      providesTags: ["subscribes"],
    }),
    count: builder.query<number, SubscribeQuery>({
      query: (query) => ({
        url: QueryUrl(`/subscribes/count`, query),
        method: "GET",
      }),
      providesTags: [{ type: "subscribes", id: -1 }],
    }),
    me: builder.query<Subscribe[], SubscribeSelectQuery>({
      query: (query) => ({
        url: QueryUrl(`/subscribes/me`, query),
        method: "GET",
      }),
      providesTags: ["selects"],
    }),
    toggle: builder.mutation<Subscribe, SubscribeFormData>({
      query: (data) => {
        return {
          url: `/subscribes/toggle`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["selects", "subscribes"],
    }),
  }),
});
