import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import { LoaderPage } from "../../../../components/Loader";
import { useAppDispatch } from "../../../../redux/store";
import { Competition, Groupe, Participe } from "../../../../types";
import { useQuery } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import { useGroupeFromLocation } from "../../../../utils/api/groupe.api";
import {
  ParticipeApi,
  ParticipeQuery,
} from "../../../../utils/api/participe.api";
import { COLORS } from "../../../../utils/constants";
import BreadCrumb from "../../components/BreadCrumb";
import { ChoiceParticipeTable } from "./participe-table";

const SelectEquipeGroupeComponent = ({
  competition,
  item,
}: {
  competition: Competition;
  item: Groupe;
}) => {
  const { disabled, loading, onSubmit, selections, renderTable } =
    useSelectEquipeGroupeForm(competition, item);

  return (
    <div className="main-page">
      <BreadCrumb
        title="Equipes"
        subTitle={"Choisir les équipes"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          { title: item.name, route: -1 },
          {
            title: "Choisir les équipes",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <h5>Liste des équipes</h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <Button
                          type="button"
                          className="btn bg-black btn-wide"
                          title={`Ajouter les ${selections.length} équipes`}
                          loading={loading}
                          disabled={disabled}
                          onClick={() => {
                            onSubmit();
                          }}
                          icon={<i className="fa fa-arrow-right" />}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>
                          {selections.length}/{item.nbreEquipe}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {renderTable()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function SelectEquipeGroupe() {
  const { item: comp } = useCompetitionFromLocation();
  const { item } = useGroupeFromLocation();
  if (!comp || !item) return <LoaderPage />;
  return <SelectEquipeGroupeComponent item={item} competition={comp} />;
}

export default SelectEquipeGroupe;

export const useSelectEquipeGroupeForm = (
  competition: Competition,
  item: Groupe
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [onUpdate] = ParticipeApi.useMajMutation();

  const [loading, setLoading] = useState(false);

  const onDone = () => {
    Swal.fire({
      icon: "success",
      iconColor: COLORS.primary,
      confirmButtonColor: COLORS.primary,
      title: `Données ajoutés au groupe avec succès`,
    });
    navigate(-1);
    dispatch(
      ParticipeApi.util.invalidateTags([
        "participes",
        "selects",
        "participeItem",
      ])
    );
  };

  const [selections, setSelections] = useState<number[]>([]);
  const { data: participes } = ParticipeApi.useParticipesSelectQuery({
    groupe: item.id,
  });

  const props = useQuery<Participe, ParticipeQuery>(
    ParticipeApi.useParticipesSelectQuery,
    {
      competition: competition.id,
    }
  );

  const filtred = props.data?.filter((p) => {
    return !p.groupe || p.groupe.id === item.id;
  });

  const onSubmit = async () => {
    setLoading(true);
    const toDeletes: number[] = [];
    const toAdds: number[] = [];
    if (participes) {
      for (let p of participes) {
        if (!selections.includes(p.id)) {
          toDeletes.push(p.id);
        }
      }
      for (let s of selections) {
        const index = participes.findIndex((p) => p.id === s);
        if (index === -1) {
          toAdds.push(s);
        }
      }
    }

    for (let it of toAdds) {
      await onUpdate({ id: it, data: { groupe: item.id } });
    }
    for (let it of toDeletes) {
      await onUpdate({ id: it, data: { groupe: null } });
    }
    onDone();
    setLoading(false);
  };

  useEffect(() => {
    if (participes) {
      setSelections(participes.map((p) => p.id));
    }
  }, [participes]);

  const disabled = selections.length != item.nbreEquipe;

  const renderTable = () => {
    return (
      <div className="panel-body p-20">
        <ChoiceParticipeTable
          selections={selections}
          type={competition.type}
          setSelections={setSelections}
          {...props}
          data={filtred}
        />
      </div>
    );
  };

  return {
    disabled,
    onSubmit,
    props,
    participes,
    selections,
    setSelections,
    navigate,
    loading,
    renderTable,
  };
};
