import moment from "moment";
import { LoaderPage } from "../../components/Loader";
import { Competition, Phase } from "../../types";
import {
  FollowButton,
  getFN,
  getImage,
  usePaginationQuery,
} from "../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../utils/api/competition.api";
import { PhaseApi, PhaseQuery } from "../../utils/api/phase.api";
import { useTabsControl } from "../../utils/hooks";
import { Classements, Groupes } from "../admin/competitions/detail-competition";
import {
  AthletesSinglesList,
  DisplayMatchesFront,
} from "../equipe/detailEquipe";
import { ListEquipesSingle } from "../federation/detailFederation";

const DetailCompetitonComponent = ({ item }: { item: Competition }) => {
  const { active, setActive } = useTabsControl(0);
  const DATA = [
    {
      title: "Matches",
      component: () => <DisplayMatchesFront competition={item.id} />,
    },
  ];
  const props = usePaginationQuery<Phase, PhaseQuery>(PhaseApi.usePhasesQuery, {
    competition: item.id,
  });

  const phase = props?.data?.find?.((i) =>
    ["Championat", "Groupe"].includes(i.type)
  );
  const isChamptonat = phase?.type === "Championat";
  const isGroupe = phase?.type === "Groupe";
  if (isChamptonat) {
    DATA.splice(1, 0, {
      title: "Classements",
      component: () => <Classements setIsChampionat={() => null} item={item} />,
    });
  }
  if (isGroupe) {
    DATA.splice(1, 0, {
      title: "Groupes",
      component: () => <Groupes item={item} phaseId={phase.id} />,
    });
  }
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="grid-box">
                  <div className="title-section d-flex justify-content-beetwen">
                    <h1>
                      <span>
                        {item.name} - {item.federation.nom}
                      </span>
                    </h1>
                    <FollowButton subscriberId={item.id} field="competition" />
                  </div>

                  <ul className="autor-list">
                    <li>
                      <div className="autor-box">
                        <img src={getImage(item.image)} alt="" />

                        <div className="autor-content">
                          <div className="autor-title d-flex flex-column">
                            <h1>
                              Dirigé par {getFN(item.federation.president)}
                            </h1>
                            <span className="d-flex gap-10 align-items-center">
                              <i className="fa fa-caret-right"></i>
                              {item.niveau.name}
                            </span>
                            <span className="d-flex gap-10 align-items-center">
                              <i className="fa fa-tags"></i>
                              {item.sport.name}
                            </span>
                            <span className="d-flex gap-10 align-items-center">
                              <i className="fa fa-calendar"></i>
                              {item.saison.name} - du{" "}
                              {moment(item.startDate).format("DD MMM YYYY")} au{" "}
                              {moment(item.endDate).format("DD MMM YYYY")}
                            </span>
                          </div>
                          Juge :{" "}
                          <a href="#" className="autor-site">
                            {getFN(item.inspecteur)}
                          </a>
                        </div>
                      </div>
                    </li>
                    <ul
                      className="tabs"
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        paddingLeft: 0,
                      }}
                    >
                      {DATA.map((ut, index) => (
                        <li
                          role="presentation"
                          key={index}
                          className={index === active ? "active" : ""}
                        >
                          <a
                            href={`#${ut.title}`}
                            onClick={() => setActive(index)}
                            aria-controls={ut.title}
                            role="tab"
                            style={{ fontSize: 12 }}
                            data-toggle="tab"
                          >
                            {ut.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                    {DATA[active]?.component?.()}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className="sidebar">
                <div className="widget social-widget">
                  <div className="title-section">
                    <h1>
                      <span>Informations sur la compétition</span>
                    </h1>
                  </div>
                  <ul className="social-share">
                    {item.prolongation && (
                      <li>
                        <a href="#" className="twitter">
                          <i className="fa fa-clock-o"></i>
                        </a>
                        <span className="number">Prolongation</span>
                        <span>OUI</span>
                      </li>
                    )}

                    {item.tirsAuxBut && (
                      <li>
                        <a href="#" className="facebook">
                          <i className="fa fa-futbol-o"></i>
                        </a>
                        <span className="number">Tire au but</span>
                        <span>OUI</span>
                      </li>
                    )}
                    <li className=" ">
                      <a href="#" className="rss">
                        <i className="fa fa-users"></i>
                      </a>
                      <span className="number">Nombre d'équipes</span>
                      <span>{item.nbreEquipe}</span>
                    </li>
                    <li className="">
                      <a href="#" className="rss">
                        <i className="fa fa-male"></i>
                      </a>
                      <span className="number">Genre</span>
                      <span>{item.sexe}</span>
                    </li>
                  </ul>
                </div>
                {item.type === "Individuel" && (
                  <AthletesSinglesList competition={item.id} />
                )}
                {item.type === "Equipe" && (
                  <>
                    <div className="title-section">
                      <h1>
                        <span>Liste des équipes</span>
                      </h1>
                    </div>
                    <ListEquipesSingle competition={item.id} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default function DetailCompetiton() {
  const { item } = useCompetitionFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <DetailCompetitonComponent item={item} />;
}
