import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input, { GoogleInput, Select } from "../../../components/Input";
import { imageFormatter } from "../../../components/Table";
import { useUser } from "../../../redux/store";
import { User, UserFormData } from "../../../types";
import { UserApi } from "../../../utils/api/user.api";
import { Sexes } from "../../../utils/const-fields";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import ProfileAthlete from "../athletes/profile-athlete";
import BreadCrumb from "../components/BreadCrumb";

type ProfileUserProps = {
  item: User;
};

const ProfileUserComponent = ({ item }: ProfileUserProps) => {
  const navigate = useNavigate();

  const [exec, { isLoading }] = UserApi.useMajMutation();
  const {
    setErrors,
    errors,
    register,
    onSubmitForm,
    data: form,
    onChange,
  } = useFormData<UserFormData>(
    {
      nom: item?.nom || "",
      prenom: item?.prenom || "",
      email: item?.email || "",
      telephone: item?.telephone || "",
      address: item?.address || "",
      birthDate: item?.birthDate || "",
      federation: item?.federation?.id || "",
      nationality: item?.nationality || "",
      fonction: item?.fonction || "",
      sexe: item?.sexe || "Homme",
      latitude: item?.latitude || "",
      longitude: item?.longitude || "",
    },
    (yup) => ({
      nom: yup.string().required().label("Nom"),
      prenom: yup.string().required().label("Prénom"),
      email: yup.string().email().required().label("Email"),
      telephone: yup.string().required().label("Téléphone"),
      address: yup.string().required().label("Adresse"),
      birthDate: yup.string().required().label("Date de naissance"),
      nationality: yup.string().required().label("Nationalité"),
      sexe: yup.string().required().label("Sexe"),
    })
  );

  const onSubmit = React.useCallback(
    async (data: UserFormData) => {
      const res = await exec({ id: item?.id, data });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          nom:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Profil modifiée avec succès`,
        });
        navigate(-1);
      }
    },
    [item, form]
  );
  const { value: _2, ...imgProps } = register("image");

  return (
    <div className="main-page">
      <BreadCrumb
        title="Profil"
        subTitle={"Modification de profil"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          // { title: "Toutes les utilisateurs", route: "/dashboard/users" },
          {
            title: "Modification de profil",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - Profil</h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12 text-center mb-15">
                        {imageFormatter(item, 200, true)}
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Prénom"}
                          label="Prénom"
                          {...register("prenom")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("nom")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Fonction"}
                          label="Fonction"
                          {...register("fonction")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Email"}
                          label="Email"
                          {...register("email")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="email"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Téléphone"}
                          label="Téléphone"
                          {...register("telephone")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="tel"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <GoogleInput
                          {...register("address")}
                          value={form.address}
                          label="Adresse"
                          error={errors.address}
                          placeholder="Adresse"
                          inputClass="form-control"
                          onChange={(data) => {
                            onChange({
                              address: data.address,
                              latitude: data.latitude,
                              longitude: data.longitude,
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir le sexe"}
                          {...register("sexe")}
                          label="Sexe"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={Sexes.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Date de naissance"}
                          label="Date de naissance"
                          {...register("birthDate")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="date"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nationalité"}
                          label="Nationalité"
                          {...register("nationality")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Photo"}
                          label="Photo"
                          {...imgProps}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="file"
                        />
                      </div>

                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default function ProfileUser() {
  const user = useUser();
  if (user.userType === "Athlète") {
    return <ProfileAthlete />;
  }
  return <ProfileUserComponent item={user} />;
}
