import { Link } from "react-router-dom";
import { User } from "../../../types";
import { getFN, getImage } from "../../../utils/api/common.api";
const AthleteItem = ({ item }: { item: User }) => {
  return (
    <li>
      <img
        src={getImage(item.image)}
        alt=""
        style={{ width: 80, height: 80, objectFit: "cover" }}
      />
      <div className="post-content">
        <h2>
          <Link to={`/athlete/${item.id}`}>{getFN(item)}</Link>
        </h2>
        <ul className="post-tags d-flex flex-column">
          <li>
            <i className="fa fa-flag"></i>
            {item.federation?.acronyme}
          </li>
          {!!item?.equipe && (
            <li>
              <i className="fa fa-users"></i>
              {item.equipe.nom}
            </li>
          )}
          <li>
            <i className="fa fa-flag"></i>
            {item.sport.name}
          </li>
        </ul>
      </div>
    </li>
  );
};

export default AthleteItem;
