import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import { TextArea } from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { useUser } from "../../../redux/store";
import { MessageContact } from "../../../types";
import {
  ContactApi,
  useContactFromLocation,
} from "../../../utils/api/contact.api";
import { COLORS } from "../../../utils/constants";
import BreadCrumb from "../components/BreadCrumb";

type CreateMessageContactProps = {
  item: MessageContact;
};
const CreateMessageContact = ({ item }: CreateMessageContactProps) => {
  const navigate = useNavigate();
  const user = useUser();
  const [exec, { isLoading }] = ContactApi.useResponseMutation();
  const [val, setVal] = useState("");
  const onSubmit = React.useCallback(async () => {
    if (val.trim().length < 3) {
      Swal.fire({
        icon: "error",
        iconColor: COLORS.red,
        confirmButtonColor: COLORS.red,
        title: `Entrez une réponse valid`,
      });
      return;
    }
    const res = await exec({ contact: item.id, user: user.id, message: val });
    if ("error" in res) {
      const error = res.error as any;
      Swal.fire({
        icon: "error",
        iconColor: COLORS.red,
        confirmButtonColor: COLORS.red,
        title:
          error.data?.message ||
          "Veuillez vous assurer d'entrer les bonnes données",
      });
    } else {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.primary,
        confirmButtonColor: COLORS.primary,
        title: `Réponse envoyée avec succès`,
      });
      navigate(-1);
    }
  }, [val]);

  return (
    <div className="main-page">
      <BreadCrumb
        title="Message de contact"
        subTitle={item.name}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: item.name,
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>{item.name}</h5>
                    <h6>{item.email}</h6>
                    {!!item && (
                      <>
                        <div>{item.message}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="panel-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                    className="p-20"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <TextArea
                          label="Répondre"
                          placeholder="Votre réponse"
                          value={val}
                          onChange={(e) => {
                            setVal(e.target.value);
                          }}
                          containerClass="form-group"
                          inputClass="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateMessageContact;

export function DetailContact() {
  const { item } = useContactFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateMessageContact item={item} />;
}
