import moment from "moment";
import { Link } from "react-router-dom";
import { Match } from "../../../types";
import { getFN, getImage } from "../../../utils/api/common.api";
import { useMatchScores } from "../../admin/competitions/matches/match-table";

const MatchItem = ({
  item,
  showCompetName,
}: {
  item: Match;
  showCompetName?: boolean;
}) => {
  const { participeDom, participeExt } = item;
  const { athlete: athleteDom, equipe: equipeDom } = participeDom;
  const { athlete: athleteExt, equipe: equipeExt } = participeExt;
  const { totalScoreDom, totalScoreExt } = useMatchScores(item, true);
  return (
    <li className="my-4">
      <Link to={`/match/${item.id}`}>
        <div className="autor-box d-flex justify-content-evenly">
          <img src={getImage(athleteDom?.image || equipeDom?.image)} alt="" />

          <div
            className="autor-content text-center"
            style={{ marginLeft: "0" }}
          >
            {showCompetName && <p>{item.phase.competition.name}</p>}
            <div className="autor-title d-flex justify-content-center">
              <h1 className="d-flex gap-10">
                <a href="#">{athleteDom ? getFN(athleteDom) : equipeDom.nom}</a>
                {item.status !== "En attente" && (
                  <p>
                    {totalScoreDom}-{totalScoreExt}
                  </p>
                )}
                {item.status === "En attente" && <p>{item.heure}</p>}
                <a href="#">{athleteExt ? getFN(athleteExt) : equipeExt.nom}</a>
              </h1>
            </div>
            {item.groupe && <p className="date">{item.groupe.name}</p>}
            {!item.groupe && <p className="date">{item.phase.name}</p>}
            <p className="date">
              {moment(item.date).format("DD/MM/YYYY")} à {item.heure}
            </p>
            <p className="date">Juge : {getFN(item.juge)}</p>
          </div>
          <img src={getImage(athleteExt?.image || equipeExt?.image)} alt="" />
        </div>
      </Link>
    </li>
  );
};

export default MatchItem;
