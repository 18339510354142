import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import moment from "moment";
import Table, {
  ApprouveButton,
  DeleteButton,
  RejectButton,
  TableHeader,
  TableProps,
  UpdateButton,
  ViewButton,
  imageFormatter,
} from "../../../components/Table";

import { useUser } from "../../../redux/store";
import { Competition } from "../../../types";
import { CompetitionApi } from "../../../utils/api/competition.api";

type CompetitionTableProps = Omit<
  TableProps<Competition>,
  "keyField" | "headers"
> & {};
const CompetitionTable = ({ ...props }: CompetitionTableProps) => {
  const user = useUser();
  const [onDelete] = CompetitionApi.useDeleteMutation();
  const headers: TableHeader<Competition>[] = [
    {
      name: "name",
      title: "Nom",
      formatter: (i) => {
        return (
          <div>
            {imageFormatter(i)}
            <span style={{ display: "inline-block", marginLeft: 5 }}>
              {i.name}
            </span>
          </div>
        );
      },
    },
    {
      name: "federation",
      title: "Fédération",
      formatter: (i) => i.federation?.acronyme,
    },
    { name: "sport", title: "Sport", formatter: (i) => i.sport?.name },
    {
      name: "niveau",
      title: "Catégorie",
      formatter: (i) => i.niveau?.short_name ?? "",
    },
    {
      name: "saison",
      title: "Saison",
      formatter: (i) => i.saison?.name ?? "",
    },
    {
      name: "startDate",
      title: "Date début",
      formatter: (i) => moment(i.startDate).format("DD/MM/YYYY"),
    },
    {
      name: "endDate",
      title: "Date fin",
      formatter: (i) => moment(i.endDate).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/competitions/${item.slug}`}
            state={item}
          />

          <UpdateButton
            route={`/dashboard/competitions/update/${item.slug}`}
            state={item}
            permission="competitions.update"
          />
          <DeleteButton
            permission="competitions.delete"
            id={item.id}
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Athlète supprimé avec succès"}
          />
        </>
      ),
    },
  ];
  if (user.userType === "Agents Fédération") {
    headers.splice(1, 1, {
      name: "isValidated",
      title: "Statut",
      formatter: (i) => {
        if (i.isRejected) {
          return (
            <button
              type="button"
              style={{ margin: "0px 5px" }}
              className="btn btn-danger btn-xs btn-labeled next-btn"
            >
              Rejetée
            </button>
          );
        }
        if (i.isValidated) {
          return (
            <button
              type="button"
              style={{ margin: "0px 5px" }}
              className="btn btn-success btn-xs btn-labeled next-btn"
            >
              Validée
            </button>
          );
        }
        return (
          <button
            type="button"
            style={{ margin: "0px 5px" }}
            className="btn btn-info btn-xs btn-labeled next-btn"
          >
            En attente
          </button>
        );
      },
    });
  }
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default CompetitionTable;

export function ValidateCompetitionTable(props: CompetitionTableProps) {
  const [maj] = CompetitionApi.useMajMutation();
  const headers: TableHeader<Competition>[] = [
    { name: "image", title: "Image", formatter: imageFormatter },
    { name: "name", title: "Nom" },
    {
      name: "federation",
      title: "Fédération",
      formatter: (i) => i.federation?.acronyme,
    },
    { name: "sport", title: "Sport", formatter: (i) => i.sport?.name },
    {
      name: "niveau",
      title: "Catégorie",
      formatter: (i) => i.niveau?.short_name ?? "",
    },
    {
      name: "saison",
      title: "Saison",
      formatter: (i) => i.saison?.name ?? "",
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ApprouveButton
            error="Une erreur est survenue"
            permission={"competitions.validate"}
            success="Compétition approuvée avec succès"
            approuvFunction={() =>
              maj({
                id: item.id,
                data: { isRejected: false, isValidated: true },
              })
            }
          />

          {!item.isRejected && (
            <RejectButton
              error="Une erreur est survenue"
              permission={"competitions.reject"}
              success="Compétition  rejetée avec succès"
              rejectFunction={() =>
                maj({
                  id: item.id,
                  data: { isRejected: true, isValidated: false },
                })
              }
            />
          )}

          <ViewButton
            route={`/dashboard/competitions/${item.id}`}
            state={item}
            permission="competitions.view"
          />
        </>
      ),
    },
  ];

  return <Table keyField={"id"} {...props} headers={headers} />;
}
