import { useUser } from "../redux/store";
import { UserApi } from "./api/user.api";

const PERMISSIONS = [
  "sports.create",
  "sports.list",
  "sports.update",
  "sports.delete",
  "fede.create",
  "fede.view",
  "fede.list",
  "fede.update",
  "fede.delete",
  "fede.viewfederation",
  "niveaux.create",
  "niveaux.list",
  "niveaux.update",
  "niveaux.delete",
  "agents.create",
  "agents.view",
  "agents.list",
  "agents.update",
  "agents.delete",
  "equipes.create",
  "equipes.view",
  "equipes.viewteam",
  "equipes.list",
  "equipes.update",
  "equipes.delete",
  "athletes.create",
  "athletes.view",
  "athletes.list",
  "athletes.reject",
  "athletes.update",
  "athletes.delete",
  "athletes.validate",
  "competitions.create",
  "competitions.view",
  "competitions.list",
  "competitions.update",
  "competitions.delete",
  "competitions.validate",
  "competitions.reject",
  "match.create",
  "match.delete",
  "match.update",
  "match.start",
  "match.end",
  "score.create",
  "score.delete",
  "score.validate",
  "agents-cno.create",
  "agents-cno.view",
  "agents-cno.list",
  "agents-cno.delete",
  "agents-cno.update",
  "president-fede.create",
  "president-fede.view",
  "president-fede.list",
  "president-fede.delete",
  "president-fede.update",
  "abonnes.create",
  "abonnes.view",
  "abonnes.list",
  "abonnes.delete",
  "reseaux-sociaux",
  "coordonnees",
  "pubs",
  "news",
  "saisons.create",
  "saisons.list",
  "saisons.update",
  "saisons.delete",
  // "juge.create",
  // "juge.list",
  // "juge.update",
  // "juge.delete",
  // "inspecteur.create",
  // "inspecteur.list",
  // "inspecteur.update",
  // "inspecteur.delete",
] as const;

export const PERMISSION_LABELS: { [key in PermissionValue]: string } = {
  "sports.create": "Créer un sport",
  "sports.list": "Voir la liste des sports",
  "sports.update": "Modifier un sport",
  "sports.delete": "Supprimer un sport",
  "fede.create": "Créer une fédération",
  "fede.list": "Voir la liste des fédérations",
  "fede.update": "Modifier une fédération",
  "fede.delete": "Supprimer une fédération",
  "fede.viewfederation": "Voir sa fédération",
  "fede.view": "Voir les détails d'une fédération",
  "niveaux.create": "Créer une catégorie",
  "niveaux.list": "Voir la liste des catégories",
  "niveaux.update": "Modifier une catégorie",
  "niveaux.delete": "Supprimer une catégorie",
  "agents.create": "Créer un agent",
  "agents.list": "Voir la liste des agents",
  "agents.update": "Modifier un agent",
  "agents.delete": "Supprimer un agent",
  "agents.view": "Voir les détail d'un agent",
  "equipes.create": "Créer une équipe",
  "equipes.list": "Voir la liste des équipes",
  "equipes.update": "Modifier une équipe",
  "equipes.delete": "Supprimer une équipe",
  "equipes.view": "Voir les détail d'une équipe",
  "equipes.viewteam": "Voir son équipe",
  "athletes.create": "Créer un athlète",
  "athletes.list": "Voir la liste des athlètes",
  "athletes.update": "Modifier un athlète",
  "athletes.delete": "Supprimer un athlète",
  "athletes.view": "Voir les détail d'un athlète",
  "athletes.reject": "rejeter l'inscription d'un athlète",
  "athletes.validate": "Valider l'inscription d'un athlète",
  "competitions.create": "Créer une compétition",
  "competitions.list": "Voir la liste des compétitions",
  "competitions.update": "Modifier une compétition",
  "competitions.delete": "Supprimer une compétition",
  "competitions.view": "Voir les détail d'une compétition",
  "competitions.reject": "rejeter l'inscription d'une compétition",
  "competitions.validate": "Valider l'inscription d'une compétition",
  "match.create": "Créer un match",
  "match.update": "Modifier un match",
  "match.delete": "Supprimer un match",
  "match.start": "Démarrer un match",
  "match.end": "Terminer un match",
  "score.create": "Créer un score",
  "score.validate": "Valider un score",
  "score.delete": "Supprimer un score",
  "agents-cno.create": "Créer un agent CNO",
  "agents-cno.list": "Voir la liste des agents CNO",
  "agents-cno.update": "Modifier un agent CNO",
  "agents-cno.delete": "Supprimer un agent CNO",
  "agents-cno.view": "Voir la liste des agents CNO",
  "president-fede.create": "Créer une président fédération",
  "president-fede.list": "Voir la liste des président fédérations",
  "president-fede.update": "Modifier une président fédération",
  "president-fede.delete": "Supprimer une président fédération",
  "president-fede.view": "Voir les détails d'un président fédération",
  "abonnes.create": "Créer un abonné",
  "abonnes.view": "Voir les détail d'un abonné",
  "abonnes.list": "Voir la liste des abonnés",
  "abonnes.delete": "Supprimer un abonné",
  "reseaux-sociaux": "Gérer les réseaux sociaux",
  coordonnees: "Gérer les coordonnées",
  pubs: "Gérer les pubs",
  news: "Gérer les news",
  "saisons.create": "Créer une saison",
  "saisons.list": "Voir la liste des saisons",
  "saisons.update": "Modifier une saison",
  "saisons.delete": "Supprimer une saison",
  // "juge.create": "Créer un juge",
  // "juge.list": "Voir la liste des juges",
  // "juge.update": "Modifier un juge",
  // "juge.delete": "Supprimer un juge",
  // "inspecteur.create": "Créer un inspecteur",
  // "inspecteur.list": "Voir la liste des inspecteurs",
  // "inspecteur.update": "Modifier un inspecteur",
  // "inspecteur.delete": "Supprimer un inspecteur",
};

export type PermissionValue = (typeof PERMISSIONS)[number];

export function usePermissions() {
  const { data } = UserApi.useRolesQuery();

  const user = useUser();
  const hasAccess = (permission: PermissionValue[]) => {
    const role = data?.find((i) => i.name === user?.userType);
    if (!user || permission.length === 0 || !role) return null;

    const pers = role.permissions.map((p) => p.name);
    return permission.some((i) => pers.includes(i));
  };

  return {
    hasAccess,
    user,
  };
}
