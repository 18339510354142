import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import Table, {
  DeleteButton,
  TableHeader,
  TableProps,
  UpdateButton,
  ViewButton,
} from "../../../../components/Table";

import { Competition, Phase } from "../../../../types";
import { PhaseApi } from "../../../../utils/api/phase.api";

type PhaseTableProps = Omit<TableProps<Phase>, "keyField" | "headers"> & {
  competition: Competition;
};
const PhaseTable = ({ competition, ...props }: PhaseTableProps) => {
  const [onDelete] = PhaseApi.useDeleteMutation();
  const headers: TableHeader<Phase>[] = [
    { name: "name", title: "Nom" },
    {
      name: "type",
      title: "Type",
      formatter: (i) => i.type,
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/competitions/${competition.slug}/phases/${item.id}`}
            state={item}
          />
          <UpdateButton
            route={`/dashboard/competitions/${competition.slug}/phases/update/${item.id}`}
            state={item}
            permission="competitions.create"
          />
          <DeleteButton
            permission="competitions.create"
            id={item.id}
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Phase supprimée avec succès"}
          />
        </>
      ),
    },
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default PhaseTable;
