import moment from "moment";
import Table, {
  DeleteButton,
  TableHeader,
  UpdateButton,
  ViewButton,
  imageFormatter,
} from "../../../components/Table";
import { Federation } from "../../../types";
import {
  CommonQuery,
  getFN,
  usePaginationQuery,
} from "../../../utils/api/common.api";
import { FedesApi } from "../../../utils/api/fedes.api";
import BreadCrumb from "../components/BreadCrumb";

const Fedes = () => {
  const props = usePaginationQuery<Federation, CommonQuery>(
    FedesApi.useFederationsQuery,
    {},
    { sport: true }
  );
  const [onDelete] = FedesApi.useDeleteMutation();

  const headers: TableHeader<Federation>[] = [
    { name: "image", title: "Image", formatter: imageFormatter },
    { name: "nom", title: "Nom" },
    { name: "sport", title: "Sport", formatter: (i) => i.sport.name },
    {
      name: "president",
      title: "Président",
      formatter: (i) => getFN(i.president),
    },
    {
      name: "createdDate",
      title: "Date de création",
      formatter: (i) => moment(i.createdDate).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/federations/${item.slug}`}
            state={item}
            permission="fede.view"
          />

          <UpdateButton
            route={`/dashboard/federations/update/${item.slug}`}
            state={item}
            permission="fede.update"
          />

          <DeleteButton
            id={item.id}
            deleteFunction={onDelete}
            permission="fede.delete"
            error={"Une erreur est survenue lors de la suppression"}
            success={"Fédération supprimée avec succès"}
          />
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Fédérations"
        subTitle="Liste des fédérations"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Toutes les fédérations",
            route: "/dashboard/federations",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des Fédérations ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fedes;
