// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Groupe, GroupeFormData } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
export type GroupeQuery = CommonQuery & {
  phase?: number;
};
export type GroupeSelectQuery = Omit<CommonQuery, "page" | "limit"> & {
  phase?: number;
};
// Define a service using a base URL and expected endpoints
export const GroupeApi = createApi({
  tagTypes: ["groupes", "groupeItem"],
  reducerPath: "GroupeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    groupes: builder.query<PaginationResults<Groupe>, GroupeQuery>({
      query: (query) => ({
        url: QueryUrl(`/proupes/`, query),
        method: "GET",
      }),
      providesTags: ["groupes"],
    }),
    select: builder.query<Groupe[], GroupeSelectQuery>({
      query: (query) => ({
        url: QueryUrl(`/proupes/select`, query),
        method: "GET",
      }),
      providesTags: ["groupes"],
    }),
    groupeById: builder.query<Groupe, string | number>({
      query: (id) => ({
        url: `/proupes/${id}/`,
        method: "GET",
      }),
      providesTags: ["groupeItem"],
    }),
    maj: builder.mutation<
      Groupe,
      { id?: number; data: FormData | GroupeFormData }
    >({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/proupes/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/proupes/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["groupes", "groupeItem"],
    }),
    delete: builder.mutation<Groupe, number>({
      query: (id) => ({
        url: `/proupes/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["groupes", "groupeItem"],
    }),
  }),
});

export function useGroupeFromLocation(fromLocation?: boolean) {
  const location = useLocation();
  const { groupeId } = useParams<{ groupeId: string }>();
  const [fetch, { data }] = GroupeApi.useLazyGroupeByIdQuery();
  const [item, setItem] = useState<Groupe>();
  useEffect(() => {
    if (fromLocation !== false) {
      setItem(location.state);
    }
  }, []);

  useEffect(() => {
    if (groupeId) {
      fetch(groupeId);
    }
  }, [groupeId]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
