import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import { LoaderPage } from "../../../../components/Loader";
import {
  Competition,
  Groupe,
  Match,
  Participe,
  Phase,
} from "../../../../types";
import { usePaginationQuery, useQuery } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import { useGroupeFromLocation } from "../../../../utils/api/groupe.api";
import { MatchApi, MatchSelectQuery } from "../../../../utils/api/match.api";
import {
  ParticipeApi,
  ParticipeQuery,
} from "../../../../utils/api/participe.api";
import { usePhaseFromLocation } from "../../../../utils/api/phase.api";
import { useTabsControl } from "../../../../utils/hooks";
import { usePermissions } from "../../../../utils/permissions";
import BreadCrumb from "../../components/BreadCrumb";
import ClassmentsTable from "../matches/classments";
import { DisplayMatches } from "../matches/match-table";
import ParticipeTable from "../participes/participe-table";

const DetailGroupeComponent = ({
  competition,
  item,
  phase,
}: {
  competition: Competition;
  item: Groupe;
  phase: Phase;
}) => {
  const { active, setActive } = useTabsControl(0);

  const DATA = [
    {
      title: competition.type === "Equipe" ? "Equipes" : "Athlètes",
      component: Equipes,
    },
    {
      title: "Matches",
      component: Matches,
    },
    {
      title: "Classements",
      component: Classements,
    },
  ];
  return (
    <div className="main-page">
      <BreadCrumb
        title={competition.name}
        subTitle={item.name}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: phase.name,
            route: `/dashboard/competitions/${competition.slug}/phases/${phase.id}`,
          },
          {
            title: item.name,
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>{item.name}</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <ul className="tabs">
                    {DATA.map((ut, index) => (
                      <li
                        role="presentation"
                        className={index === active ? "active" : ""}
                      >
                        <a
                          href={`#${ut.title}`}
                          onClick={() => setActive(index)}
                          aria-controls={ut.title}
                          role="tab"
                          style={{ fontSize: 12 }}
                          data-toggle="tab"
                        >
                          {ut.title}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <div className="tabs-container">
                    {DATA.map(({ title, component: Component }, index) => (
                      <div
                        role="tabpanel"
                        className={`${index === active ? "active" : ""}`}
                        id={title}
                        key={title}
                      >
                        <Component
                          phase={phase}
                          item={item}
                          competition={competition}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function DetailGroupe() {
  const { item: comp } = useCompetitionFromLocation();
  const { item } = useGroupeFromLocation();
  const { item: phase } = usePhaseFromLocation();

  if (!comp || !item || !phase) return <LoaderPage />;
  return <DetailGroupeComponent phase={phase} competition={comp} item={item} />;
}

export default DetailGroupe;

type Props = { item: Groupe; competition: Competition; phase: Phase };

const Equipes = ({ item, competition, phase }: Props) => {
  const navigate = useNavigate();
  const { hasAccess } = usePermissions();
  const props = usePaginationQuery<Participe, ParticipeQuery>(
    ParticipeApi.useParticipesQuery,
    { groupe: item.id }
  );
  return (
    <div className="row">
      {hasAccess(["competitions.create"]) && (
        <div className="col-md-12 text-right">
          {competition.type === "Equipe" && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title="Choisir les équipes"
              onClick={() => {
                navigate(
                  `/dashboard/competitions/${competition.slug}/phases/${phase.id}/groupe/${item.id}/equipes/select`
                );
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
          {competition.type === "Individuel" && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title="Choisir les athlètes"
              onClick={() => {
                navigate(
                  `/dashboard/competitions/${competition.slug}/phases/${phase.id}/groupe/${item.id}/athletes/select`
                );
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
        </div>
      )}
      <div className="col-md-12">
        <ParticipeTable type={competition.type} {...props} />
      </div>
    </div>
  );
};

const Matches = ({ item, competition, phase }: Props) => {
  const navigate = useNavigate();
  const { hasAccess } = usePermissions();
  const props = useQuery<Match, MatchSelectQuery>(MatchApi.useSelectQuery, {
    groupe: item.id,
  });
  return (
    <div className="row">
      {hasAccess(["competitions.create"]) && (
        <div className="col-md-12 text-right mb-5">
          <Button
            type="button"
            className="btn bg-black btn-wide"
            title="Ajouter un match"
            onClick={() => {
              navigate(
                `/dashboard/competitions/${competition.slug}/phases/${phase.id}/groupe/${item.id}/matches/create`
              );
            }}
            icon={<i className="fa fa-arrow-right" />}
          />
        </div>
      )}

      <div className="col-md-12">
        <DisplayMatches data={props.data} isLoading={props.isLoading} />
      </div>
    </div>
  );
};

const Classements = ({ item, competition }: Props) => {
  const { data: matches, isLoading: loading1 } = useQuery<
    Match,
    MatchSelectQuery
  >(MatchApi.useSelectQuery, {
    groupe: item.id,
  });

  const { isLoading: loading2, data: participes } = useQuery<
    Participe,
    ParticipeQuery
  >(ParticipeApi.useParticipesSelectQuery, { groupe: item.id });

  return (
    <ClassmentsTable
      matches={matches}
      participes={participes}
      isLoading={loading1 || loading2 || false}
      competition={competition}
    />
  );
};
