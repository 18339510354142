// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SelectOptions, Sport, SportFormData } from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const SportApi = createApi({
  tagTypes: ["sports", "sportsSelect", "sport"],
  reducerPath: "SportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    sports: builder.query<PaginationResults<Sport>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/sports/`, query),
        method: "GET",
      }),
      providesTags: ["sports"],
    }),
    sportsSelect: builder.query<Sport[], void>({
      query: () => ({
        url: `/sports/select/`,
        method: "GET",
      }),
      providesTags: ["sportsSelect"],
    }),
    findBySlug: builder.query<Sport, string>({
      query: (slug) => ({
        url: `/sports/slug/${slug}/`,
        method: "GET",
      }),
      providesTags: (a, b, slug) => [{ type: "sport", id: slug }],
    }),
    maj: builder.mutation<Sport, { id?: number; data: SportFormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/sports/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/sports/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["sports", "sportsSelect"],
    }),
    delete: builder.mutation<Sport, number>({
      query: (id) => ({
        url: `/sports/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["sports", "sportsSelect"],
    }),
  }),
});

export function useSportsSelect() {
  const [items, setItems] = useState<SelectOptions>([]);
  const { data } = SportApi.useSportsSelectQuery();
  useEffect(() => {
    if (data) {
      setItems(data.map((i) => ({ value: i.id, label: i.name })));
    }
  }, [data]);
  return items;
}

export function useSportFromLocation() {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [fetch, { data }] = SportApi.useLazyFindBySlugQuery();
  const [item, setItem] = useState<Sport>(location.state);

  useEffect(() => {
    if (slug) fetch(slug);
  }, [fetch]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
