import moment from "moment";
import { Link } from "react-router-dom";
import { Competition } from "../../../types";
import { getImage } from "../../../utils/api/common.api";

const CompetitionItem = ({ item }: { item: Competition }) => {
  return (
    <li>
      <img src={getImage(item.image)} alt="" />
      <div className="post-content">
        <h2>
          <Link to={`/competition/${item.slug}`}>
            {item.name} - {item.federation.nom}
          </Link>
        </h2>
        <ul className="post-tags d-flex flex-column">
          <li>
            <i className="fa fa-trophy"></i>
            {item.sport.name}
          </li>
          <li>
            <i className="fa fa-calendar"></i>
            Du <a href="#">{moment(item.startDate).format("DD MMM")}</a> au{" "}
            <a href="#">{moment(item.endDate).format("DD MMM YYYY")}</a>
          </li>
          <li>
            <i className="fa fa-users"></i>
            10 Athlètes
          </li>
        </ul>
      </div>
    </li>
  );
};

export default CompetitionItem;
