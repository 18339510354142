import OwnCarousel from "../../components/OwnCarousel";
import { Competition } from "../../types";
import CompetitionItem from "../federation/components/CompetitionItem";

const CompetSlider = ({ competitions }: { competitions?: Competition[] }) => {
  return (
    <OwnCarousel showDots num={1}>
      {competitions?.map((c) => {
        return (
          <div className="row">
            <CompetitionItem large md={12} item={c} key={c.id} />
          </div>
        );
      })}
    </OwnCarousel>
  );
};

export default CompetSlider;
