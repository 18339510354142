import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input, { Select } from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { validateImg } from "../../../components/Table";
import { useUser } from "../../../redux/store";
import {
  Competition,
  CompetitionFormData,
  FederationFormData,
} from "../../../types";
import {
  CompetitionApi,
  useCompetitionFromLocation,
} from "../../../utils/api/competition.api";
import { useFedeSelectItems } from "../../../utils/api/fedes.api";
import { useNiveauxsSelect } from "../../../utils/api/niveau.api";
import { useSaisonsSelect } from "../../../utils/api/saison.api";
import { useSportsSelect } from "../../../utils/api/sports.api";
import { useUsersSelect } from "../../../utils/api/user.api";
import { TypeEquipes, getCompetTypes } from "../../../utils/const-fields";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import { getCompetTypeLabel } from "../../../utils/hooks";
import BreadCrumb from "../components/BreadCrumb";

type CreateCompetitionProps = {
  item?: Competition;
};
const CreateCompetition = ({ item }: CreateCompetitionProps) => {
  const navigate = useNavigate();
  const sports = useSportsSelect();
  const niveaux = useNiveauxsSelect();
  const saisons = useSaisonsSelect();
  const user = useUser();
  const fedeId = item?.federation?.id || user?.federation?.id;
  const fedes = useFedeSelectItems();
  const [exec, { isLoading }] = CompetitionApi.useMajMutation();
  const { setErrors, errors, register, onSubmitForm, data } =
    useFormData<CompetitionFormData>(
      {
        federation: fedeId || "",
        name: item?.name || "",
        short_name: item?.short_name || "",
        sport: item?.sport?.id || "",
        inspecteur: item?.inspecteur?.id || "",
        endDate: item?.endDate || "",
        startDate: item?.startDate || "",
        type: item?.type || "",
        nbreEquipe: item?.nbreEquipe || "",
        sexe: item?.sexe || "",
        tirsAuxBut: item?.tirsAuxBut || false,
        saison: item?.saison?.id || "",
        niveau: item?.niveau?.id || "",
        prolongation: item?.prolongation || false,
      },
      (yup) => ({
        federation: fedeId
          ? yup.string().notRequired()
          : yup.string().required().label("Fédération"),
        name: yup.string().required().label("Nom"),
        short_name: yup.string().required().label("Nom court"),
        sport: yup.string().required().label("Sport"),
        inspecteur: yup.string().required().label("Inspecteur"),
        type: yup.string().required().label("Type"),
        endDate: yup.string().required().label("Date fin"),
        startDate: yup.string().required().label("Date début"),
        nbreEquipe: yup.string().required().label("Nombre"),
        sexe: yup.string().required().label("Sexe"),
        saison: yup.string().required().label("Saison"),
        niveau: yup.string().required().label("Catégorie"),
        image: item
          ? validateImg(yup).notRequired().label("Image principale")
          : validateImg(yup).required().label("Image principale"),
      })
    );

  const onSubmit = React.useCallback(
    async (data: FederationFormData) => {
      let fd = new FormData();
      if (data instanceof FormData) {
        fd = data;
      } else {
        for (let [k, v] of Object.entries(data)) {
          fd.append(k, v as any);
        }
      }
      const res = await exec({ id: item?.id, data: fd });
      if ("error" in res) {
        const error = res.error as any;
        Swal.fire({
          icon: "error",
          iconColor: COLORS.red,
          confirmButtonColor: COLORS.red,
          title:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Competition ${item ? "modifiée" : "ajoutée"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  const { users: inspecteurs } = useUsersSelect({
    federation: data?.federation,
    userType: ["Inspecteur"],
  });

  const fede = fedes.find(
    (f) =>
      parseInt(String(f.value), 10) === parseInt(String(data.federation), 10)
  );

  const types = getCompetTypes(fede?.type);

  const label = getCompetTypeLabel(data.type);

  const { value: val1, ...imgProps } = register("image");
  return (
    <div className="main-page">
      <BreadCrumb
        title="Compétition"
        subTitle={
          item
            ? "Formulaire de modification de compétition"
            : "Formulaire de création de compétition"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: item
              ? "Modification de compétition"
              : "Création de compétition",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} de
                      compétition
                    </h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      {!fedeId && (
                        <div className="col-md-6 col-xs-12">
                          <Select
                            placeholder={"Choisir une fédération"}
                            {...register("federation")}
                            label="Fédération"
                            containerClass="form-group"
                            inputClass="form-control"
                            options={fedes}
                          />
                        </div>
                      )}
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir un inspecteur"}
                          {...register("inspecteur")}
                          label="Inspecteur"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={inspecteurs}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir le type"}
                          {...register("type")}
                          label="Type"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={types.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir le sexe"}
                          {...register("sexe")}
                          label="Sexe"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={TypeEquipes.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir un sport"}
                          label="Sport"
                          {...register("sport")}
                          containerClass="form-group"
                          inputClass="form-control"
                          options={sports}
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("name")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nom court"}
                          label="Nom court"
                          {...register("short_name")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Date début"}
                          label="Date début"
                          {...register("startDate")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="date"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Date fin"}
                          label="Date fin"
                          {...register("endDate")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="date"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Image principale"}
                          label="Image principale"
                          {...imgProps}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="file"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir une catégorie"}
                          label="Catégorie"
                          {...register("niveau")}
                          containerClass="form-group"
                          inputClass="form-control"
                          options={niveaux}
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir une Saison"}
                          label="Saison"
                          {...register("saison")}
                          containerClass="form-group"
                          inputClass="form-control"
                          options={saisons}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={`Nombre d'${label}`}
                          label={`Nombre d'${label}`}
                          {...register("nbreEquipe")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="number"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Prolongation?"}
                          label="Prolongation?"
                          containerClass="perm"
                          {...register("prolongation")}
                          type="checkbox"
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Tirs au but?"}
                          label="Tirs au but?"
                          containerClass="perm"
                          {...register("tirsAuxBut")}
                          type="checkbox"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateCompetition;

export function UpdateCompetition() {
  const { item } = useCompetitionFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateCompetition item={item} />;
}
