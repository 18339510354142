import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import moment from "moment";
import Table, {
  ApprouveButton,
  DeleteButton,
  RejectButton,
  TableHeader,
  TableProps,
  UpdateButton,
  ViewButton,
  imageFormatter,
} from "../../../components/Table";

import Input from "../../../components/Input";
import { User } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import { UserApi } from "../../../utils/api/user.api";

type AthleteTableProps = Omit<TableProps<User>, "keyField" | "headers"> & {};
const commonHeaders: TableHeader<User>[] = [
  { name: "image", title: "Image", formatter: imageFormatter },
  { name: "prenom", title: "Nom", formatter: getFN },
  {
    name: "federation",
    title: "Fédération",
    formatter: (i) => i.federation?.acronyme,
  },
  { name: "sport", title: "Sport", formatter: (i) => i.sport?.name },
  { name: "equipe", title: "Équipe", formatter: (i) => i.equipe?.nom ?? "" },
  {
    name: "niveau",
    title: "Catégorie",
    formatter: (i) => i.niveau?.short_name ?? "",
  },
];
const AthleteTable = ({ ...props }: AthleteTableProps) => {
  const [onDelete] = UserApi.useDeleteMutation();
  const headers: TableHeader<User>[] = [
    ...commonHeaders,
    {
      name: "createdAt",
      title: "Date d'inscription",
      formatter: (i) => moment(i.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            route={`/dashboard/athletes/${item.id}`}
            state={item}
            permission="athletes.view"
          />

          <UpdateButton
            route={`/dashboard/athletes/update/${item.id}`}
            state={item}
            permission="athletes.update"
          />

          <DeleteButton
            permission="athletes.delete"
            id={item.id}
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"Athlète supprimé avec succès"}
          />
        </>
      ),
    },
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};

export default AthleteTable;

export function ValidateAthleteTable(props: AthleteTableProps) {
  const [maj] = UserApi.useMajMutation();
  const headers: TableHeader<User>[] = [
    ...commonHeaders,
    {
      name: "createdAt",
      title: "Date d'inscription",
      formatter: (i) => moment(i.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ApprouveButton
            error="Une erreur est survenue"
            permission={"athletes.validate"}
            success="Athlète approuvé avec succès"
            approuvFunction={() =>
              maj({
                id: item.id,
                data: { isRejected: false, isValidated: true },
              })
            }
          />

          {!item.isRejected && (
            <RejectButton
              error="Une erreur est survenue"
              permission={"athletes.reject"}
              success="Inscription  rejeté avec succès"
              rejectFunction={() =>
                maj({
                  id: item.id,
                  data: { isRejected: true, isValidated: false },
                })
              }
            />
          )}

          <ViewButton
            route={`/dashboard/athletes/${item.id}`}
            state={item}
            permission="athletes.view"
          />
        </>
      ),
    },
  ];

  return <Table keyField={"id"} {...props} headers={headers} />;
}

export const ChoiceAthleteTable = ({
  selections,
  setSelections,
  ...props
}: AthleteTableProps & {
  selections: number[];
  setSelections: any;
}) => {
  const headers = [
    ...commonHeaders,
    {
      name: "id",
      title: "Selections",
      formatter: (item) => {
        const checked = selections.includes(item.id);

        return (
          <div className="text-center">
            <Input
              checked={checked}
              onChange={(e) => {
                if (checked) {
                  setSelections((old: number[]) =>
                    old.filter((f) => f !== item.id)
                  );
                } else {
                  setSelections((old: number[]) => [...old, item.id]);
                }
              }}
              type="checkbox"
            />
          </div>
        );
      },
    } as TableHeader<User>,
  ];
  return <Table keyField={"id"} {...props} headers={headers} />;
};
