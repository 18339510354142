// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Auth,
  AuthFormData,
  ChangePasswordFromData,
  ForgetPasswordFormData,
  ResetPasswordFormData,
  UserFormData,
} from "../../types";
import { prepareHeaders } from "./utils";

import { ApiUrl } from "./URL";
import { UserApi } from "./user.api";

// Define a service using a base URL and expected endpoints
export const AuthApi = createApi({
  tagTypes: ["auth"],
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api/auth`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<Auth, AuthFormData>({
      query: (data) => ({
        url: `/login/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      onCacheEntryAdded: (args, { dispatch }) => {
        dispatch(UserApi.util.invalidateTags(["user"]));
      },
    }),
    register: builder.mutation<any, UserFormData>({
      query: (data) => ({
        url: `/register/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [],
    }),
    forgetPassword: builder.mutation<any, ForgetPasswordFormData>({
      query: (data) => ({
        url: `/forgot-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [],
    }),
    resetPassword: builder.mutation<any, ResetPasswordFormData>({
      query: (data) => ({
        url: `/reset-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [],
    }),
    changePassword: builder.mutation<any, ChangePasswordFromData>({
      query: (data) => ({
        url: `/update-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} = AuthApi;
