import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { Permission, Role, RoleFormData } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import { UserApi, useRoleFromLocation } from "../../../utils/api/user.api";
import { COLORS } from "../../../utils/constants";
import { PERMISSION_LABELS } from "../../../utils/permissions";
import BreadCrumb from "../components/BreadCrumb";

type CreateRoleProps = {
  item: Role;
};
type Item = {
  title: string;
  items: Permission[];
};
const CreateRole = ({ item }: CreateRoleProps) => {
  const navigate = useNavigate();
  const [exec, { isLoading }] = UserApi.useUpdateRoleMutation();
  const [items, setItems] = useState<Item[]>([]);
  const { data: permissionsList } = UserApi.usePermissionsQuery();
  const [permissions, setPermissions] = useState(
    item.permissions.map((c) => c.id)
  );

  useEffect(() => {
    if (permissionsList) {
      const newItems: Item[] = [];
      for (let permission of permissionsList) {
        const perm = permission.name.split(".")[0];
        const index = newItems.findIndex((i) => i.title === perm);
        if (index === -1) {
          newItems.push({ title: perm, items: [permission] });
        } else {
          newItems[index].items.push(permission);
        }
      }
      const autres = newItems.filter((i) => i.items.length === 1);

      setItems([
        ...newItems.filter((i) => i.items.length > 1),
        {
          title: "autres",
          items: autres.reduce(
            (acc, cur) => [...acc, ...cur.items],
            [] as Permission[]
          ),
        },
      ]);
    }
  }, [permissionsList]);

  const onSubmit = React.useCallback(
    async (data: RoleFormData) => {
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        Swal.fire({
          icon: "error",
          iconColor: COLORS.red,
          confirmButtonColor: COLORS.red,
          title:
            error.data?.message ||
            `Veuillez vous assurer d'entrer les bonnes données`,
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Role ${item ? "modifié" : "ajouté"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Roles & Permissions"
        subTitle={item.name}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Toutes les roles",
            route: "/dashboard/roles-et-permissions",
          },
          {
            title: item.name,
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - {item.name}</h5>

                    <div>
                      Dernière modification:{" "}
                      {moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                    </div>
                    <div>Modifiée par: {getFN(item.updatedBy)}</div>
                  </div>
                </div>
                <div className="panel-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSubmit({ permissions });
                    }}
                    className="p-20"
                  >
                    {items.map((item) => {
                      return (
                        <div key={item.title} className="row">
                          <div className="col-md-12">
                            <h4 className="text-uppercase">{item.title}</h4>
                          </div>
                          {item.items.map((perm) => {
                            const checked = permissions.includes(perm.id);
                            const label =
                              perm.name in PERMISSION_LABELS
                                ? PERMISSION_LABELS[perm.name]
                                : perm.name;
                            return (
                              <div key={perm.id} className="col-md-6 col-xs-12">
                                <Input
                                  placeholder={label}
                                  label={label}
                                  checked={checked}
                                  containerClass="perm"
                                  onChange={(e) => {
                                    if (checked) {
                                      setPermissions((old) =>
                                        old.filter((s) => s !== perm.id)
                                      );
                                    } else {
                                      setPermissions((old) => [
                                        ...old,
                                        perm.id,
                                      ]);
                                    }
                                  }}
                                  type="checkbox"
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default function UpdateRole() {
  const { item } = useRoleFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateRole item={item} />;
}
