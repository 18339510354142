import moment from "moment";
import { Link } from "react-router-dom";
import { LoaderPage } from "../../components/Loader";
import { Competition, Equipe, Federation } from "../../types";
import {
  FollowButton,
  getFN,
  getImage,
  useLoadMoreQuery,
} from "../../utils/api/common.api";
import {
  CompetitionApi,
  CompetitionQuery,
} from "../../utils/api/competition.api";
import { EquipeApi, EquipeQuery } from "../../utils/api/equipe.api";
import { useFedeFromLocation } from "../../utils/api/fedes.api";
import { ParticipeApi, ParticipeQuery } from "../../utils/api/participe.api";
import { AthletesList } from "../equipe/detailEquipe";
import CompetitionItem from "./components/CompetitionItem";

const ShowFedeComponent = ({ item }: { item: Federation }) => {
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="grid-box">
                  <div className="title-section d-flex justify-content-beetwen">
                    <h1>
                      <span>
                        {item.nom} - {item.sport.name}
                      </span>
                    </h1>
                    <FollowButton subscriberId={item.id} field="federation" />
                  </div>

                  <ul className="autor-list">
                    <li>
                      <div className="autor-box">
                        <img src={getImage(item.image)} alt="" />

                        <div className="autor-content">
                          <div className="autor-title d-flex flex-column">
                            <h1>Dirigé par {getFN(item.president)}</h1>
                            {/* <span className="d-flex gap-10 align-items-center">
                              <i className="fa fa-trophy"></i>
                              20 compétitions
                            </span> */}
                            <span className="d-flex gap-10 align-items-center">
                              <i className="fa fa-tags"></i>
                              {item.type}
                            </span>
                          </div>

                          <p>{item.description}</p>
                        </div>
                      </div>

                      <div className="autor-last-line">
                        <a href="#" className="autor-site">
                          {item.webSite}
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="title-section">
                    <h1>
                      <span>Compétitions</span>
                    </h1>
                  </div>
                  <CompetitionList federation={item.id} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className="sidebar">
                <div className="widget social-widget">
                  <div className="title-section">
                    <h1>
                      <span>Informations sur la fédération</span>
                    </h1>
                  </div>
                  <ul className="social-share">
                    <li className="d-flex align-items-center ">
                      <a href="#" className="twitter">
                        <i className="fa fa-envelope-o"></i>
                      </a>
                      <span className="number">{item.email}</span>
                    </li>

                    <li className="d-flex align-items-center ">
                      <a href="#" className="rss">
                        <i className="fa fa-phone"></i>
                      </a>
                      <span className="number">{item.telephone}</span>
                    </li>
                    <li className="d-flex align-items-center ">
                      <a href="#" className="rss">
                        <i className="fa fa-location-arrow"></i>
                      </a>
                      <span className="number">{item.address}</span>
                    </li>
                    <li className="d-flex align-items-center ">
                      <a href="#" className="rss">
                        <i className="fa fa-calendar-o"></i>
                      </a>
                      <span className="number">
                        {moment(item.createdDate).format("DD MMM YYYY")}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="widget tab-posts-widget">
                  <ul
                    className="nav nav-tabs"
                    id="myTab"
                    style={{ marginBottom: 10 }}
                  >
                    <li className="active">
                      <a href="#option1" data-toggle="tab">
                        Équipes
                      </a>
                    </li>
                    <li className="">
                      <a href="#option2" data-toggle="tab">
                        Athlètes
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane active" id="option1">
                      <ListEquipes federation={item.id} />
                    </div>
                    <div className="tab-pane" id="option2">
                      <AthletesList showTitle={false} federation={item.id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default function DetailFederation() {
  const { item } = useFedeFromLocation();
  if (!item) return <LoaderPage />;
  return <ShowFedeComponent item={item} />;
}

export function ListEquipes(props: EquipeQuery & { showMoreButton?: boolean }) {
  const { renderButton, data, isLoading } = useLoadMoreQuery<
    Equipe,
    EquipeQuery
  >(EquipeApi.useEquipesQuery, {
    ...props,
    limit: 6,
  });

  return (
    <>
      <ul className="list-posts">
        {data.map((c) => (
          <EquipeItem item={c} key={c.id} />
        ))}
      </ul>
      {props?.showMoreButton !== false && renderButton()}
    </>
  );
}

export function ListEquipesSingle(props: ParticipeQuery) {
  const { renderButton, data, isLoading } = useLoadMoreQuery<
    Equipe,
    ParticipeQuery
  >(ParticipeApi.useSinglesQuery, {
    ...props,
    limit: 6,
  });

  return (
    <>
      <ul className="list-posts">
        {!isLoading && data.length === 0 && <p>Pas d'équipe</p>}
        {data.map((c) => (
          <EquipeItem item={c} key={c.id} />
        ))}
      </ul>
      {renderButton()}
    </>
  );
}

export function EquipeItem({ item }: { item: Equipe }) {
  return (
    <li>
      <img
        src={getImage(item.image)}
        alt=""
        style={{ width: 80, height: 80, objectFit: "contain" }}
      />
      <div className="post-content">
        <h2>
          <Link to={`/team/${item.slug}`}>{item.nom}</Link>
        </h2>
        <ul className="post-tags d-flex flex-column">
          <li>
            <i className="fa fa-info-circle"></i>
            {item.type}
          </li>
          <li>
            <i className="fa fa-tags"></i>
            {item.niveau.name}
          </li>
        </ul>
      </div>
    </li>
  );
}

export function CompetitionList(props: CompetitionQuery) {
  const { renderButton, data, isLoading } = useLoadMoreQuery<
    Competition,
    CompetitionQuery
  >(CompetitionApi.useCompetitionsQuery, {
    ...props,
    limit: 6,
  });
  return (
    <>
      <div className="row">
        {data.map((c) => (
          <CompetitionItem key={c.id} item={c} />
        ))}
      </div>
      {renderButton()}
    </>
  );
}
