import { useUser } from "../../../redux/store";
import { Equipe } from "../../../types";
import { usePaginationQuery } from "../../../utils/api/common.api";
import { EquipeApi, EquipeQuery } from "../../../utils/api/equipe.api";
import BreadCrumb from "../components/BreadCrumb";
import EquipeTable from "./equipe-table";

const Equipes = () => {
  const user = useUser();
  const props = usePaginationQuery<Equipe, EquipeQuery>(
    EquipeApi.useEquipesQuery,
    { federation: user?.federation?.id },
    { sport: true, niveau: true, federation: !user?.federation }
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Equipes"
        subTitle="Liste des équipes"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Toutes les équipes",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des équipes ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <EquipeTable {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Equipes;
