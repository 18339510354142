import moment from "moment";
import DashboardItem from "../../../components/DashboardItem";
import { LoaderPage } from "../../../components/Loader";
import { Match, Participe, User } from "../../../types";
import {
  getFN,
  getImage,
  usePaginationQuery,
  useQuery,
} from "../../../utils/api/common.api";
import { MatchApi, MatchSelectQuery } from "../../../utils/api/match.api";
import { ParticipeApi, ParticipeQuery } from "../../../utils/api/participe.api";
import { UserApi, useUserFromLocation } from "../../../utils/api/user.api";
import { useTabsControl } from "../../../utils/hooks";
import CompetitionTable from "../competitions/competition-table";
import { DisplayMatches } from "../competitions/matches/match-table";
import BreadCrumb from "../components/BreadCrumb";

type DetailAthleteProps = {
  item: User;
};

type Dashboard = {
  competitions: number;
  matches: number;
  buts: number;
};

type Data = {
  title: string;
  component: any;
  bg: string;
  icon: any;
};

const DetailAthleteComponent = ({ item }: DetailAthleteProps) => {
  const { active, setActive } = useTabsControl(0);
  const { data: dashboard } = UserApi.usePerformencesQuery(item.id);

  const DATA: Data[] = [
    {
      title: "Infos",
      component: Infos,
      bg: "bg-warning",
      icon: <i className="fa fa-users" />,
    },
    {
      title: "Compétitions",
      component: Competitions,
      bg: "bg-primary",
      icon: <i className="fa fa-area-chart" />,
    },
    {
      title: "Matches",
      component: Matches,
      bg: "bg-warning",
      icon: <i className="fa fa-area-chart" />,
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Athlétes"
        subTitle={"Détail athlète"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: getFN(item),
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row mt-5 pb-5">
            <DashboardItem
              cols={4}
              title={DATA[1].title}
              bg={DATA[1].bg}
              count={dashboard?.competitions ?? 0}
              icon={DATA[1].icon}
            />
            <DashboardItem
              cols={4}
              title={DATA[2].title}
              bg={DATA[2].bg}
              count={dashboard?.matches ?? 0}
              icon={DATA[2].icon}
            />
            <DashboardItem
              cols={4}
              title={item.sport.typeScore}
              bg={"bg-success"}
              count={dashboard?.buts ?? 0}
              icon={<i className="fa fa-area-chart" />}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-body">
                  <div className="panel-body p-20">
                    <ul className="tabs">
                      {DATA.map((ut, index) => (
                        <li
                          role="presentation"
                          className={index === active ? "active" : ""}
                        >
                          <a
                            href={`#${ut.title}`}
                            onClick={() => setActive(index)}
                            aria-controls={ut.title}
                            role="tab"
                            style={{ fontSize: 12 }}
                            data-toggle="tab"
                          >
                            {ut.title}
                          </a>
                        </li>
                      ))}
                    </ul>

                    <div className="tabs-container">
                      {DATA.map(({ title, component: Component }, index) => (
                        <div
                          role="tabpanel"
                          className={`${index === active ? "active" : ""}`}
                          id={title}
                          key={index}
                        >
                          <Component item={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Infos = ({ item }: DetailAthleteProps) => {
  const data: DataItem[] = [
    { title: "Prénom", value: item.prenom },
    { title: "Nom", value: item.nom },
    { title: "Email", value: item.email },
    { title: "Téléphone", value: item.telephone },
    { title: "Fédération", value: item.federation?.nom },
    { title: "Equipe", value: item.equipe?.nom ?? "-" },
    { title: "Catégorie", value: item.niveau?.name },
    { title: "Sport", value: item.sport?.name },
    {
      title: "Date de naissance",
      value: moment(item.birthDate).format("DD/MM/YYYY"),
    },
    { title: "Nom familles", value: item.nomsFamille },
    { title: "Adresse", value: item.address },
    { title: "Nationalité", value: item.nationality },
    {
      title: "Photo d'identité",
      value: (
        <a target="_blank" href={getImage(item.image)} rel="noreferrer">
          <img
            src={getImage(item.image)}
            alt=""
            style={{ width: "auto", maxHeight: 200 }}
          />
        </a>
      ),
    },
    {
      title: "Pièces d’identité",
      value: (
        <a target="_blank" href={getImage(item.idCard)} rel="noreferrer">
          <img
            src={getImage(item.idCard)}
            alt=""
            style={{ width: "auto", maxHeight: 200 }}
          />
        </a>
      ),
    },
    {
      title: "Licence",
      value: (
        <a target="_blank" href={getImage(item.document)} rel="noreferrer">
          <iframe
            title="Licence"
            src={getImage(item.document)}
            width={"100%"}
            frameBorder={0}
            height={200}
          />
        </a>
      ),
    },
    {
      title: "Dernière modification",
      value: moment(item.updatedAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Modifiée par",
      value: getFN(item.updatedBy),
    },
  ];
  if (item?.federation?.type === "Individuel") {
    data.splice(5, 1);
  }
  return <DataList data={data} />;
};

export default function DetailAthlete() {
  const { item } = useUserFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <DetailAthleteComponent item={item} />;
}

export type DataItem = {
  title: string;
  value: any;
};
type DataListProps = {
  data: DataItem[];
};
export function DataList({ data }: DataListProps) {
  return (
    <div className="row">
      {data.map((item) => {
        return (
          <div
            style={{ marginBottom: 20 }}
            className="col-md-6 col-xs-12"
            key={item.title}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                marginBottom: 7.5,
              }}
            >
              {item.title}
            </div>
            <div style={{ fontSize: "1.2rem" }}>{item.value}</div>
          </div>
        );
      })}
    </div>
  );
}

function Competitions({ item }: DetailAthleteProps) {
  const query: ParticipeQuery = item?.equipe
    ? { equipe: item.equipe.id }
    : { athlete: item.id };
  const props = usePaginationQuery<Participe, ParticipeQuery>(
    ParticipeApi.useParticipesQuery,
    query
  );

  return (
    <CompetitionTable {...props} data={props.data.map((i) => i.competition)} />
  );
}

function Matches({ item }: DetailAthleteProps) {
  const query: MatchSelectQuery = item?.equipe
    ? { equipe: item.equipe.id }
    : { athlete: item.id };
  const props = useQuery<Match, MatchSelectQuery>(
    MatchApi.useSelectQuery,
    query
  );

  return <DisplayMatches data={props.data} isLoading={props.isLoading} />;
}
