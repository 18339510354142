import { useEffect, useState } from "react";
import {
  TableHeader,
  TableWithoutPagination,
} from "../../../../components/Table";
import { Competition, Match, Participe } from "../../../../types";
import { getCompetTypeLabel, getParticipeLabel } from "../../../../utils/hooks";

type ClassmentsProps = {
  participes: Participe[];
  matches: Match[];
  isLoading: boolean;
  competition: Competition;
};

type ClassmentRow = {
  id: number;
  rang: number;
  participe: Participe;
  bm: number;
  be: number;
  pts: number;
  mj: number;
  mp: number;
  mg: number;
  mn: number;
  db: number;
};

const ClassmentsTable = ({
  isLoading,
  matches,
  participes,
  competition,
}: ClassmentsProps) => {
  const [data, setData] = useState<ClassmentRow[]>([]);
  const { type, sport } = competition;
  useEffect(() => {
    const data: ClassmentRow[] = [];
    for (let item of participes) {
      const [bm, be, mg, mn, mp, db, pts, mj] = matches.reduce(
        (acc, cur) => {
          if (
            (cur.participeDom.id === item.id ||
              cur.participeExt.id === item.id) &&
            cur.status !== "En attente"
          ) {
            if (cur.matchNul) {
              acc[3] += 1;
              acc[6] += sport.pointsNull;
            } else if (cur.winner?.id === item.id) {
              acc[2] += 1;
              acc[6] += sport.pointsVictoire;
            } else {
              acc[4] += 1;
              acc[6] += sport.pointsDefaite;
            }
            const bm = cur.scores
              .filter((s) => s.participe.id === item.id)
              .reduce((acc, cur) => acc + cur.nbre, 0);
            const be = cur.scores
              .filter((s) => s.participe.id !== item.id)
              .reduce((acc, cur) => acc + cur.nbre, 0);

            acc[0] += bm;
            acc[1] += be;
            acc[5] = acc[0] - acc[1];
            acc[7] += 1;
          }
          return acc;
        },
        [0, 0, 0, 0, 0, 0, 0, 0]
      );

      data.push({
        be,
        bm,
        db,
        id: item.id,
        mg,
        mn,
        mp,
        participe: item,
        pts,
        mj,
        rang: 0,
      });
    }
    setData(
      data
        .sort((a, b) => {
          if (a.pts === b.pts) return b.db - a.db;
          return b.pts - a.pts;
        })
        .map((c, index) => ({ ...c, rang: index + 1 }))
    );
  }, [matches, participes]);
  const headers: TableHeader<ClassmentRow>[] = [
    {
      name: "participe",
      title: getCompetTypeLabel(type),
      formatter: (i) => `${i.rang}) ${getParticipeLabel(i.participe)}`,
    },
    { name: "pts", title: "PTS" },
    { name: "mj", title: "MJ" },
    { name: "mp", title: "MP" },
    { name: "mg", title: "MG" },
    { name: "mn", title: "MN" },
    { name: "bm", title: "BM" },
    { name: "be", title: "BE" },
    { name: "db", title: "DB" },
  ];
  return (
    <TableWithoutPagination
      keyField={"id"}
      data={data}
      isLoading={isLoading}
      headers={headers}
    />
  );
};

export default ClassmentsTable;
