import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import { LoaderPage } from "../../../../components/Loader";
import { useAppDispatch } from "../../../../redux/store";
import { Competition, Equipe, Participe } from "../../../../types";
import { getId, useQuery } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import { EquipeApi, EquipeQuery } from "../../../../utils/api/equipe.api";
import { ParticipeApi } from "../../../../utils/api/participe.api";
import { COLORS } from "../../../../utils/constants";
import BreadCrumb from "../../components/BreadCrumb";
import { ChoiceEquipeTable } from "../../equipes/equipe-table";

const SelectEquipeComponent = ({
  competition,
}: {
  competition: Competition;
}) => {
  const { disabled, onSubmit, loading, selections, setSelections, props } =
    useSelectEquipeForm(competition, "equipe");
  return (
    <div className="main-page">
      <BreadCrumb
        title="Equipes"
        subTitle={"Choisir les équipes"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: "Choisir les équipes",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <h5>Liste des équipes </h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <Button
                          type="button"
                          className="btn bg-black btn-wide"
                          title={`Ajouter les ${selections.length} équipes`}
                          loading={loading}
                          disabled={disabled}
                          onClick={() => {
                            onSubmit();
                          }}
                          icon={<i className="fa fa-arrow-right" />}
                        />
                      </div>
                      <div className="col-md-2">
                        <p>
                          {selections.length}/{competition.nbreEquipe}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <ChoiceEquipeTable
                    selections={selections}
                    setSelections={setSelections}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function SelectEquipe() {
  const { item: comp } = useCompetitionFromLocation();
  if (!comp) return <LoaderPage />;
  return <SelectEquipeComponent competition={comp} />;
}

export default SelectEquipe;

export const useSelectEquipeForm = (
  competition: Competition,
  field: keyof Pick<Participe, "athlete" | "equipe">
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [onAdd] = ParticipeApi.useMajMutation();
  const [onDelete] = ParticipeApi.useDeleteMutation();

  const [loading, setLoading] = useState(false);

  const onDone = () => {
    Swal.fire({
      icon: "success",
      iconColor: COLORS.primary,
      confirmButtonColor: COLORS.primary,
      title: `Données ajoutés au compétition avec succès`,
    });
    navigate(-1);
    dispatch(
      ParticipeApi.util.invalidateTags([
        "participes",
        "selects",
        "participeItem",
      ])
    );
  };

  const [selections, setSelections] = useState<number[]>([]);
  const { data: participes } = ParticipeApi.useParticipesSelectQuery({
    competition: competition.id,
  });
  const props = useQuery<Equipe, EquipeQuery>(EquipeApi.useEquipesSelectQuery, {
    federation: getId(competition.federation),
    sport: competition.sport.id,
    type: competition.sexe,
  });

  const onSubmit = async () => {
    setLoading(true);
    const toDeletes: number[] = [];
    const toAdds: number[] = [];
    if (participes) {
      for (let p of participes) {
        if (!selections.includes(p[field].id)) {
          toDeletes.push(p.id);
        }
      }
      for (let s of selections) {
        const index = participes.findIndex((p) => p[field].id === s);
        if (index === -1) {
          toAdds.push(s);
        }
      }
    }

    for (let item of toAdds) {
      await onAdd({ data: { competition: competition.id, [field]: item } });
    }

    for (let item of toDeletes) {
      await onDelete(item);
    }
    onDone();

    setLoading(false);
  };

  useEffect(() => {
    if (participes) {
      setSelections(participes.map((p) => p[field].id));
    }
  }, [participes]);

  const disabled = selections.length != competition.nbreEquipe;

  return {
    disabled,
    participes,
    onSubmit,
    loading,
    selections,
    setSelections,
    props,
  };
};
