import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { Saison, SaisonFormData } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import {
  SaisonApi,
  useSaisonFromLocation,
} from "../../../utils/api/saison.api";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import BreadCrumb from "../components/BreadCrumb";

type CreateSaisonProps = {
  item?: Saison;
};
const CreateSaison = ({ item }: CreateSaisonProps) => {
  const navigate = useNavigate();
  const [exec, { isLoading }] = SaisonApi.useMajMutation();
  const { setErrors, register, onSubmitForm } = useFormData<SaisonFormData>(
    {
      name: item?.name || "",
    },
    (yup) => ({
      name: yup.string().required().label("Nom"),
    })
  );

  const onSubmit = React.useCallback(
    async (data: SaisonFormData) => {
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          name:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Saison ${item ? "modifié" : "ajouté"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Saison"
        subTitle={
          item
            ? "Formulaire de modification de saison"
            : "Formulaire de création de saison"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: item ? "Modification de saison" : "Création de saison",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} de
                      saison
                    </h5>
                    {!!item && (
                      <>
                        <div>
                          Dernière modification:{" "}
                          {moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                        </div>
                        <div>Modifiée par: {getFN(item.updatedBy)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("name")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateSaison;

export function UpdateSaison() {
  const { item } = useSaisonFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateSaison item={item} />;
}
