import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import { Federation } from "../../types";
import { FedesApi } from "../../utils/api/fedes.api";
import FederationItem from "./components/FederationItem";

const Federations = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const [fedes1, setFedes1] = useState<Federation[]>([]);
  const [fedes2, setFedes2] = useState<Federation[]>([]);
  const [fedes3, setFedes3] = useState<Federation[]>([]);

  const { data, isLoading } = FedesApi.useFederationsSelectQuery();

  useEffect(() => {
    if (data) {
      const sport = params.get("sport");
      let filtred = [...data];
      if (sport) {
        filtred = filtred.filter((f) => f.sport.slug === sport);
      }
      setFedes1(filtred.filter((f) => f.type === "Equipe"));
      setFedes2(filtred.filter((f) => f.type === "Individuel"));
      setFedes3(filtred.filter((f) => f.type === "Both"));
    }
  }, [data, location.search, params]);
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="forum-box">
                  <div className="title-section">
                    <h1>
                      <span>Fédérations</span>
                    </h1>
                  </div>

                  <ListFedes title="Équipe" data={fedes1} />
                  <ListFedes title="Individuel" data={fedes2} />
                  <ListFedes title="Équipe & Individuel" data={fedes3} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Federations;

function ListFedes({ data, title }: { data: Federation[]; title: string }) {
  return (
    <div className="forum-table">
      <div className="table-title">
        <h2>
          <a href="#">{title}</a>
        </h2>
        {data.length > 0 && <p>Nos fédérations</p>}
        {data.length === 0 && <p>Pas de fédérations</p>}
      </div>
      {data.map((c) => (
        <FederationItem item={c} key={c.id} />
      ))}
    </div>
  );
}
