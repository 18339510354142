import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { LoaderPage } from "../../../../components/Loader";
import { Competition, Groupe, GroupeFormData, Phase } from "../../../../types";
import { getFN } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import {
  GroupeApi,
  useGroupeFromLocation,
} from "../../../../utils/api/groupe.api";
import { usePhaseFromLocation } from "../../../../utils/api/phase.api";
import { COLORS } from "../../../../utils/constants";
import { useFormData } from "../../../../utils/form";
import { getCompetTypeLabel } from "../../../../utils/hooks";
import BreadCrumb from "../../components/BreadCrumb";

type CreateGroupeProps = {
  item?: Groupe;
  phase: Phase;
  competition: Competition;
};
const CreateGroupeComponent = ({
  item,
  competition,
  phase,
}: CreateGroupeProps) => {
  const navigate = useNavigate();

  const label = getCompetTypeLabel(competition.type);

  const [exec, { isLoading }] = GroupeApi.useMajMutation();
  const { setErrors, register, onSubmitForm, data } =
    useFormData<GroupeFormData>(
      {
        name: item?.name || "",
        nbreEquipe: "",
        phase: phase.id,
      },
      (yup) => ({
        name: yup.string().required().label("Nom"),
        nbreEquipe: yup.string().required().label(`Nombre d'${label}`),
      })
    );

  const onSubmit = React.useCallback(
    async (data: GroupeFormData) => {
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          name:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Groupe ${item ? "modifié" : "ajouté"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Groupes"
        subTitle={
          item
            ? "Formulaire de modification de groupe"
            : "Formulaire de création de groupe"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: phase.name,
            route: `/dashboard/competitions/${competition.slug}/phases/${phase.id}`,
          },
          {
            title: item ? "Modification de groupe" : "Création de groupe",
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} de
                      groupe
                    </h5>
                    {!!item && (
                      <>
                        <div>
                          Dernière modification:{" "}
                          {moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                        </div>
                        <div>Modifiée par: {getFN(item.updatedBy)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("name")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          placeholder={`Nombre d'${label}`}
                          label={`Nombre d'${label}`}
                          {...register("nbreEquipe")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function CreateGroupe({ item }: { item?: Groupe }) {
  const { item: comp } = useCompetitionFromLocation();
  const { item: phase } = usePhaseFromLocation();
  if (!comp || !phase) return <LoaderPage />;
  return <CreateGroupeComponent phase={phase} item={item} competition={comp} />;
}

export default CreateGroupe;

export function UpdateGroupe() {
  const { item } = useGroupeFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateGroupe item={item} />;
}
