import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { ForgetPasswordFormData } from "../../types";
import { AuthApi } from "../../utils/api/auth.api";
import { COLORS } from "../../utils/constants";
import { useFormData } from "../../utils/form";

const ForgetPassword = () => {
  const [exec, { isLoading }] = AuthApi.useForgetPasswordMutation();
  const { register, setErrors, onSubmitForm } =
    useFormData<ForgetPasswordFormData>(
      {
        email: "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
      })
    );
  const navigate = useNavigate();
  const onSubmit = React.useCallback(async (data: ForgetPasswordFormData) => {
    const res = await exec(data);
    if ("data" in res) {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.primary,
        confirmButtonColor: COLORS.primary,
        title: "Un code vous a été envoyé avec succès",
      });
      navigate(`/auth/reset-password?email=${data.email}`);
    } else {
      setErrors({
        email: "Utilisateur non trouvé",
      });
    }
  }, []);

  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="block-content">
              <div className="grid-box">
                <div className="contact-form-box">
                  <div className="title-section">
                    <h1>
                      <span>Mot de passe oublié</span>
                    </h1>
                  </div>
                  <form id="contact-form" onSubmit={onSubmitForm(onSubmit)}>
                    <div className="row">
                      <Input
                        type="email"
                        required
                        label="Email"
                        containerClass="col-md-12"
                        {...register("email")}
                      />
                    </div>
                    <Button
                      title="Envoyer"
                      id="submit-contact"
                      icon={<i className="fa fa-paper-plane" />}
                      type="submit"
                      loading={isLoading}
                    />
                    &nbsp;&nbsp;
                    <span>
                      <Link to="/auth/se-connecter">Se connecter</Link>
                    </span>
                  </form>
                </div>
              </div>

              {/* <GoogleAds /> */}
            </div>
          </div>

          {/* <div className="col-sm-4">
            <SideBar />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
