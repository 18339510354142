import BreadCrumb from "../pages/admin/components/BreadCrumb";

export const LoaderPage = () => {
  return (
    <div className="main-page">
      <BreadCrumb
        title="Chargements..."
        subTitle={"Chargements en cours..."}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Chargements...",
          },
        ]}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 400,
        }}
      >
        <h4>Chargements...</h4>
      </div>
    </div>
  );
};
