import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { ChangePasswordFromData } from "../../../types";
import { AuthApi } from "../../../utils/api/auth.api";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import BreadCrumb from "../components/BreadCrumb";

const UpdatePassword = () => {
  const navigate = useNavigate();

  const [exec, { isLoading }] = AuthApi.useChangePasswordMutation();
  const { data, onChange, errors, onSubmitForm, setErrors, register } =
    useFormData<ChangePasswordFromData>(
      {
        newPassword: "",
        newPasswordConfirm: "",
        oldPassword: "",
      },
      (Yup) => ({
        oldPassword: Yup.string().required().label("Ancien mot de passe"),
        newPassword: Yup.string().required().label("Mot de passe"),
        newPasswordConfirm: Yup.string().oneOf(
          [Yup.ref("newPassword"), null],
          "Les mots de passe ne correspondent pas"
        ),
      })
    );

  const onSubmit = React.useCallback(
    async (data: ChangePasswordFromData) => {
      // @ts-ignore
      const { error } = await exec(data);
      console.log(error);
      if (error) {
        setErrors({
          oldPassword:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: "Votre mot de passe a été modifier avec succès",
        });
        onChange({ oldPassword: "", newPassword: "", newPasswordConfirm: "" });
      }
    },
    [data]
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Profil"
        subTitle={"Modification de mot de passe"}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: "Modification de mot de passe",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>GESTION ADMIN - Modification de mot de passe</h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          placeholder={"Ancien mot de passe"}
                          label="Ancien mot de passe"
                          {...register("oldPassword")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="password"
                        />
                      </div>

                      <div className="col-md-12">
                        <Input
                          placeholder={"Nouveau mot de passe"}
                          label="Nouveau mot de passe"
                          {...register("newPassword")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="password"
                        />
                      </div>

                      <div className="col-md-12">
                        <Input
                          placeholder={"Confirmer mot de passe"}
                          label="Confirmer mot de passe"
                          {...register("newPasswordConfirm")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="password"
                        />
                      </div>

                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdatePassword;
