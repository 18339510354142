import { Link } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import { CompetitionApi } from "../../utils/api/competition.api";
import { FedesApi } from "../../utils/api/fedes.api";
import { UserApi } from "../../utils/api/user.api";
import AthleteItem from "./components/AthleteItem";

import CompetitionItem from "../federation/components/CompetitionItem";
import FederationItem from "../federation/components/FederationItem";
import { ListEquipes } from "../federation/detailFederation";
import News from "./News";
import TopCompet from "./TopCompet";

const HomePage = () => {
  return (
    <>
      <section className="heading-news2">
        <div className="container">
          <News />
          <TopCompet />
        </div>
      </section>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              {/* <!-- block content --> */}
              <div className="block-content">
                {/* <!-- grid box --> */}
                <div className="grid-box">
                  <div className="title-section">
                    <h1>
                      <span className="world">Fédérations </span>
                    </h1>
                  </div>

                  <Fedes />
                </div>
                <div className="grid-box">
                  <div className="title-section">
                    <h1>
                      <span>Nos équipes</span>
                    </h1>
                  </div>

                  <ListEquipes showMoreButton={false} />
                  <div className="center-button">
                    <Link to={"/team"}>
                      <i className="fa fa-refresh"></i> Voir toutes les équipes
                    </Link>
                  </div>
                </div>
                <div className="grid-box">
                  <div className="title-section">
                    <h1>
                      <span>Nos athlètes</span>
                    </h1>
                  </div>

                  <Athletes />
                </div>
                {/* <!-- End grid-box --> */}

                {/* <!-- carousel box --> */}
                <div className="carousel-box">
                  <div className="title-section">
                    <h1>
                      <span className="tech">Compétitions</span>
                    </h1>
                  </div>

                  <Compets />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;

function Fedes() {
  const { data } = FedesApi.useFederationsQuery({ limit: 6 });
  const fedes = data?.results;
  return (
    <>
      <div className="forum-table">
        {fedes?.map((c) => (
          <FederationItem key={c.id} item={c} />
        ))}
      </div>
      <div className="center-button">
        <Link to={"/federation"}>
          <i className="fa fa-refresh"></i> Voir toutes les fédérations
        </Link>
      </div>
    </>
  );
}

function Athletes() {
  const { data } = UserApi.useUsersQuery({ userType: ["Athlète"], limit: 6 });
  return (
    <>
      <div className="row">
        {data?.results?.map((c) => (
          <AthleteItem key={c.id} item={c} />
        ))}
      </div>
      <div className="center-button">
        <Link to={"/athlete"}>
          <i className="fa fa-refresh"></i> Voir tous les athlètes
        </Link>
      </div>
    </>
  );
}

function Compets() {
  const { data, isLoading } = CompetitionApi.useCompetitionsQuery({
    limit: 6,
    isValidated: true,
  });
  return (
    <>
      <div className="row" style={{ marginBottom: 20 }}>
        {data?.results?.map((c) => (
          <CompetitionItem item={c} key={c.id} />
        ))}
      </div>

      <div className="center-button">
        <Link to={"/competition"}>
          <i className="fa fa-refresh"></i> Voir toutes les compétitions
        </Link>
      </div>
    </>
  );
}
