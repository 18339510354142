import Table, {
  ApprouveButton,
  DeleteButton,
  TableHeader,
  ViewButton,
} from "../../../components/Table";
import { MessageContact } from "../../../types";
import { CommonQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { ContactApi } from "../../../utils/api/contact.api";
import BreadCrumb from "../components/BreadCrumb";

const Contacts = () => {
  const props = usePaginationQuery<MessageContact, CommonQuery>(
    ContactApi.useMessageContactsQuery,
    {}
  );
  const [onDelete] = ContactApi.useDeleteMutation();

  const [maj] = ContactApi.useMajMutation();

  const headers: TableHeader<MessageContact>[] = [
    { name: "name", title: "Prénom & Nom" },
    { name: "email", title: "Email" },
    {
      name: "message",
      title: "Message",
      formatter: (item) => item.message?.slice(0, 20) + "...",
    },
    {
      name: "status",
      title: "Statut",
      formatter: (item) => {
        const traite = item.status === "Traité";

        return (
          <button
            type="button"
            className={`btn btn-${
              traite ? "success" : "danger"
            } btn-xs btn-labeled`}
          >
            {traite ? "Traité" : "Non traité"}
            <span className="btn-label btn-label-right">
              <i className={`fa fa-${traite ? "check" : "close"}`}></i>
            </span>
          </button>
        );
      },
    },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <ViewButton
            permission="president-fede.create"
            route={`/dashboard/contacts/${item.id}`}
            state={item}
          />
          <span style={{ display: "inline-block", margin: "0px 5px" }}>
            <DeleteButton
              id={item.id}
              permission="president-fede.create"
              deleteFunction={onDelete}
              error={"Une erreur est survenue lors de la suppression"}
              success={"Message supprimé avec succès"}
            />
          </span>
          {item.status === "Nouveau" && (
            <ApprouveButton
              error="Une erreur est survenue"
              permission={"president-fede.create"}
              title="Traité"
              success="Message contact traité avec succès"
              approuvFunction={() =>
                maj({
                  id: item.id,
                  data: { status: "Traité" },
                })
              }
            />
          )}
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Message de contacts"
        subTitle="Liste des message de contacts"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Message de contacts" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Message de contacts ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contacts;
