import { Link } from "react-router-dom";
import { DisplayAthletes } from "../pages/equipe/detailEquipe";
import { UserApi } from "../utils/api/user.api";
import { useAppConfig } from "../utils/hooks";

const Footer = () => {
  const socials = useAppConfig().filter((f) => f.type === "social" && f.link);
  return (
    <footer>
      <div className="container">
        <div className="footer-widgets-part">
          <div className="row">
            <div className="col-md-3">
              <div className="widget text-widget">
                <h1>About</h1>
                <p>
                  Donec nec justo eget felis facilisis fermentum. Aliquam
                  porttitor mauris sit amet orci. Aenean dignissim pellentesque
                  felis.{" "}
                </p>
                <p>
                  Morbi in sem quis dui placerat ornare. Pellentesque odio nisi,
                  euismod in, pharetra a, ultricies in, diam. Sed arcu.{" "}
                </p>
              </div>
              <div className="widget social-widget">
                <h1>Stay Connected</h1>
                <ul className="social-icons">
                  {socials.map((s) => (
                    <li key={s.title}>
                      <a className="facebook" target="blank" href={s.link}>
                        {s.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="widget posts-widget">
                <h1>Random Athlète</h1>
                <RandomAthletes />
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget flickr-widget">
                <h1>Flickr Photos</h1>
                <ul className="flickr-list">
                  <li>
                    <a href="#">
                      <img src="upload/flickr/1.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="upload/flickr/2.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="upload/flickr/3.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="upload/flickr/4.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="upload/flickr/5.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="upload/flickr/6.jpg" alt="" />
                    </a>
                  </li>
                </ul>
                <a href="#">View more photos...</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-last-line">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <p>&copy; COPYRIGHT 2023 epastelsoft.com</p>
            </div>
            <div className="col-md-6 col-xs-12">
              <nav className="footer-nav">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="https://epastelsoft.com">Epastelsoft</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

function RandomAthletes() {
  const { data = [], isLoading } = UserApi.useRandomQuery();
  return (
    <DisplayAthletes
      data={data.slice(0, 4)}
      loading={isLoading}
      renderButton={() => null}
      showTitle={false}
    />
  );
}
