import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Table, { TableHeader } from "../../../components/Table";
import { SocialItem, useAppConfig } from "../../../utils/hooks";
import BreadCrumb from "../components/BreadCrumb";

const AppConfigs = () => {
  const configs = useAppConfig();
  const navigate = useNavigate();
  const headers: TableHeader<SocialItem>[] = [
    {
      name: "title",
      title: "Titre",
      formatter: (item) => (
        <p>
          {item.icon}
          {"  "}
          {item.title}
        </p>
      ),
    },
    {
      title: "Description",
      name: "link",
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Coordonnées"
        subTitle="Coordonnées"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Coordonnées" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Coordonnées</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <div style={{ marginBottom: 20 }}>
                    <Button
                      type="button"
                      className="btn bg-black btn-wide"
                      title="Modifier"
                      onClick={() => {
                        navigate(`/dashboard/config/update`);
                      }}
                      icon={<i className="fa fa-arrow-right" />}
                    />
                  </div>
                  <Table data={configs} headers={headers} keyField="title" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppConfigs;
