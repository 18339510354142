import {
  DeleteButton,
  UpdateButton,
  imageFormatter,
} from "../../../../components/Table";

import moment from "moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import Input, { Select } from "../../../../components/Input";
import { useAppDispatch } from "../../../../redux/store";
import {
  Match,
  Participe,
  Score,
  ScoreFormData,
  SelectOptions,
} from "../../../../types";
import { getFN } from "../../../../utils/api/common.api";
import { MatchApi } from "../../../../utils/api/match.api";
import { ScoreApi } from "../../../../utils/api/score.api";
import { useUsersSelect } from "../../../../utils/api/user.api";
import { COLORS } from "../../../../utils/constants";
import { useFormData } from "../../../../utils/form";
import { getCompetTypeLabel, getParticipeLabel } from "../../../../utils/hooks";
import { usePermissions } from "../../../../utils/permissions";

type DisplayMatchesProps = {
  data: Match[];
  isLoading?: boolean;
  showCompetitionName?: boolean;
};

export function DisplayMatches({
  data,
  showCompetitionName,
}: DisplayMatchesProps) {
  return (
    <div className="row">
      {data.map((item) => {
        if (showCompetitionName) {
          return (
            <div className="col-md-12 mb-5">
              <MatchLabel text={item.phase?.competition?.name} />
              <MatchItem key={item.id} item={item} />
            </div>
          );
        }
        return <MatchItem key={item.id} item={item} />;
      })}
    </div>
  );
}

function MatchItem({ item }: { item: Match }) {
  const { hasAccess } = usePermissions();
  const cmptSlug = item.phase.competition.slug;
  const phaseId = item.phase.id;
  const [onDelete] = MatchApi.useDeleteMutation();
  const [show, setShow] = useState(false);
  const [update, { isLoading }] = MatchApi.useMajMutation();
  return (
    <div className="col-md-12">
      <div className="row">
        <div
          className="col-md-12"
          style={{
            backgroundColor: "#f2f2f2",
            padding: "10px 5px",
            margin: "5px 0px",
          }}
        >
          <div className="col-md-8">
            {item.groupe && (
              <>
                <UpdateButton
                  route={`/dashboard/competitions/${cmptSlug}/phases/${phaseId}/groupe/${item.groupe.id}/matches/${item.id}/update`}
                  state={item}
                  permission="match.update"
                />
              </>
            )}

            {!item.groupe && (
              <>
                <UpdateButton
                  route={`/dashboard/competitions/${cmptSlug}/phases/${phaseId}/matches/${item.id}/update`}
                  state={item}
                  permission="match.update"
                />
              </>
            )}

            <DeleteButton
              permission="match.delete"
              id={item.id}
              deleteFunction={onDelete}
              error={"Une erreur est survenue lors de la suppression"}
              success={"Match supprimé avec succès"}
            />

            <div style={{ display: "inline-block" }}>
              {!item?.validated && (
                <>
                  {hasAccess(["score.create"]) &&
                    item.status === "En cours" && (
                      <span style={{ marginLeft: 5, display: "inline-block" }}>
                        <button
                          onClick={() => setShow((old) => !old)}
                          type="button"
                          className="btn btn-primary btn-xs btn-labeled"
                        >
                          Ajouter score
                          <span className="btn-label btn-label-right">
                            <i className="fa fa-plus"></i>
                          </span>
                        </button>
                      </span>
                    )}
                  {hasAccess(["match.start"]) &&
                    item.status === "En attente" && (
                      <span style={{ marginLeft: 5, display: "inline-block" }}>
                        <button
                          onClick={() =>
                            update({
                              id: item.id,
                              data: { status: "En cours" },
                            })
                          }
                          type="button"
                          disabled={isLoading}
                          style={{ margin: "0px 5px" }}
                          className="btn btn-warning btn-xs btn-labeled"
                        >
                          Match en cours
                          <span className="btn-label btn-label-right">
                            <i className="fa fa-rotate-right"></i>
                          </span>
                        </button>
                      </span>
                    )}
                  {hasAccess(["match.end"]) && item.status === "En cours" && (
                    <span style={{ marginLeft: 5, display: "inline-block" }}>
                      <button
                        onClick={() =>
                          update({ id: item.id, data: { status: "Terminé" } })
                        }
                        type="button"
                        disabled={isLoading}
                        style={{ margin: "0px 5px" }}
                        className="btn btn-success btn-xs btn-labeled"
                      >
                        Match terminé
                        <span className="btn-label btn-label-right">
                          <i className="fa fa-check"></i>
                        </span>
                      </button>
                    </span>
                  )}
                </>
              )}
              {item.status === "Terminé" &&
                hasAccess(["score.validate"]) &&
                !item.validated && (
                  <span style={{ marginLeft: 5, display: "inline-block" }}>
                    <button
                      onClick={() =>
                        update({
                          id: item.id,
                          data: { validated: true, status: "Terminé" },
                        })
                      }
                      type="button"
                      disabled={isLoading}
                      style={{ margin: "0px 5px" }}
                      className="btn btn-success btn-xs btn-labeled"
                    >
                      Valider le score
                      <span className="btn-label btn-label-right">
                        <i className="fa fa-check"></i>
                      </span>
                    </button>
                  </span>
                )}
            </div>
          </div>
          <div className="col-md-4 text-right">
            <MatchLabel fontSize="0.8rem" text={`Juge: ${getFN(item?.juge)}`} />
            <MatchLabel
              fontSize="0.8rem"
              text={`${moment(item.date).format("DD MMM YYYY")} - ${
                item.heure
              }`}
            />
          </div>
          <div
            className="col-md-12"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <EquipeItem
              allowDel={!item.validated && item.status !== "Terminé"}
              isDom
              item={item.participeDom}
              scores={item.scores}
            />
            <MatchScore item={item} />
            <EquipeItem
              isDom={false}
              allowDel={!item.validated && item.status !== "Terminé"}
              item={item.participeExt}
              scores={item.scores}
            />
          </div>
        </div>

        {show && (
          <AddScoreForm
            item={item}
            onHide={() => {
              setShow(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

function EquipeItem({
  item,
  isDom,
  scores,
  allowDel,
}: {
  item: Participe;
  isDom: boolean;
  scores: Score[];
  allowDel?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [onDelete] = ScoreApi.useDeleteMutation();
  const filtred = scores.filter((i) => i.participe.id === item.id);
  const img = (
    <div style={{ borderRadius: "50%", overflow: "hidden" }}>
      {imageFormatter(item.equipe)}
    </div>
  );
  return (
    <div className={"col-md-4"}>
      {item.equipe && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: isDom ? "flex-end" : "flex-start",
            }}
          >
            {!isDom && img}
            <MatchLabel text={item.equipe.nom} />
            {isDom && img}
          </div>
          {filtred.map((score) => {
            return (
              <div className={isDom ? "text-right" : "text-left"}>
                {getFN(score.athlete)} ({score.nbre} {score.typeScore})
                {score.csc ? "(csc)" : ""}{" "}
                {allowDel && (
                  <DeleteButton
                    permission="score.delete"
                    id={score.id}
                    deleteFunction={async (props: any) => {
                      const res = await onDelete(props);
                      dispatch(
                        MatchApi.util.invalidateTags([
                          "matchItem",
                          "matchs",
                          "select",
                        ])
                      );
                      return res;
                    }}
                    error={"Une erreur est survenue lors de la suppression"}
                    success={"Score supprimé avec succès"}
                  />
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

function MatchScore({ item }: { item: Match }) {
  const { totalScoreDom, totalScoreExt } = useMatchScores(item);

  return (
    <div className="col-md-4 text-center">
      <div style={{ height: 10 }} />
      {item.status !== "En attente" && (
        <div>
          <MatchLabel text={`${totalScoreDom} - ${totalScoreExt}`} />
          <div style={{ fontSize: 10 }}>({item.status})</div>
        </div>
      )}
      {item.status === "En attente" && <MatchLabel text={item.heure} />}
      {!item.validated && item.status === "Terminé" && (
        <MatchLabel fontSize="0.8rem" text={"Score non validé"} color="#F00" />
      )}
    </div>
  );
}

export function useMatchScores(item: Match, validated?: boolean) {
  const [totalScoreDom, setTotalScoreDom] = useState(0);
  const [totalScoreExt, setTotalScoreExt] = useState(0);

  useEffect(() => {
    (() => {
      if (validated && !item.validated) return;
      setTotalScoreDom(
        item.scores.reduce((acc, cur) => {
          if (cur.participe.id === item.participeDom.id) {
            return acc + cur.nbre;
          }
          return acc;
        }, 0)
      );
      setTotalScoreExt(
        item.scores.reduce((acc, cur) => {
          if (cur.participe.id === item.participeExt.id) {
            return acc + cur.nbre;
          }
          return acc;
        }, 0)
      );
    })();
  }, [item.scores]);

  return {
    totalScoreDom,
    totalScoreExt,
  };
}

function MatchLabel({
  text,
  fontSize = "1.2rem",
  color,
}: {
  text: string;
  fontSize?: string;
  color?: string;
}) {
  return (
    <span
      style={{
        display: "inline-block",
        margin: "0px 10px",
        fontSize: fontSize,
        fontWeight: "bold",
        color,
      }}
    >
      {text}
    </span>
  );
}

function AddScoreForm({ item, onHide }: { item: Match; onHide: () => void }) {
  const { typeScore } = item.phase.competition.sport;
  const label = `Nombre de ${typeScore}`;
  const cmtLabel = getCompetTypeLabel(item.phase.competition.type);
  const dispatch = useAppDispatch();
  const [exec, { isLoading }] = ScoreApi.useMajMutation();

  const { setErrors, register, onSubmitForm, onChange, data } =
    useFormData<ScoreFormData>(
      {
        athlete: "",
        match: item.id,
        nbre: "",
        participe: "",
        typeScore: typeScore,
        csc: false,
      },
      (yup) => ({
        athlete: yup.string().required().label("Athlète"),
        nbre: yup.string().required().label(label),
        participe: yup.string().required().label(cmtLabel),
      })
    );
  let selected: Participe | null = null;
  let selected1: Participe | null = null;
  if (data.participe) {
    selected =
      data.participe == item.participeDom?.id
        ? item.participeDom
        : item.participeExt;

    selected1 =
      selected.id == item.participeDom?.id
        ? item.participeExt
        : item.participeDom;
  }
  const { users } = useUsersSelect({
    userType: ["Athlète"],
    equipe: data.csc ? selected1?.equipe?.id : selected?.equipe?.id,
  });
  const DATA: SelectOptions = [
    {
      label: getParticipeLabel(item.participeDom),
      value: item.participeDom.id,
    },
    {
      label: getParticipeLabel(item.participeExt),
      value: item.participeExt.id,
    },
  ];
  const isIndu = item.phase.competition.type === "Individuel";
  useEffect(() => {
    if (selected?.id) {
      if (isIndu) {
        onChange("athlete", selected.athlete.id);
      }
    } else {
      onChange("athlete", "");
    }
  }, [selected?.id]);

  const onSubmit = React.useCallback(async (data: ScoreFormData) => {
    const res = await exec({ data });
    if ("error" in res) {
      const error = res.error as any;
      setErrors({
        participe:
          error.data?.message ||
          "Veuillez vous assurer d'entrer les bonnes données",
      });
    } else {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.primary,
        confirmButtonColor: COLORS.primary,
        title: `Score ajouté avec succès`,
      });
      dispatch(MatchApi.util.invalidateTags(["matchItem", "matchs", "select"]));
      onHide();
    }
  }, []);

  useEffect(() => {
    if (!isIndu) {
      onChange("athlete", "");
    }
  }, [data.csc]);
  return (
    <div className="col-md-12">
      <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
        <div className="row">
          <div className="col-md-12">
            <Select
              placeholder={cmtLabel}
              {...register("participe")}
              label={cmtLabel}
              containerClass="form-group"
              inputClass="form-control"
              options={DATA}
            />
          </div>
          <div className="col-md-12">
            <Input
              placeholder={label}
              label={label}
              {...register("nbre")}
              containerClass="form-group"
              inputClass="form-control"
              type="number"
            />
          </div>
          {!isIndu && !!selected && (
            <>
              <div className="col-md-12">
                <Input
                  placeholder={"Contre son camp?"}
                  label="Contre son camp?"
                  containerClass="perm"
                  {...register("csc")}
                  type="checkbox"
                />
              </div>
              <div className="col-md-12">
                <Select
                  placeholder={"Athlète"}
                  label="Athlète"
                  {...register("athlete")}
                  containerClass="form-group"
                  inputClass="form-control"
                  options={users}
                />
              </div>
            </>
          )}
          <div className="col-md-12">
            <div className="btn-group pull-right mt-10" role="group">
              <button
                type="reset"
                onClick={() => onHide()}
                className="btn btn-gray btn-wide"
              >
                <i className="fa fa-times"></i>Annuler
              </button>
              <Button
                type="submit"
                className="btn bg-black btn-wide"
                title="Enregistrer"
                loading={isLoading}
                icon={<i className="fa fa-arrow-right" />}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
