const SocialWidget = () => {
  return (
    <div className="widget social-widget">
      <div className="title-section">
        <h1>
          <span>Nos Réseaux sociaux</span>
        </h1>
      </div>
      <ul className="social-share">
        <li>
          <a href="#" className="rss">
            <i className="fa fa-rss"></i>
          </a>
          <span className="number">9,455</span>
          <span>Subscribers</span>
        </li>
        <li>
          <a href="#" className="facebook">
            <i className="fa fa-facebook"></i>
          </a>
          <span className="number">56,743</span>
          <span>Fans</span>
        </li>
        <li>
          <a href="#" className="twitter">
            <i className="fa fa-twitter"></i>
          </a>
          <span className="number">43,501</span>
          <span>Followers</span>
        </li>
        <li>
          <a href="#" className="google">
            <i className="fa fa-google-plus"></i>
          </a>
          <span className="number">35,003</span>
          <span>Followers</span>
        </li>
      </ul>
    </div>
  );
};

export default SocialWidget;
