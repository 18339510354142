// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Competition,
  DashboardData,
  Equipe,
  Federation,
  Permission,
  Role,
  RoleFormData,
  SelectOptions,
  User,
  UserFormData,
  UserPerformences,
} from "../../types";
import { ApiUrl } from "./URL";
import {
  CommonQuery,
  PaginationResults,
  QueryUrl,
  UserQuery,
  getFN,
  prepareFormData,
} from "./common.api";
import { prepareHeaders } from "./utils";

type SearchResults = {
  athletes: User[];
  equipes: Equipe[];
  federations: Federation[];
  competitions: Competition[];
};
// Define a service using a base URL and expected endpoints
export const UserApi = createApi({
  tagTypes: [
    "user",
    "users",
    "select",
    "userItem",
    "validates",
    "roles",
    "permissions",
    "performences",
    "dashboard",
    "search",
  ],
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<User, void>({
      query: () => ({
        url: `/user/me/`,
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    users: builder.query<PaginationResults<User>, UserQuery>({
      query: (query) => ({
        url: QueryUrl(`/user/`, query),
        method: "GET",
      }),
      providesTags: ["users"],
    }),
    random: builder.query<User[], void>({
      query: (query) => ({
        url: `/user/random-athlete`,
        method: "GET",
      }),
      providesTags: [{ type: "users", id: -1 }],
    }),
    roles: builder.query<Role[], void>({
      query: () => ({
        url: `/roles/`,
        method: "GET",
      }),
      providesTags: ["roles"],
    }),
    permissions: builder.query<Permission[], void>({
      query: () => ({
        url: `/permissions/`,
        method: "GET",
      }),
      providesTags: ["permissions"],
    }),
    performences: builder.query<UserPerformences, number>({
      query: (userId) => ({
        url: `/user/performences/${userId}`,
        method: "GET",
      }),
      providesTags: (a, b, userId) => [{ type: "performences", id: userId }],
    }),
    dashboard: builder.query<DashboardData, void>({
      query: () => ({
        url: `/dashboard`,
        method: "GET",
      }),
      providesTags: ["dashboard"],
    }),
    search: builder.query<SearchResults, { search: string }>({
      query: (query) => ({
        url: QueryUrl(`/dashboard/search`, query),
        method: "GET",
      }),
      providesTags: ["search"],
    }),
    toValidate: builder.query<PaginationResults<User>, CommonQuery>({
      query: (query) => ({
        url: QueryUrl(`/user/to-validate`, query),
        method: "GET",
      }),
      providesTags: ["validates"],
    }),
    userById: builder.query<User, string | number>({
      query: (id) => ({
        url: `/user/${id}/`,
        method: "GET",
      }),
      providesTags: ["userItem"],
    }),
    usersSelect: builder.query<User[], UserQuery>({
      query: (query) => ({
        url: QueryUrl(`/user/select/`, query),
        method: "GET",
      }),
      providesTags: ["select"],
    }),
    updateRole: builder.mutation<Role, { id: number; data: RoleFormData }>({
      query: ({ id, data }) => {
        return {
          url: `/roles/${id}/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["roles", "user"],
    }),
    maj: builder.mutation<User, { id?: number; data: FormData | UserFormData }>(
      {
        query: ({ id, data }) => {
          if (id) {
            return {
              url: `/user/${id}/`,
              method: "PUT",
              body: prepareFormData(data),
            };
          }
          return {
            url: `/user/`,
            method: "POST",
            body: prepareFormData(data),
          };
        },
        invalidatesTags: ["users", "user", "select", "userItem", "validates"],
      }
    ),
    delete: builder.mutation<User, number>({
      query: (id) => ({
        url: `/user/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["users", "user", "userItem", "select", "validates"],
    }),
  }),
});

type FilterFunc = (u: User) => boolean;
export function useUsersSelect(query: UserQuery, filterFunc?: FilterFunc) {
  const [items, setItems] = useState<SelectOptions>([]);
  const { data, refetch, isLoading } = UserApi.useUsersSelectQuery(query);
  useEffect(() => {
    if (data) {
      let items = [...data];
      if (filterFunc) {
        items = items.filter(filterFunc);
      }
      setItems(
        items.map((i) => ({
          value: i.id,
          label: `${getFN(i)} ${i.email}`,
        }))
      );
    }
  }, [data]);
  return {
    users: items,
    refetch,
    isLoading,
  };
}

export function useUserFromLocation() {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [fetch, { data }] = UserApi.useLazyUserByIdQuery();
  const [item, setItem] = useState<User>(location.state);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}

export function useRoleFromLocation() {
  const { id } = useParams<{ id: string }>();
  const { data } = UserApi.useRolesQuery();
  const [item, setItem] = useState<Role>();

  useEffect(() => {
    if (id) {
      const finded = data?.find((i) => i.id === parseInt(id, 10));
      setItem(finded);
    }
  }, [data, id]);

  return {
    item,
  };
}
