import { useState } from "react";
import { useScriptEffect } from "./scripts";

export const useStyles = (uris: string[]) => {
  const [urls] = useState(uris);
  const [styles, setStyles] = useState<HTMLLinkElement[]>([]);

  const [loaded, setLoaded] = useState(false);

  useScriptEffect(() => {
    setLoaded(false);
    createStylesAsync(urls).then((styles) => {
      setStyles((old) => {
        if (old.length === 0) {
          setLoaded(true);
          return styles;
        } else {
          for (let style of old) {
            if (document.head.contains(style)) document.head.removeChild(style);
          }

          setLoaded(true);
          return styles;
        }
      });
    });
  });

  return loaded;
};

async function createStylesAsync(urls: string[]) {
  const styles: HTMLLinkElement[] = [];
  for (let style of urls) {
    const res = await createStyleAsync(style);
    if (res) {
      styles.push(res);
    }
  }
  return styles;
}

function createStyleAsync(url: string) {
  return new Promise<HTMLLinkElement | null>((resolve) => {
    const elm = document.getElementById(url);
    if (!elm) {
      const style = document.createElement("link");
      style.rel = "stylesheet";
      style.type = "text/css";
      style.href = url;
      style.id = url;
      style.onload = (e) => {
        resolve(style);
      };
      style.onerror = (e) => {
        resolve(null);
      };
      document.head.appendChild(style);
    } else {
      resolve(elm as HTMLLinkElement);
    }
  });
}
