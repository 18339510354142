const StatsItem = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <div className="hexagon text-center">
      <h5>{title}</h5>
      <p>{value}</p>
    </div>
  );
};

export default StatsItem;
