import Table, {
  DeleteButton,
  TableHeader,
  UpdateButton,
} from "../../../components/Table";
import { Niveau } from "../../../types";
import { CommonQuery, usePaginationQuery } from "../../../utils/api/common.api";
import { NiveauApi } from "../../../utils/api/niveau.api";
import BreadCrumb from "../components/BreadCrumb";

const Niveaux = () => {
  const props = usePaginationQuery<Niveau, CommonQuery>(
    NiveauApi.useNiveauxQuery,
    {}
  );
  const [onDelete] = NiveauApi.useDeleteMutation();

  const headers: TableHeader<Niveau>[] = [
    { name: "name", title: "Nom" },
    { name: "short_name", title: "Nom court" },
    {
      name: "id",
      title: "Actions",
      formatter: (item) => (
        <>
          <UpdateButton
            route={`/dashboard/niveaux/update/${item.slug}`}
            state={item}
            permission="niveaux.update"
          />
          <DeleteButton
            id={item.id}
            permission="niveaux.delete"
            deleteFunction={onDelete}
            error={"Une erreur est survenue lors de la suppression"}
            success={"niveau supprimé avec succès"}
          />
        </>
      ),
    },
  ];

  return (
    <div className="main-page">
      <BreadCrumb
        title="Niveaux"
        subTitle="Liste des sports"
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Toutes les niveaux" },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>Liste des catégories ({props?.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20">
                  <Table {...props} headers={headers} keyField="id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Niveaux;
