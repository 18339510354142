import SideBar from "../../components/SideBar/SideBar";
import { Competition } from "../../types";
import { useLoadMoreQuery } from "../../utils/api/common.api";
import {
  CompetitionApi,
  CompetitionQuery,
} from "../../utils/api/competition.api";
import CompetitionItem from "./components/CompetitionItem";

const Competitions = () => {
  const { data, isLoading, renderButton } = useLoadMoreQuery<
    Competition,
    CompetitionQuery
  >(CompetitionApi.useCompetitionsQuery, { limit: 6, isValidated: true });
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="forum-box">
                  <div className="title-section">
                    <h1>
                      <span>Compétitions</span>
                    </h1>
                  </div>

                  <div className="forum-table">
                    <div className="table-title">
                      <h2>
                        <a href="#">Nos compétitions</a>
                      </h2>
                    </div>

                    {data.map((c) => (
                      <CompetitionItem item={c} key={c.id} />
                    ))}
                    {renderButton()}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Competitions;
