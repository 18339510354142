import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import DashboardItem from "../../../components/DashboardItem";
import { LoaderPage } from "../../../components/Loader";
import { useUser } from "../../../redux/store";
import { Equipe, Match, Participe, User } from "../../../types";
import {
  UserQuery,
  getFN,
  usePaginationQuery,
  useQuery,
} from "../../../utils/api/common.api";
import { useEquipeFromLocation } from "../../../utils/api/equipe.api";
import { MatchApi, MatchSelectQuery } from "../../../utils/api/match.api";
import { ParticipeApi, ParticipeQuery } from "../../../utils/api/participe.api";
import { UserApi } from "../../../utils/api/user.api";
import { useTabsControl } from "../../../utils/hooks";
import { usePermissions } from "../../../utils/permissions";
import AgentTable from "../agents/agent-table";
import AthleteTable from "../athletes/athlete-table";
import { DataItem, DataList } from "../athletes/detail-athlete";
import CompetitionTable from "../competitions/competition-table";
import { DisplayMatches } from "../competitions/matches/match-table";
import BreadCrumb from "../components/BreadCrumb";
import { useSetCount } from "../fedes/show-fede";

type Dashboard = {
  agents: number;
  athletes: number;
  competitions: number;
  matches: number;
};

type Data = {
  title: string;
  component: any;
  dashboardField: keyof Dashboard;
  bg: string;
  icon: any;
};

const DetailEquipeComponent = ({ item }: { item: Equipe }) => {
  const { active, setActive } = useTabsControl(-1);
  const [counts, setCounts] = useState<Dashboard>({
    agents: 0,
    athletes: 0,
    competitions: 0,
    matches: 0,
  });

  const DATA: Data[] = [
    {
      title: "Agents",
      component: Agents,
      bg: "bg-warning",
      dashboardField: "agents",
      icon: <i className="fa fa-users" />,
    },

    {
      title: "Athlètes",
      component: Athletes,
      bg: "bg-success",
      dashboardField: "athletes",
      icon: <i className="fa fa-users" />,
    },
    {
      title: "Compétitions",
      component: Competitions,
      bg: "bg-primary",
      dashboardField: "competitions",
      icon: <i className="fa fa-area-chart" />,
    },
    {
      title: "Matches",
      component: Matches,
      bg: "bg-warning",
      dashboardField: "matches",
      icon: <i className="fa fa-area-chart" />,
    },
  ];

  return (
    <>
      <div className="main-page">
        <BreadCrumb
          title={item.nom}
          subTitle="Détail équipe"
          items={[
            {
              title: "Home",
              route: "/dashboard",
            },
            { title: "Détail équipe" },
          ]}
        />
        <section className="section pt-10">
          <div className="container-fluid">
            <div className="row mt-5 pb-5">
              {DATA.map((it) => (
                <DashboardItem
                  cols={4}
                  title={it.title}
                  bg={it.bg}
                  count={counts[it.dashboardField]}
                  key={it.dashboardField}
                  icon={it.icon}
                />
              ))}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel border-primary no-border border-3-top">
                  <div className="panel-body p-20">
                    <ul className="tabs">
                      <li
                        role="presentation"
                        className={active === -1 ? "active" : ""}
                      >
                        <a
                          href={`#infos`}
                          onClick={() => setActive(-1)}
                          aria-controls={"infos"}
                          role="tab"
                          style={{ fontSize: 12 }}
                          data-toggle="tab"
                        >
                          Infos équipe
                        </a>
                      </li>
                      {DATA.map((ut, index) => (
                        <li
                          role="presentation"
                          className={index === active ? "active" : ""}
                        >
                          <a
                            href={`#${ut.title}`}
                            onClick={() => setActive(index)}
                            aria-controls={ut.title}
                            role="tab"
                            style={{ fontSize: 12 }}
                            data-toggle="tab"
                          >
                            {ut.title}
                          </a>
                        </li>
                      ))}
                    </ul>

                    <div className="tabs-container">
                      <div
                        role="tabpanel"
                        className={`${active === -1 ? "active" : ""}`}
                        id={"infos"}
                      >
                        <InfosEquipe item={item} />
                      </div>
                      {DATA.map(
                        (
                          { title, component: Component, dashboardField },
                          index
                        ) => (
                          <div
                            role="tabpanel"
                            className={`${index === active ? "active" : ""}`}
                            id={title}
                            key={dashboardField}
                          >
                            <Component
                              item={item}
                              setCounts={setCounts}
                              field={dashboardField}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default function DetailEquipe() {
  const { item } = useEquipeFromLocation();
  if (!item) return <LoaderPage />;
  return <DetailEquipeComponent item={item} />;
}

export function MonEquipe() {
  const navigate = useNavigate();
  const { equipe } = useUser();
  useEffect(() => {
    if (equipe) {
      navigate(`/dashboard/equipes/${equipe.slug}`, { replace: true });
    } else {
      navigate("/error-404", { replace: true });
    }
  }, [equipe]);

  return <LoaderPage />;
}

type CommonProps = {
  setCounts: any;
  field: string;
  item: Equipe;
};

function Agents({ item, field, setCounts }: CommonProps) {
  const props = usePaginationQuery<User, UserQuery>(UserApi.useUsersQuery, {
    userType: ["Agents Équipe"],
    equipe: item.id,
  });
  useSetCount(setCounts, field, props.total);
  return <AgentTable {...props} />;
}

function Competitions({ item, field, setCounts }: CommonProps) {
  const props = usePaginationQuery<Participe, ParticipeQuery>(
    ParticipeApi.useParticipesQuery,
    {
      equipe: item.id,
    },
    { niveau: true, sport: true }
  );
  useSetCount(setCounts, field, props.total);
  return (
    <CompetitionTable {...props} data={props.data.map((i) => i.competition)} />
  );
}

function Athletes({ item, field, setCounts }: CommonProps) {
  const props = usePaginationQuery<User, UserQuery>(
    UserApi.useUsersQuery,
    {
      userType: ["Athlète"],
      equipe: item.id,
    },
    {
      sport: true,
      niveau: true,
    }
  );
  useSetCount(setCounts, field, props.total);
  return <AthleteTable {...props} />;
}

function Matches({ item, field, setCounts }: CommonProps) {
  const props = useQuery<Match, MatchSelectQuery>(MatchApi.useSelectQuery, {
    equipe: item.id,
  });

  useSetCount(setCounts, field, props.data.length);

  return <DisplayMatches data={props.data} isLoading={props.isLoading} />;
}

function InfosEquipe({ item }: { item: Equipe }) {
  const { hasAccess } = usePermissions();
  const navigate = useNavigate();
  const DATA: DataItem[] = [
    { title: "Nom", value: item.nom },
    { title: "Type", value: item.type },
    { title: "Sport", value: item.sport?.name },
    { title: "Fédération", value: item.federation?.nom },
    {
      title: "Date de création",
      value: moment(item.createdDate).format("DD/MM/YYYY"),
    },
    { title: "Catégorie", value: item.niveau?.name },
    {
      title: "Dernière modification",
      value: moment(item.updatedAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Modifiée par",
      value: getFN(item.updatedBy),
    },
  ];

  return (
    <div>
      <div className="row" style={{ marginBottom: 10 }}>
        <div className="col-md-12">
          {hasAccess(["equipes.update"]) && (
            <Button
              type="button"
              className="btn bg-black btn-wide"
              title={"Modifier"}
              onClick={() => {
                navigate(`/dashboard/equipes/update/${item.slug}`);
              }}
              icon={<i className="fa fa-arrow-right" />}
            />
          )}
        </div>
      </div>
      <DataList data={DATA} />
    </div>
  );
}
