import PostsWidget from "./PostsWidget";
import SocialWidget from "./SocialWidget";

const SideBar = () => {
  return (
    <div className="sidebar">
      <SocialWidget />
      <PostsWidget />
      {/* <RssWidget /> */}

      <div className="widget post-widget">
        <div className="title-section">
          <h1>
            <span>CNOSD</span>
          </h1>
        </div>
        <img src="images/logo-cnos.png" width="250px" alt="" />
      </div>
    </div>
  );
};

export default SideBar;
