// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Equipe, SelectOptions, UserPerformences } from "../../types";
import { TypeEquipe } from "../const-fields";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";

export type EquipeQuery = CommonQuery & {
  federation?: number | string;
  niveau?: number | string;
  sport?: number | string;
  type?: TypeEquipe;
};

// Define a service using a base URL and expected endpoints
export const EquipeApi = createApi({
  tagTypes: ["equipes", "equipesSelect", "equipe", "performences"],
  reducerPath: "EquipeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    equipes: builder.query<PaginationResults<Equipe>, EquipeQuery>({
      query: (query) => ({
        url: QueryUrl(`/equipes/`, query),
        method: "GET",
      }),
      providesTags: ["equipes"],
    }),
    performences: builder.query<UserPerformences, number>({
      query: (equipeId) => ({
        url: `/equipes/performences/${equipeId}`,
        method: "GET",
      }),
      providesTags: (a, b, equipeId) => [
        { type: "performences", id: equipeId },
      ],
    }),
    equipesSelect: builder.query<Equipe[], EquipeQuery>({
      query: (query) => ({
        url: QueryUrl(`/equipes/select/`, query),
        method: "GET",
      }),
      providesTags: ["equipesSelect"],
    }),
    findBySlug: builder.query<Equipe, string>({
      query: (slug) => ({
        url: `/equipes/slug/${slug}/`,
        method: "GET",
      }),
      providesTags: (a, b, slug) => [{ type: "equipe", id: slug }],
    }),
    maj: builder.mutation<Equipe, { id?: number; data: FormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/equipes/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/equipes/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["equipes", "equipesSelect"],
    }),
    delete: builder.mutation<Equipe, number>({
      query: (id) => ({
        url: `/equipes/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["equipes", "equipesSelect"],
    }),
  }),
});

export function useEquipeFromLocation() {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [fetch, { data }] = EquipeApi.useLazyFindBySlugQuery();
  const [item, setItem] = useState<Equipe>(location.state);

  useEffect(() => {
    if (slug) fetch(slug);
  }, [slug]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}

export function useEquipeSelect(federation?: number | string) {
  const [items, setItems] = useState<SelectOptions>([]);
  const [empty] = useState<SelectOptions>([]);

  const { data, isFetching, isLoading } = EquipeApi.useEquipesSelectQuery({
    federation: federation,
  });
  useEffect(() => {
    if (data) {
      setItems(data.map((i) => ({ value: i.id, label: i.nom })));
    }
  }, [data]);
  const loading = isLoading || isFetching;
  return loading ? empty : items;
}
