import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardItem from "../../components/DashboardItem";
import { DashboardData } from "../../types";
import { UserApi } from "../../utils/api/user.api";
import { useCompetitionToValidate, useUserToValidate } from "../../utils/hooks";
import { usePermissions } from "../../utils/permissions";
import { ValidateAthleteTable } from "./athletes/athlete-table";
import { ValidateCompetitionTable } from "./competitions/competition-table";

type DashbaordItem = {
  icon: any;
  title: string;
  route?: any;
  field: keyof DashboardData;
};

const COLORS = [
  "bg-success",
  "bg-warning",
  "bg-primary",
  "bg-info",
  "bg-danger",
];

const DATA: DashbaordItem[] = [
  {
    title: "Fédérations",
    icon: <i className="fa fa-file-text"></i>,
    route: "/dashboard/federations",
    field: "federations",
  },
  {
    title: "Agents CNO",
    icon: <i className="fa fa-users"></i>,
    route: "/dashboard/agents-cno",
    field: "agentsCno",
  },
  {
    title: "Agents équipes",
    icon: <i className="fa fa-users"></i>,
    route: "/dashboard/agents",
    field: "agentsEquipes",
  },
  {
    title: "Agents fédérations",
    icon: <i className="fa fa-users"></i>,
    route: "/dashboard/agents",
    field: "agentsFederations",
  },
  {
    title: "Athlètes",
    icon: <i className="fa fa-users"></i>,
    route: "/dashboard/athletes",
    field: "athletes",
  },
  {
    title: "Abonnés",
    icon: <i className="fa fa-users"></i>,
    route: "/dashboard/abonnes",
    field: "abonnes",
  },
  {
    title: "Scores",
    icon: <i className="fa fa-area-chart"></i>,
    field: "buts",
  },
  {
    title: "Compétitions",
    icon: <i className="fa fa-area-chart" />,
    route: "/dashboard/competitions",
    field: "competitions",
  },
  {
    title: "Equipes",
    icon: <i className="fa fa-flag-o" />,
    route: "/dashboard/equipes",
    field: "equipes",
  },
  {
    title: "Matches",
    icon: <i className="fa fa-area-chart"></i>,
    route: "/dashboard/matches",
    field: "matches",
  },
  {
    title: "Catégories",
    icon: <i className="fa fa-file-text"></i>,
    route: "/dashboard/niveaux",
    field: "niveaux",
  },
  {
    title: "Présidents Fédération",
    icon: <i className="fa fa-users"></i>,
    route: "/dashboard/presidents-federations",
    field: "presidentFederations",
  },
  {
    title: "Saisons",
    icon: <i className="fa fa-file-text"></i>,
    route: "/dashboard/saisons",
    field: "saisons",
  },
  {
    title: "Sports",
    icon: <i className="fa fa-file-text"></i>,
    route: "/dashboard/sports",
    field: "sports",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { hasAccess } = usePermissions();
  const [dashboard, setDashboard] = useState<DashbaordItem[]>([]);
  const { data, isLoading } = UserApi.useDashboardQuery();
  useEffect(() => {
    if (data) {
      const items: DashbaordItem[] = [];
      for (let it of DATA) {
        if (data[it.field] !== undefined) {
          items.push(it);
        }
      }
      setDashboard(items);
    }
  }, [data]);
  return (
    <div className="main-page">
      <div className="container-fluid">
        <div className="row page-title-div">
          <div className="col-sm-6">
            <h2 className="title">
              Tableau de bord
              <img
                src="/admin/images/logo-cnos_4.png"
                alt="Options - Admin Template"
                className="logo"
                width="75px"
              />
            </h2>
            <p className="sub-title">Admin dashboard pour CNOSD</p>
          </div>
        </div>
        <div className="row breadcrumb-div">
          <div className="col-sm-6">
            <ul className="breadcrumb">
              <li>
                <Link to="/dashboard">
                  <i className="fa fa-home"></i> Home
                </Link>
              </li>
              <li className="active">Tableau de bord</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container-fluid">
          <div className="row">
            {dashboard.map((item, index) => (
              <DashboardItem
                title={item.title}
                count={data?.[item.field] ?? 0}
                onClick={() => {
                  if (item.route) {
                    navigate(item.route);
                  }
                }}
                icon={item.icon}
                bg={COLORS[index % COLORS.length]}
                key={item.field}
              />
            ))}
          </div>
        </div>
      </section>
      {hasAccess(["athletes.validate", "athletes.reject"]) && (
        <section className="section pt-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="panel">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <h5>Athlètes en attentes de validation</h5>
                    </div>
                  </div>
                  <div className="panel-body p-20 overflow-x-auto">
                    <AthleteToValidate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {hasAccess(["competitions.validate", "competitions.reject"]) && (
        <section className="section pt-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="panel">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <h5>Compétitions en attentes de validation</h5>
                    </div>
                  </div>
                  <div className="panel-body p-20 overflow-x-auto">
                    <CompetitionToValidate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Dashboard;

function AthleteToValidate() {
  const props = useUserToValidate();

  return <ValidateAthleteTable {...props} />;
}

function CompetitionToValidate() {
  const props = useCompetitionToValidate();

  return <ValidateCompetitionTable {...props} />;
}
