// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Equipe,
  Participe,
  ParticipeFormData,
  SelectOptions,
  User,
} from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl, getFN } from "./common.api";
import { prepareHeaders } from "./utils";
export type ParticipeQuery = CommonQuery & {
  competition?: number;
  groupe?: number;
  equipe?: number;
  athlete?: number;
};
// Define a service using a base URL and expected endpoints
export const ParticipeApi = createApi({
  tagTypes: ["participes", "participeItem", "selects"],
  reducerPath: "ParticipeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    participes: builder.query<PaginationResults<Participe>, ParticipeQuery>({
      query: (query) => ({
        url: QueryUrl(`/participes/`, query),
        method: "GET",
      }),
      providesTags: ["participes"],
    }),
    singles: builder.query<PaginationResults<Equipe | User>, ParticipeQuery>({
      query: (query) => ({
        url: QueryUrl(`/participes/singles`, query),
        method: "GET",
      }),
      providesTags: [{ type: "participes", id: -1 }],
    }),
    participesSelect: builder.query<Participe[], ParticipeQuery>({
      query: (query) => ({
        url: QueryUrl(`/participes/select`, query),
        method: "GET",
      }),
      providesTags: ["selects"],
    }),
    participeById: builder.query<Participe, string | number>({
      query: (id) => ({
        url: `/participes/${id}/`,
        method: "GET",
      }),
      providesTags: ["participeItem"],
    }),

    maj: builder.mutation<Participe, { id?: number; data: ParticipeFormData }>({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/participes/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/participes/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [],
    }),
    delete: builder.mutation<Participe, number>({
      query: (id) => ({
        url: `/participes/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [],
    }),
  }),
});

type FilterFunc = (u: Participe) => boolean;
export function useParticipeSelect(
  query: ParticipeQuery,
  filterFunc?: FilterFunc
) {
  const [items, setItems] = useState<SelectOptions>([]);
  const { data, refetch, isLoading } =
    ParticipeApi.useParticipesSelectQuery(query);
  useEffect(() => {
    if (data) {
      let items = [...data];
      if (filterFunc) {
        items = items.filter(filterFunc);
      }
      setItems(
        items.map((i) => {
          if (i.athlete)
            return {
              value: i.id,
              label: getFN(i.athlete),
            };
          return {
            value: i.id,
            label: i.equipe.nom,
          };
        })
      );
    }
  }, [data]);
  return {
    participes: items,
    refetch,
    isLoading,
  };
}

export function useParticipeFromLocation() {
  const location = useLocation();
  const { participeId } = useParams<{ participeId: string }>();
  const [fetch, { data }] = ParticipeApi.useLazyParticipeByIdQuery();
  const [item, setItem] = useState<Participe>(location.state);

  useEffect(() => {
    if (participeId) fetch(participeId);
  }, [participeId]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
