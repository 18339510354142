import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input, { GoogleInput, Select } from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { useUser } from "../../../redux/store";
import { User, UserFormData } from "../../../types";
import { useEquipeSelect } from "../../../utils/api/equipe.api";
import { useFedeSelectItems } from "../../../utils/api/fedes.api";
import { UserApi, useUserFromLocation } from "../../../utils/api/user.api";
import {
  CreateAgentTypes,
  CreateAgentTypesIndividuel,
  Sexes,
} from "../../../utils/const-fields";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import BreadCrumb from "../components/BreadCrumb";

type CreateAgentProps = {
  item?: User;
};

const CreateAgent = ({ item }: CreateAgentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();

  const fedes = useFedeSelectItems();
  const [exec, { isLoading }] = UserApi.useMajMutation();
  const fedeId = item?.federation?.id || user?.federation?.id;
  const {
    setErrors,
    errors,
    register,
    onSubmitForm,
    data: form,
    onChange,
  } = useFormData<UserFormData>(
    {
      nom: item?.nom || "",
      prenom: item?.prenom || "",
      email: item?.email || "",
      telephone: item?.telephone || "",
      address: item?.address || "",
      birthDate: item?.birthDate || "",
      equipe: item?.equipe?.id || "",
      federation: fedeId || "",
      nationality: item?.nationality || "",
      fonction: item?.fonction || "",
      sexe: item?.sexe || "Homme",
      userType: item?.userType || "Agents CNO",
      latitude: item?.latitude || "",
      longitude: item?.latitude || "",
      ...(item ? {} : { password: "", password_confirm: "" }),
    },
    (yup) => ({
      nom: yup.string().required().label("Nom"),
      prenom: yup.string().required().label("Prénom"),
      email: yup.string().email().required().label("Email"),
      telephone: yup.string().required().label("Téléphone"),
      fonction: yup.string().required().label("Fonction"),
      address: yup.string().required().label("Adresse"),
      birthDate: yup.string().required().label("Date de naissance"),
      nationality: yup.string().required().label("Nationalité"),
      federation: fedeId
        ? yup.string().notRequired()
        : yup.string().required().label("Fédération"),
      sexe: yup.string().required().label("Sexe"),
      userType: yup.string().required().label("Type"),
      password: !item
        ? yup.string().required().label("Mot de passe")
        : yup.string().notRequired(),
      password_confirm: !item
        ? yup
            .string()
            .oneOf(
              [yup.ref("password"), null],
              "Les mots de passe ne correspondent pas"
            )
        : yup.string().notRequired(),
    })
  );

  const equipes = useEquipeSelect(form.federation);

  const onSubmit = React.useCallback(
    async (data: UserFormData) => {
      if (form.userType === "Agents Équipe") {
        if (!form.equipe) {
          setErrors({ equipe: "Ce champ est obligatoir" });
          return;
        }
      } else {
        if (data instanceof FormData) {
          data.delete("equipe");
        } else {
          data = { ...data };
          delete data.equipe;
        }
      }
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          nom:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Agent ${item ? "modifiée" : "ajoutée"} avec succès`,
        });
        if (location.key === "default") {
          window.close();
        } else {
          navigate(-1);
        }
      }
    },
    [item, form]
  );

  const fede = fedes.find(
    (f) =>
      parseInt(String(f.value), 10) === parseInt(String(form.federation), 10)
  );

  const types: User["userType"][] =
    fede?.type === "Individuel" ? CreateAgentTypesIndividuel : CreateAgentTypes;

  return (
    <div className="main-page">
      <BreadCrumb
        title="Agents"
        subTitle={
          item
            ? "Formulaire de modification d'agent"
            : "Formulaire de création d'agent"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: item ? "Modification d'agent" : "Création d'agent",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"}{" "}
                      d'agent{" "}
                    </h5>
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      {!fedeId && (
                        <div className="col-md-6 col-xs-12">
                          <Select
                            placeholder={"Choisir une fédération"}
                            {...register("federation")}
                            label="Fédération"
                            containerClass="form-group"
                            inputClass="form-control"
                            options={fedes}
                          />
                        </div>
                      )}
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir un type"}
                          {...register("userType")}
                          label="Type"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={types.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <Select
                          placeholder={"Choisir le sexe"}
                          {...register("sexe")}
                          label="Sexe"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={Sexes.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Prénom"}
                          label="Prénom"
                          {...register("prenom")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("nom")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Email"}
                          label="Email"
                          {...register("email")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="email"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Fonction"}
                          label="Fonction"
                          {...register("fonction")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Téléphone"}
                          label="Téléphone"
                          {...register("telephone")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="tel"
                        />
                      </div>

                      {form.userType === "Agents Équipe" && (
                        <div className="col-md-6 col-xs-12">
                          <Select
                            placeholder={"Choisir une équipe"}
                            {...register("equipe")}
                            label="Equipe"
                            containerClass="form-group"
                            inputClass="form-control"
                            options={equipes}
                          />
                        </div>
                      )}

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Date de naissance"}
                          label="Date de naissance"
                          {...register("birthDate")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="date"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <Input
                          placeholder={"Nationalité"}
                          label="Nationalité"
                          {...register("nationality")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <GoogleInput
                          {...register("address")}
                          value={form.address}
                          label="Adresse"
                          placeholder="Adresse"
                          inputClass="form-control"
                          onChange={(data) => {
                            onChange({
                              address: data.address,
                              latitude: data.latitude,
                              longitude: data.longitude,
                            });
                          }}
                        />
                      </div>

                      {!item && (
                        <>
                          <div className="col-md-6 col-xs-12">
                            <Input
                              placeholder={"Mot de passe"}
                              label="Mot de passe"
                              {...register("password")}
                              containerClass="form-group"
                              inputClass="form-control"
                              type="password"
                            />
                          </div>

                          <div className="col-md-6 col-xs-12">
                            <Input
                              placeholder={"Confirmer mot de passe"}
                              label="Confirmer mot de passe"
                              {...register("password_confirm")}
                              containerClass="form-group"
                              inputClass="form-control"
                              type="password"
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateAgent;

export function UpdateAgent() {
  const { item } = useUserFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateAgent item={item} />;
}
