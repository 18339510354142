import { useCompetitionToValidate } from "../../../utils/hooks";
import BreadCrumb from "../components/BreadCrumb";
import { ValidateCompetitionTable } from "./competition-table";

const CompetitionsAvalider = () => {
  const props = useCompetitionToValidate();
  return (
    <div className="main-page">
      <BreadCrumb
        title="Compétitions"
        subTitle="Compétitions à valider"
        items={[{ title: "Home" }, { title: "Compétitions à valider" }]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-warning no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>En attente de validation ({props.total ?? 0})</h5>
                  </div>
                </div>
                <div className="panel-body p-20 overflow-x-auto">
                  <ValidateCompetitionTable {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompetitionsAvalider;
