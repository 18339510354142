import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import { LoaderPage } from "../../../../components/Loader";
import { Competition, Groupe, Match, Phase } from "../../../../types";
import { usePaginationQuery, useQuery } from "../../../../utils/api/common.api";
import { useCompetitionFromLocation } from "../../../../utils/api/competition.api";
import { GroupeApi, GroupeQuery } from "../../../../utils/api/groupe.api";
import { MatchApi, MatchSelectQuery } from "../../../../utils/api/match.api";
import { usePhaseFromLocation } from "../../../../utils/api/phase.api";
import { usePermissions } from "../../../../utils/permissions";
import BreadCrumb from "../../components/BreadCrumb";
import GroupeTable from "../groupes/groupe-table";
import { DisplayMatches } from "../matches/match-table";

const DetailPhaseComponent = ({
  competition,
  item,
}: {
  competition: Competition;
  item: Phase;
}) => {
  const navigate = useNavigate();
  const isGroupe = item.type === "Groupe";
  const { hasAccess } = usePermissions();
  return (
    <div className="main-page">
      <BreadCrumb
        title={competition.name}
        subTitle={item.name}
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          {
            title: competition.name,
            route: `/dashboard/competitions/${competition.slug}`,
          },
          {
            title: item.name,
          },
        ]}
      />
      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <h5>{item.name}</h5>
                      </div>
                      <div className="col-md-6 text-right">
                        {isGroupe && hasAccess(["competitions.create"]) && (
                          <Button
                            type="button"
                            className="btn bg-black btn-wide"
                            title={"Ajouter un groupe"}
                            onClick={() => {
                              navigate(
                                `/dashboard/competitions/${competition.slug}/phases/${item.id}/groupe/create`
                              );
                            }}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        )}
                        {!isGroupe && hasAccess(["match.create"]) && (
                          <Button
                            type="button"
                            className="btn bg-black btn-wide"
                            title={"Ajouter un match"}
                            onClick={() => {
                              navigate(
                                `/dashboard/competitions/${competition.slug}/phases/${item.id}/matches/create`
                              );
                            }}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-body p-20">
                  {isGroupe && (
                    <ListGroupes competition={competition} phase={item} />
                  )}
                  {!isGroupe && <ListMatches phase={item} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function DetailPhase() {
  const { item: comp } = useCompetitionFromLocation();
  const { item } = usePhaseFromLocation();

  if (!comp || !item) return <LoaderPage />;
  return <DetailPhaseComponent competition={comp} item={item} />;
}

export default DetailPhase;

function ListGroupes({
  phase,
  competition,
}: {
  phase: Phase;
  competition: Competition;
}) {
  const props = usePaginationQuery<Groupe, GroupeQuery>(
    GroupeApi.useGroupesQuery,
    { phase: phase.id }
  );

  return <GroupeTable {...props} phase={phase} competition={competition} />;
}

function ListMatches({ phase }: { phase: Phase }) {
  const props = useQuery<Match, MatchSelectQuery>(MatchApi.useSelectQuery, {
    phase: phase.id,
  });

  return <DisplayMatches data={props.data} isLoading={props.isLoading} />;
}
