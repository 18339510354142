// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  MessageContact,
  MessageContactFormData,
  MessageContactResponseFormData,
} from "../../types";
import { ApiUrl } from "./URL";
import { CommonQuery, PaginationResults, QueryUrl } from "./common.api";
import { prepareHeaders } from "./utils";
// Define a service using a base URL and expected endpoints
export const ContactApi = createApi({
  tagTypes: ["message-contact"],
  reducerPath: "ContactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    messageContacts: builder.query<
      PaginationResults<MessageContact>,
      CommonQuery
    >({
      query: (query) => ({
        url: QueryUrl(`/message-contact/`, query),
        method: "GET",
      }),
      providesTags: ["message-contact"],
    }),
    maj: builder.mutation<
      MessageContact,
      { id?: number; data: MessageContactFormData }
    >({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/message-contact/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `/message-contact/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["message-contact"],
    }),
    response: builder.mutation<MessageContact, MessageContactResponseFormData>({
      query: (data) => {
        return {
          url: `/message-contact/response/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["message-contact"],
    }),
    find: builder.query<MessageContact, number | string>({
      query: (id) => `/message-contact/${id}/`,
      providesTags: (a, b, id) => [{ type: "message-contact", id }],
    }),
    delete: builder.mutation<MessageContact, number>({
      query: (id) => ({
        url: `/message-contact/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["message-contact"],
    }),
  }),
});

export function useContactFromLocation() {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [fetch, { data }] = ContactApi.useLazyFindQuery();
  const [item, setItem] = useState<MessageContact>(location.state);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return {
    item,
  };
}
