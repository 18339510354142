import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { setAppConfig } from "./redux/slices/app-config.slice";
import { onLogout, onSetUser } from "./redux/slices/user.slice";
import { useAppDispatch, useUser } from "./redux/store";
import router from "./routes/router";
import { AppConfigApi } from "./utils/api/app-config.api";
import { UserApi } from "./utils/api/user.api";

function App() {
  const user = useUser();
  return (
    <>
      {!!user?.id && <FetchUser />}
      <FetchAppConfig />
      <RouterProvider router={router} />
    </>
  );
}

export default App;

function FetchUser() {
  const dispatch = useAppDispatch();
  const { data, error } = UserApi.useMeQuery();
  useEffect(() => {
    if (data) {
      dispatch(onSetUser(data));
    }
  }, [data]);

  useEffect(() => {
    if (error && "status" in error) {
      const { status } = error;
      if (status === 401) {
        dispatch(onLogout());
        window.location.href = "/auth/se-connecter";
      }
    }
  }, [error]);

  return null;
}

function FetchAppConfig() {
  const dispatch = useAppDispatch();
  const { data, error } = AppConfigApi.useAppConigQuery();
  useEffect(() => {
    if (data) {
      dispatch(setAppConfig(data));
    }
  }, [data]);

  return null;
}
