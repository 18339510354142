import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input, { Select } from "../../../components/Input";
import { LoaderPage } from "../../../components/Loader";
import { Sport, SportFormData } from "../../../types";
import { getFN } from "../../../utils/api/common.api";
import { SportApi, useSportFromLocation } from "../../../utils/api/sports.api";
import { TypeScores } from "../../../utils/const-fields";
import { COLORS } from "../../../utils/constants";
import { useFormData } from "../../../utils/form";
import BreadCrumb from "../components/BreadCrumb";

type CreateSportProps = {
  item?: Sport;
};
const CreateSport = ({ item }: CreateSportProps) => {
  const navigate = useNavigate();
  const [exec, { isLoading }] = SportApi.useMajMutation();
  const { setErrors, register, onSubmitForm } = useFormData<SportFormData>(
    {
      name: item?.name || "",
      pointsDefaite: item?.pointsDefaite || "",
      pointsNull: item?.pointsNull || "",
      pointsVictoire: item?.pointsVictoire || "",
      typeScore: item?.typeScore || "",
    },
    (yup) => ({
      name: yup.string().required().label("Nom"),
      pointsDefaite: yup.number().required().label("Points par défaite"),
      pointsNull: yup.number().required().label("Points par match nul"),
      pointsVictoire: yup.number().required().label("Points par victoire"),
      typeScore: yup.string().required().label("Type de score"),
    })
  );

  const onSubmit = React.useCallback(
    async (data: SportFormData) => {
      const res = await exec({ id: item?.id, data: data });
      if ("error" in res) {
        const error = res.error as any;
        setErrors({
          name:
            error.data?.message ||
            "Veuillez vous assurer d'entrer les bonnes données",
        });
      } else {
        Swal.fire({
          icon: "success",
          iconColor: COLORS.primary,
          confirmButtonColor: COLORS.primary,
          title: `Sport ${item ? "modifié" : "ajouté"} avec succès`,
        });
        navigate(-1);
      }
    },
    [item]
  );

  return (
    <div className="main-page">
      <BreadCrumb
        title="Sport"
        subTitle={
          item
            ? "Formulaire de modification d'un sport"
            : "Formulaire de création d'un sport"
        }
        items={[
          {
            title: "Home",
            route: "/dashboard",
          },
          { title: "Toutes les sports", route: "/dashboard/sports" },
          {
            title: item ? "Modification d'un sport" : "Création d'un sport",
          },
        ]}
      />

      <section className="section pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel border-primary no-border border-3-top">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h5>
                      GESTION ADMIN - {item ? "Modification" : "Création"} d'un
                      sport{" "}
                    </h5>
                    {!!item && (
                      <>
                        <div>
                          Dernière modification:{" "}
                          {moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}
                        </div>
                        <div>Modifiée par: {getFN(item.updatedBy)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="panel-body">
                  <form onSubmit={onSubmitForm(onSubmit)} className="p-20">
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          placeholder={"Nom"}
                          label="Nom"
                          {...register("name")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="text"
                        />
                      </div>
                      <div className="col-md-12">
                        <Select
                          placeholder={"Type de score"}
                          {...register("typeScore")}
                          label="Type de score"
                          containerClass="form-group"
                          inputClass="form-control"
                          options={TypeScores.map((s) => ({
                            label: s,
                            value: s,
                          }))}
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          placeholder={"Points par victoire"}
                          label="Points par victoire"
                          {...register("pointsVictoire")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="number"
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          placeholder={"Points par défaite"}
                          label="Points par défaite"
                          {...register("pointsDefaite")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="number"
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          placeholder={"Points par match nul"}
                          label="Points par match nul"
                          {...register("pointsNull")}
                          containerClass="form-group"
                          inputClass="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="btn-group pull-right mt-10"
                          role="group"
                        >
                          <button
                            type="reset"
                            onClick={() => navigate(-1)}
                            className="btn btn-gray btn-wide"
                          >
                            <i className="fa fa-times"></i>Annuler
                          </button>
                          <Button
                            type="submit"
                            className="btn bg-black btn-wide"
                            title="Enregistrer"
                            loading={isLoading}
                            icon={<i className="fa fa-arrow-right" />}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateSport;

export function UpdateSport() {
  const { item } = useSportFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <CreateSport item={item} />;
}
