import { LoaderPage } from "../../components/Loader";
import SideBar from "../../components/SideBar/SideBar";
import { User } from "../../types";
import { FollowButton, getFN, getImage } from "../../utils/api/common.api";
import { UserApi, useUserFromLocation } from "../../utils/api/user.api";
import { DisplayMatchesFront } from "../equipe/detailEquipe";
import StatsItem from "./components/StatsItem";
const DetailAthleteComponent = ({ item }: { item: User }) => {
  const {
    data = {
      buts: 0,
      competitions: 0,
      competitionsWins: 0,
      draws: 0,
      loses: 0,
      matches: 0,
      wins: 0,
    },
    isLoading,
  } = UserApi.usePerformencesQuery(item.id);
  return (
    <>
      <section className="block-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className="block-content">
                <div className="grid-box">
                  <div className="title-section d-flex justify-content-beetwen">
                    <h1>
                      <span>Information sur l'athlète </span>
                    </h1>
                    <FollowButton subscriberId={item.id} field="athlete" />
                  </div>

                  <ul className="autor-list">
                    <li>
                      <div className="athlete-box">
                        <img
                          className="athlete-img"
                          src={getImage(item.image)}
                          alt=""
                        />

                        <div className="autor-content">
                          <div className="autor-title">
                            <h4>{getFN(item)}</h4>
                          </div>
                          <div className="d-flex flex-wrap gap-10 flex-column">
                            <p>
                              Email :{" "}
                              <span className="text-bold">{item.email}</span>
                            </p>

                            <p>
                              Téléphone :{" "}
                              <span className="text-bold">
                                {item.telephone}
                              </span>
                            </p>
                            <p>
                              Fédération :
                              <span className="text-bold">
                                {" "}
                                {item.federation.nom}{" "}
                              </span>
                            </p>
                            {!!item.equipe && (
                              <p>
                                Équipe :{" "}
                                <span className="text-bold">
                                  {item.equipe.nom}
                                </span>
                              </p>
                            )}
                            <p>
                              Catégories :{" "}
                              <span className="text-bold">
                                {item.niveau.name}
                              </span>
                            </p>
                            <p>
                              Sport :{" "}
                              <span className="text-bold">
                                {item.sport.name}
                              </span>
                            </p>
                            <p>
                              Adresse :{" "}
                              <span className="text-bold">{item.address}</span>
                            </p>
                            <p>
                              Nationnalité :{" "}
                              <span className="text-bold">
                                {item.nationality}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div>
                    <div className="title-section">
                      <h1>
                        <span>Statistiques de l'athlète</span>
                      </h1>
                    </div>
                    <div className="hexagon-box">
                      <StatsItem
                        title="Compétitions"
                        value={data.competitions}
                      />
                      <StatsItem
                        title="Compétitions gagnées"
                        value={data.competitionsWins}
                      />
                      <StatsItem title="Matchs gagnés" value={data.wins} />
                      <StatsItem title="Matchs perdus" value={data.loses} />
                      <StatsItem title="Matchs nulls" value={data.draws} />
                      <StatsItem title="Buts marqués" value={data.buts} />
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="title-section">
                      <h1>
                        <span>Historique des matchs</span>
                      </h1>
                    </div>
                    <ul className="autor-list">
                      {!!item.equipe && (
                        <DisplayMatchesFront
                          showCompetName
                          equipe={item.equipe.id}
                        />
                      )}
                      {!item.equipe && (
                        <DisplayMatchesFront showCompetName athlete={item.id} />
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <SideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default function DetailAthleteFront() {
  const { item } = useUserFromLocation();
  if (!item) {
    return <LoaderPage />;
  }
  return <DetailAthleteComponent item={item} />;
}
